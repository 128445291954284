import React, { useState, useRef } from 'react';
import { transcribeAudio } from './AI'; // Whisper integration
import { transcribeAudioLocal } from './AI';
import { transcribeAWS, invokeModel } from './AI';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { MicrophoneIcon } from '@heroicons/react/20/solid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const EndPoint = process.env.REACT_APP_API_ENDPOINT;


const AudioRecorder = (props) => {
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [message, setMessage] = useState('');
    const [mediaStream, setMediaStream] = useState(null); // Store the media stream
    const audioChunksRef = useRef([]);
    const audioContext = useRef(new AudioContext());

    const toggleRecording = () => {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setMessage("Recording in progress...");
        setRecording(true);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                setMediaStream(stream); // Save the stream for later use

                recorder.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                recorder.start();
            })
            .catch(error => console.error('Error accessing media devices:', error));
    };

    const stopRecording = () => {
        setMessage("Processing...");
        setRecording(false);

        if (mediaRecorder) {
            mediaRecorder.stop();

            mediaRecorder.onstop = () => {
                const blob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                audioChunksRef.current = []; // Clear the chunks after recording
                splitAndTranscribe(blob);

                // Stop all tracks of the media stream
                if (mediaStream) {
                    mediaStream.getTracks().forEach(track => track.stop());
                    setMediaStream(null); // Clear the stream after stopping
                }
            };
        }
    };

    const splitAndTranscribe = async (blob) => {
        const totalSize = blob.size;
        const chunkSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
        let start = 0;
        
        // Create a single toast for the entire transcription process
        const toastId = toast.info('Transcription in progress...', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,  
            hideProgressBar: false
        });
    
        while (start < totalSize) {
            const end = Math.min(start + chunkSizeLimit, totalSize);
            const chunk = new Blob([blob.slice(start, end)], { type: 'audio/wav' });
    
            try {
                const transcription = await transcribeAudio(chunk); // Whisper integration
                const trialTranscribe = await invokeModel(chunk);
                console.log(trialTranscribe);
                console.log(transcription)
                if (props.onInputUpdate) {
                    props.onInputUpdate(transcription, toastId);
                    toast.update(toastId, {
                        render: 'Transcription success!',
                        type: toast.TYPE.SUCCESS,
                        autoClose: 1000,  
                        hideProgressBar: false
                    });
                }

                // log event
                await axios.post(`${EndPoint}/log/event`, { type:'Transcribing', credit: 5 }, { withCredentials: true });


            } catch (error) {
                toast.error('Error occurred during transcription');
                return; // Stop further execution if error occurs
            }
    
            start = end;
        }
    };

    return (
        <div className="flex flex-col items-center m-4 h-auto">
            <div className="flex items-center space-x-4 ml-80 sm:ml-[700px]">
                <button
                    id='microphone' 
                    className={`${
                        recording ? 'bg-red-600 hover:bg-red-700' : 'bg-sky-800 hover:bg-sky-900'
                    } flex items-center text-white px-2 py-2 rounded-3xl  hover:shadow-xl relative`}
                    onClick={toggleRecording}
                >
                    <MicrophoneIcon className="w-8 h-8" />
                    {recording && <p className='px-2'>Recording</p>}
                    {/* <div className='absolute font-bold text-slate-300 bg-slate-100 w-20 -ml-2 text-6xl'>3</div> */}
                </button>
                
                {/* Live Audio Visualizer */}
                <div style={{ width: 300, height: 50 }}>
                    {recording && mediaRecorder ? (
                        <LiveAudioVisualizer
                            mediaRecorder={mediaRecorder}
                            width={300}
                            height={50}
                            barWidth={3}
                            gap={2}
                            barColor={'#205680'}
                        />
                    ) : null}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AudioRecorder;

import React from 'react';
import axios from 'axios';
import { useNavigation, withNavigation } from '../../utilities/NavigationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PractitionerForm from './Forms/PractitionerForm';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

function generateRandomId() {
    return Math.random().toString(36).substring(2, 9);
};

class Practitioner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            selectedData: {}, // Handle single object
            isEditing: false,
        };
    }

    componentDidMount() {
        this.fetchPractitioner();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
          this.setState({ selectedData: { ...this.props.patientData.practitioner } });
        }
    }

    fetchPractitioner = async () => {
        const patientData = this.props.patientData;
        const practitioner = patientData.practitioner;
        this.setState({selectedData: practitioner});
    };
  

    handleShowForm = () => {
        this.setState({ showForm: true, isEditing: true });
    }

    handleAddForm = () => {

        this.setState({ showForm: true, selectedData: {}, isEditing: false });
    }

    handleCancel = () => {
        this.setState({ showForm: false });
    }

    handleSave = async (data) => {
        this.setState({ selectedData: data });
        
        var patientData = { ...this.props.patientData }; 
        patientData.practitioner = data; 
        this.props.updatePatientData(patientData);

        this.handleCancel();

        toast.success("Practitioner updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false
        });
    };

    handleDelete = async () => {
        this.setState({ selectedData: {} });
        this.props.updatePatientData("practitioner", {});

        toast.success("Practitioner deleted!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false
        });
    };

    renderContent() {
        const { selectedData } = this.state;

        if (selectedData && selectedData.firstName) {
            return (
                <div 
                    className='p-2 m-1 bg-white shadow-sm rounded border-sky-300'
                    onClick={this.handleShowForm}
                >
                    <p className='text-xs font-semibold'>{selectedData.firstName} {selectedData.lastName}</p>
                    <p className='text-xs'>{selectedData.profession}</p>
                    <p className='text-xs'>{selectedData.practiceName}</p>
                    <p className='text-xs'>{selectedData.suburb}, {selectedData.state} {selectedData.postcode}</p>
                    <p className='text-xs'>{selectedData.providerNumber}</p>
                    <button className='text-xs text-sky-500 underline' onClick={this.handleShowForm}>Edit</button>
                </div>
            );
        }  
        return null;
    }

    render() {
        return (
            <div className='col-span-1 rounded shadow-sm p-2 m-2 bg-slate-100 relative sm:col-span-6 xs:col-span-6'>
                <p className='text-lg font-semibold p-1'>Practitioner</p>
                <div className=' gap-2'>{this.renderContent()}</div>
                <div>
                    <button id='practice' className='absolute right-2 bottom-1 bg-sky-600 hover:bg-sky-700 text-white text-xs w-32 p-2 m-1 rounded shadow-sm' onClick={this.handleAddForm}>
                        Select Practice
                    </button>
                </div>
                {this.state.showForm && (
                    <PractitionerForm 
                        data={this.state.selectedData} 
                        style={{ display: this.state.showForm ? 'block' : 'none' }} 
                        patientId={this.props.patientId}
                        onCancel={this.handleCancel}
                        onSave={this.handleSave}
                        onDelete={this.handleDelete}
                    />
                )}
                <ToastContainer />
            </div>
        )
    }
}

export default Practitioner;

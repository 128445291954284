import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigation } from '../../utilities/NavigationContext';
import { useAuth } from '../../utilities/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Interview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          firstName: '',
          lastName: '',
          userType: '',
          role: '',

          isExpanded: false,

          mediaRecorder: null,
          audioChunks: [],
          audioContext: null,
          silenceTimeout: null,
          silenceDelay: 3000, 
          audioUrl: null, 
          loading: false, 
          isPlaying: false, 
          isFinish: false,
          serverRespond: "",
          AImessage : "Start by saying hello.",
    
          submittedData: {},
          userId: '',
          projectId : '',
          projectTitle : '',
          projectDescription: '',
          interviewInstruction : '',
          formInstruction: '',
          interviewStatus: false,
          projectExist : false,
          chatMessage : [],
          isSubmitted: false,

          authBox : false,
          authCode: ['', '', '', '', '', ''],
          submitAuth : false,
          authConfirm: false,

          interviewId: null,
          interviewOption: [],
          selectedOption: '',
          interviewType : ''
        };
    }

    componentDidMount = async () => {
 
        try {
          const queryParams = new URLSearchParams(window.location.search);
          const projectId = queryParams.get('id');
          if (projectId) {
            const response = await axios.get(`${EndPoint}/project/${projectId}`);
            const project = response.data;
      
            if (project) {
              this.setState({
                projectExist: true,
                projectTitle: project.title,
                projectDescription: project.description,
                interviewInstruction: project.interviewInstruction,
                formInstruction: project.formInstruction,
                projectId: project._id
                
              });
            } else {
              this.setState({
                projectExist: false,
                projectTitle: "",
                projectDescription: "",
                interviewInstruction: "",
                formInstruction: "",
                projectId: ''
              });
            }
          } else {
            this.setState({
              projectExist: false,
              projectTitle: "",
              projectDescription: "",
              interviewInstruction: "",
              formInstruction: "",
              projectId: ''
            });
          }
        } catch (error) {
          console.error('Error fetching the project:', error);
          this.setState({
            projectExist: false,
            projectTitle: "",
            projectDescription: "",
            interviewInstruction: "",
            formInstruction: "",
            projectId: ''
          });
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]: value});
    
    }

    handleChange(e, index) {
      const { value } = e.target;
      if (/^\d?$/.test(value)) {
        const newAuthCode = [...this.state.authCode];
        newAuthCode[index] = value;
        this.setState({ authCode: newAuthCode });
      }
    }

    handleSubmitForm = async (event) => {
        event.preventDefault();
        const { email, firstName, lastName, userType } = this.state;


        try {
            const response = await axios.post(`${EndPoint}/lead`, {
                firstName,
                lastName,
                email,
                userType,
                role: 'user', 
                projectId: this.state.projectId
            });

            const newUser = response.data.lead;
            const interviewId = response.data.interviewId;
            const interviewOption = response.data.option;

            const submittedData = {
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              email: newUser.email,
              userType: newUser.userType,
              projectId : this.state.projectId,
              userId: newUser._id
            };

            this.setState({submittedData: submittedData, userId: newUser._id, isSubmitted: true, interviewId: interviewId, interviewOption: interviewOption })
       
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });

        } catch (error) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
        }
    };

    handleSubmitAuth = async (event) => {
        event.preventDefault();
        var authCode = this.state.authCode.join('');
        var email = this.state.email;
        var selectedOption = this.state.selectedOption;
        var interviewId = this.state.interviewId;

        try {
          const response = await axios.post(`${EndPoint}/lead/verifyAuthCode`, {
              email,
              authCode,
              selectedOption,
              interviewId
          });

          var interviewType = response.data.interviewType;
  
          // Email authenticated
          toast.success(response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false
          });
          this.setState({authConfirm: true})

          // create new interview data if new
          if (interviewType === 'new') {
              await axios.post(`${EndPoint}/interview`, {
                  userId: this.state.userId,
                  projectId: this.state.projectId,
                  interview: [],
                  summary : [],
                  journey: [],
                  isFinish: false
              });
          } 
        
          // Error email or Auth number
      } catch (error) {
          toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false
          });
      }
    }

    handleOptionClick = (option) => {
      this.setState({ selectedOption: option });
    };

    handlePlayButton = async () => {
        if (this.state.recording) {
          this.stopRecording();
        } else {
          this.startRecording();
        }
    
    };
    
    startRecording = async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
    
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
    
        source.connect(analyser);
    
        let audioChunks = [];
    
        mediaRecorder.ondataavailable = event => {
            audioChunks.push(event.data);
        };
    
        mediaRecorder.onstop = async () => {
            const firstName = this.state.firstName;
            const lastName = this.state.lastName;
            const timestamp = Date.now();
            const filename = `${firstName}_${lastName}_${timestamp}.mp3`;
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
            const audioFile = new File([audioBlob], filename, { type: 'audio/mp3' });
            await this.sendAudioFile(audioFile);
        };
    
        mediaRecorder.start();
        this.setState({ recording: true, mediaRecorder, audioChunks, audioContext, stream });
    
        }
    };
    
    stopRecording = () => {
        const { mediaRecorder, audioContext, silenceTimeout} = this.state;
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        }
        if (audioContext) {
        audioContext.close();
        }
        clearTimeout(silenceTimeout);
        this.setState({ recording: false });
    };
    
    sendAudioFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatMessage', JSON.stringify(this.state.chatMessage));
        formData.append('systemSet', JSON.stringify({
            title: this.state.projectTitle,
            description: this.state.projectDescription,
            interviewInstruction: this.state.interviewInstruction,
            formInstruction: this.state.formInstruction,
            projectId: this.state.projectId,
            userId: this.state.userId,
            authCode: this.state.authCode.join(''),
            interviewId: this.state.interviewId,
            interviewType: this.state.selectedOption
        }));
        formData.append('firstName', this.state.firstName); 
        formData.append('lastName', this.state.lastName);  
        this.setState({ loading: true, selectedOption: "" }); 
        try {
    
        //get LLM respond as text
        const responseText = await axios.post(`${EndPoint}/chat`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            responseType: 'json' 
        });
        var respondData = responseText.data;
        var message = respondData.message;
        var chatStatus = respondData.chatFinish;
        var AIMessage = respondData.AIMessage;
        
        // convert text to voice
        const response = await axios.post(`${EndPoint}/chat/voice`, JSON.stringify({text: AIMessage}), {
            headers: {
            'Content-Type': 'application/json'
            },
            responseType: 'blob' 
        });
    
        const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mp3' }));
        this.setState({ audioUrl });
        this.setState({chatMessage: message, isFinish: chatStatus, AImessage: AIMessage})
    
        } catch (error) {
        console.error('Error sending audio file:', error);
        } finally {
        this.setState({ loading: false }); 
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.audioUrl && this.state.audioUrl !== prevState.audioUrl) {
            this.playAudio();
        }
    }
 
    playAudio = () => {
      // Check if the audio URL is available
      if (!this.state.audioUrl) {
          console.error("Audio URL is not set.");
          return;
      }
  
      // Create a new Audio object
      const audio = new Audio(this.state.audioUrl);
      this.setState({ isPlaying: true });
  
      // Handle audio end event
      
      audio.onended = () => {
          this.setState({ isPlaying: false });
          if (this.state.isFinish) {
              this.props.navigate('/');
          }
      };
  
      // Handle errors
      audio.onerror = (error) => {
          console.error("Audio playback error:", error);
          this.setState({ isPlaying: false });
      };
  
      // Safari and modern browser handling
      if (typeof AudioContext !== 'undefined' || typeof webkitAudioContext !== 'undefined') {
          const AudioCtx = AudioContext || webkitAudioContext;
          const audioContext = new AudioCtx();
  
          if (audioContext.state === 'suspended') {
              audioContext.resume().then(() => {
                  audio.play().catch((error) => {
                      console.error("Audio play error after resuming context:", error);
                  });
              });
          } else {
              audio.play().catch((error) => {
                  console.error("Audio play error:", error);
              });
          }
      } else {
          // Fallback for older browsers like Internet Explorer
          audio.play().catch((error) => {
              console.error("Audio play error:", error);
          });
      }
    };

    toggleExpand = () => {
        this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
    };
    
  
    renderAuthBox = () => {
      const { isExpanded } = this.state;
      var authConfirm = this.state.authConfirm;
      var interviewId = this.state.interviewId;
      var interviewOption = this.state.interviewOption;

      return (
          <>
                {authConfirm ? (
                <div
                    onClick={this.toggleExpand}
                    className={`bg-white p-4 rounded shadow-sm overflow-hidden transition-all duration-300 cursor-pointer ${
                    isExpanded ? 'h-[400px]' : 'h-10'
                    }`}
                >
                    <div className='grid grid-cols-2 mb-2 bg-slate-100 p-2 -mt-4 -mx-4'>
                    <div className='text-xl font-semibold px-4'>Instructions</div>
                    <div className='text-md italic text-right'>Click to {isExpanded ? 'collapse' : 'expand'}</div>
                    </div>
                    <p className="my-2">You will receive a request to access your microphone. Please allow it.</p>
                    <p className="my-2">Click 'Click to speak' when you're ready to speak.</p>
                    <p className="my-2">Click 'Stop speaking' when you finish talking.</p>
                    <p className="my-2">The button will be available again when the AI assistant has finished talking.</p>
                    <p className="my-2">Please take your time to think about your response.</p>
                    <p className="my-2">Once you finish the interview, please close this browser.</p>
                </div>
             
              ) : (
                  <>
                      <form onSubmit={this.handleSubmitAuth}>
                          <p className='text-xl font-semibold m-4 text-center'>Authentication Code</p>
                          <p className='text-center m-4'>Please check your email for the authentication code to start/continue interview.</p>
                          <div className='flex justify-center gap-4'>
                              {this.state.authCode.map((digit, index) => (
                                  <input
                                      key={index}
                                      type="text"
                                      value={digit}
                                      onChange={(e) => this.handleChange(e, index)}
                                      maxLength="1"
                                      className={`w-10 h-10 text-center border border-gray-100 ${
                                          authConfirm ? 'pointer-events-none opacity-60' : ''
                                      }`}
                                  />
                              ))}
                          </div>
                          <div className='flex justify-center'>
                              {interviewId ? (
                                <>
                                 {interviewOption.map(option => (
                                <button key={option} onClick={() => this.handleOptionClick(option, event)}  className='bg-sky-600 hover:bg-sky-700 rounded shadow-lg w-full text-white p-4 m-8' >
                                  {option.charAt(0).toUpperCase() + option.slice(1)}
                                </button>
                              ))}
                                </>
                              ) :(
                                <button
                                  type="submit"
                                  className={`bg-sky-600 hover:bg-sky-700 rounded shadow-lg w-full text-white p-4 m-8 ${
                                      authConfirm ? 'pointer-events-none opacity-60' : ''
                                  }`}
                                >
                                    Submit
                                </button>
                              )}
                           
                
                             
                          </div>
                      </form>
                  </>
              )}
          </>
      );
    };
  
    renderLeadForm = () => {
         
      var isSubmitted = this.state.isSubmitted;
      return (
        <form onSubmit={this.handleSubmitForm} className='bg-slate-100 p-10 rounded shadow-lg xs:p-2' >
        <h2 className='text-lg font-semibold text-center'>Please input your details below</h2>
        <div className='flex flex-col m-2 p-1 gap-2 w-[96%]'>
        <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={this.state.firstName}
            onChange={this.handleInputChange}
            className={`rounded border border-slate-200 p-2 text-md ${isSubmitted ? 'pointer-events-none opacity-60': ''}`}
        />
        <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={this.state.lastName}
            onChange={this.handleInputChange}
            className={`rounded border border-slate-200 p-2 text-md ${isSubmitted ? 'pointer-events-none opacity-60': ''}`}
        />
        <input
            type="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
            className={`rounded border border-slate-200 p-2 text-md ${isSubmitted ? 'pointer-events-none opacity-60': ''}`}
        />

        <select
        name="userType"
        value={this.state.userType}
        onChange={this.handleInputChange}
        required
        className={`rounded border border-slate-200 p-2 text-md ${isSubmitted ? 'pointer-events-none opacity-60': ''}`}
        >
        <option value="" disabled>Select your Healthcare Coverage</option>
        <option value="Private">Private</option>
        <option value="Pensioner">Pensioner</option>
        <option value="Medicare">Medicare</option>
        <option value="HCC">Health Care Card</option>
        <option value="DVA">DVA</option>
        </select>

        </div>
        <div className='text-center'>
        <button
        type="submit"
        className={`bg-sky-600 hover:bg-sky-700 rounded shadow-lg w-full text-white px-4 py-4 my-2 ${
          isSubmitted ? 'pointer-events-none opacity-60' : ''
        }`}
        disabled={isSubmitted}
      > Start Interview
      </button>
        </div>
        <p className='text-xs text-center mt-4 text-gray-600'>Please check your email after you click above button. We will never share your contact details with anyone.</p>
        </form>
      )
    }

    render() {

        var AImessage = this.state.AImessage;
        var isSubmitted = this.state.isSubmitted;
   
        return (
            <>
            {this.state.projectExist ? (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className=" w-[1100px] m-12 bg-sky-100 rounded shadow-sm xs:m-4">
              <h1 className='text-center text-3xl font-semibold p-10'>{this.state.projectTitle}</h1>
              {!isSubmitted ? (
                <p className='text-center m-10 text-xl xs:m-4'>{this.state.projectDescription}</p>
              ) : (<></>)}

              <div className='grid grid-cols-2 gap-4 m-10 xs:grid-cols-1  xs:m-2 sm:m-2'>
                  <div>
                    <div className='flex flex-col m-10 xs:m-2 sm:m-4'>
                        {isSubmitted ? (              
                            <> {this.renderAuthBox()}</>
                          ):(
                            <>{this.renderLeadForm()}</>
                          )}
                    </div>
              
                    <ToastContainer />
                </div>
                <div>
                  <div className='flex flex-col'>
                    {this.state.authConfirm && (
                        <div>
                          {/* <div className='my-10 mx-2 p-4 text-xl bg-slate-50 rounded shadow-md'>{this.state.serverRespond}</div> */}
                          {this.state.isFinish === true ? (
                            <>
                            <div className=' mx-2 p-4 bg-slate-50 rounded shadow-md'>{AImessage}</div>
                                {this.state.loading && (
                                <div className="mt-6 flex justify-center">
                                <img src={`${process.env.PUBLIC_URL}/loader.gif`} alt="Loading..." className="w-[100px] h-[100px]" />
                                </div>
                            )}
                            </>
                          ) : (
                            <>
                            <div className='my-10 mx-2 p-4 bg-slate-50 rounded shadow-md'>{AImessage}</div>
                            
                            {this.state.loading && (
                                <div className="mt-6 flex justify-center">
                                <img src={`${process.env.PUBLIC_URL}/loader.gif`} alt="Loading..." className="w-[100px] h-[100px]" />
                                </div>
                            )}
                            <button
                              onClick={this.handlePlayButton}
                              className={`w-full py-6 px-4 text-xl mt-20 rounded-xl shadow-sm text-white ${
                                this.state.isPlaying 
                                  ? 'bg-gray-400 hover:bg-gray-400'  // Styles when audio is playing
                                  : (this.state.recording 
                                      ? 'bg-red-500 hover:bg-red-600'  // Styles when recording
                                      : 'bg-sky-600 hover:bg-sky-700') // Default styles
                              }`}
                              disabled={this.state.isPlaying}
                            >
                              {this.state.recording ? 'Stop speaking' : 'Click to Speak'}
                            </button>
                            </>
                          )}
                        </div>
                      )}
        
            
                  
                  </div>
                </div>
              </div>
             
            </div>
            </div>
          ):(
            <div className='m-20 text-xxl'>No available Project</div>
          )}
          </>
  
        );
    }
}

const InterviewWithNavigation = (props) => {
    const navigate = useNavigation(); // Use the useNavigation hook here
    return <Interview {...props} navigate={navigate} />;
};
export default InterviewWithNavigation;

import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "How you have felt in the PAST SEVEN DAYS";
const questions = [
    {
        number: "1", 
        question: "I have been able to laugh and see the funny side of things" , 
        options: ["As much as I always could", "Not quite as much now", "Definitlye not so much now", "Not at all"], 
        value: [0,1,2,3] 
    },
    {
        number: "2", 
        question: "I have looked forward with enjoyment to things" , 
        options: ["As much as I ever did", "Rather less than I used to", "Definitely less than I used to", "Hardly at all" ], 
        value: [0,1,2,3] 
    },
    {
        number: "3", 
        question: "I have blamed myself unnecessarily when things went wrong" , 
        options: [" Yes, most of the time", "Yes, some of the time", "Not very often", "No, never"], 
        value: [3,2,1,0] 
    },
    {
        number: "4", 
        question: "I have been anxious or worried for no good reason" , 
        options: ["No, not at all", "Hardly ever", "Yes, sometimes", "Yes, very often"], 
        value: [0,1,2,3] 
    },
    {
        number: "5", 
        question: "I have felt scared or panicky for no very good reason" , 
        options: ["Yes, quite a lot", "Yes, sometimes", "No, not so much", "No, not at all"], 
        value: [3,2,1,0] 
    },
    {
        number: "6", 
        question: "Things have been getting on top of me" , 
        options: ["Yes, most of the time I haven't been able to cope at all", "Yes, sometimes I haven't been coping as well as usual", "No, most of the time I have coped quite well", "No, I have been coping as well as ever"], 
        value: [3,2,1,0] 
    },
    {
        number: "7", 
        question: "I have been so unhappy that I have had difficulty sleeping" , 
        options: ["Yes, most of the time", "Yes, sometimes", "Not very often", "No, not at all"], 
        value: [3,2,1,0] 
    },
    {
        number: "8", 
        question: "I have felt sad or miserable" , 
        options: ["Yes, most of the time", "Yes, quite often", "Not very often", "No, not at all"], 
        value: [3,2,1,0] 
    },
    {
        number: "9", 
        question: "I have been so unhappy that I have been crying" , 
        options: ["Yes, most of the time", "Yes, quite often", "Only occasionally", "No, never"], 
        value: [3,2,1,0] 
    },
    {
        number: "10", 
        question: "The thought of harming myself has occurred to me" , 
        options: ["Yes, quite often", "Sometimes", "Hardly ever", "Never"], 
        value: [3,2,1,0] 
    }
]

const title = "Edinburgh Postnatal Depression";
const instrument = "EPDS";
const explanation = `The Edinburgh Postnatal Depression Scale (EPDS) is a screening tool used to identify symptoms of depression in women who have recently given birth. It consists of 10 questions that ask about the mother's thoughts and feelings over the previous seven days.

The questions are scored on a scale from 0 to 3, with a maximum score of 30. A score of 10 or higher is generally considered to indicate a risk of postpartum depression, and a healthcare provider may recommend further evaluation or treatment.

The EPDS is widely used in clinical settings to identify postpartum depression early and to monitor symptoms over time. However, it is important to note that the EPDS is not a diagnostic tool and should be used in conjunction with a comprehensive evaluation by a qualified healthcare professional.`

const citation = `Cox, J.L., Holden, J.M., & Sagovsky, R. (1987). Detection of postnatal depression. Development of the 10-item Edinburgh Postnatal Depression Scale. British Journal of Psychiatry, 150, 782-786`
class EPDS extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latest:"", 
                harm: false,
                tool: props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();

        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }
        
        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }
        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>EPDS</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }

    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>

                {questions.map((item) => (
                    <div className='section flex flex-col border-b-2'>
                    <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='options flex flex-row xs:flex-col options w-11/12 xs:w-auto ml-6'>
                        <div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' />  {item.options[0]}</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' />  {item.options[1]}</div>
                        </div>
                        <div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' />  {item.options[2]}</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' />  {item.options[3]}</div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
     
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;

                    noBlank = noBlank + 1;

                    if (item.number === "10") {
                        if (value !== 0) {
                            this.setState({harm : true})
                        }
                    }
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.setState({score: finalscore})
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        //interpretation of score
        if (newData.score < 9) {
            newData['notes'] = "Indicate the presence of some symptoms of distress that may be shortlived and are less likely to interfere with day to day ability to function at home or at work. However if these symptoms have persisted more than a week or two further enquiry is warranted."
        } else if (newData.score >9 && newData.score < 13) {
            newData['notes'] = "Indicate presence of symptoms of distress that may be discomforting. Repeat the EDS in 2 weeks time and continue monitoring progress regularly. If the scores increase to above 12 assess further and consider referral as needed."
        } else if (newData.score > 12) {
            newData['notes'] = "Require further assessment and appropriate management as the likelihood of depression is high. Referral to a psychiatrist/psychologist may be necessary."
        }

        //checking item 10
        if (this.state.harm === true) {
            newData.notes = newData.notes + " As there's indication of self-harm, she requires further evaluation before leaving the office to ensure her own safety and that of her baby"
        }

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', EPDS : [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-lg -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-4/5 xs:h-4/5 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-lg'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default EPDS;


import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SharedFunctionContext} from '../../utilities/SharedFunctionContext';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Log extends Component {
  static contextType = SharedFunctionContext; 
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      groupedLogs: [],
      userDetails: {}, // Store user details by userId
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    // Fetch logs and user details
    this.fetchLogs();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check for changes in userDetails to log updates
    if (JSON.stringify(prevState.userDetails) !== JSON.stringify(this.state.userDetails)) {
      console.log('User details updated:');
    }
  }

  // Fetch logs from the API
  fetchLogs() {
    axios
      .get(`${EndPoint}/log`, { withCredentials: true })
      .then((response) => {
        const logs = response.data;
        const groupedLogs = this.groupAndSortLogs(logs);

        this.setState({ logs, groupedLogs, loading: false }, () => {
          // After grouping logs, fetch user details
          this.fetchUserDetails(groupedLogs);
        });
      })
      .catch((error) => {
        console.error('Error fetching logs:', error);
        this.setState({ error: error.message, loading: false });
      });
  }

  // Group and sort logs by userId
  groupAndSortLogs(logs) {
    const groupedActivities = logs.reduce((acc, activity) => {
      const { userId, type, timeStamp } = activity;
      acc[userId] = acc[userId] || [];
      acc[userId].push({ type, timeStamp });
      return acc;
    }, {});

    return Object.entries(groupedActivities).map(([userId, activities]) => ({
      userId,
      activity: activities.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)),
    }));
  }

  // Fetch user details for each userId
  fetchUserDetails(groupedLogs) {
    const userRequests = groupedLogs.map(({ userId }) =>
      axios
        .get(`${EndPoint}/user/${userId}`, { withCredentials: true })
        .then((response) => ({ userId, data: response.data }))
        .catch((error) => {
          console.error(`Error fetching user details for ${userId}:`, error);
          return { userId, data: null }; // Handle error gracefully
        })
    );

    // Wait for all requests to complete
    Promise.all(userRequests).then((results) => {
      const userDetails = results.reduce((acc, { userId, data }) => {
        if (data) {
          acc[userId] = data; // Store user details by userId
        }
        return acc;
      }, {});

      this.setState({ userDetails });
    });
  }

  addCredit = async (creditPoint) => {
    try {
      var response = await axios.post(
        `${EndPoint}/user/credits`,
        { creditPoint }, // Request body
        { withCredentials: true } // Config object
      );

      toast.success(`${creditPoint} credits added successfully`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,  
          hideProgressBar: false
      });
      // Refresh logs and user details after adding credit
      this.fetchLogs();

      // shared data to navigation
      const { sharedFunction} = this.context;
      sharedFunction(response.data.credits);
  
    } catch (error) {
      console.error(`Error adding credit for userId: ${userId}`, error.response?.data || error.message);
    }
  };
  
  render() {
    const { groupedLogs, userDetails, loading, error } = this.state;

    return (
      <div className="container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4">Log Table</h1>
        {loading ? (
          <p className="text-gray-500">Loading...</p>
        ) : error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : groupedLogs.length === 0 ? (
          <p className="text-gray-500">No logs available.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className="border border-gray-300 px-4 py-2">User ID</th>
                  <th className="border border-gray-300 px-4 py-2">Name</th>
                  <th className="border border-gray-300 px-4 py-2">Email</th>
                  <th className="border border-gray-300 px-4 py-2">Credits</th>
                  <th className="border border-gray-300 px-4 py-2">Activities</th>
                  <th className="border border-gray-300 px-4 py-2 text-center">Gift</th>
                </tr>
              </thead>
              <tbody>
                {groupedLogs.map(({ userId, activity }) => (
                  <tr key={userId} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2 align-top">{userId}</td>
                    <td className="border border-gray-300 px-4 py-2 align-top">
                      {userDetails[userId]
                        ? `${userDetails[userId].firstName} ${userDetails[userId].lastName}`
                        : 'Loading...'}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 align-top">
                      {userDetails[userId] ? userDetails[userId].email : 'Loading...'}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 align-top">
                      {userDetails[userId] ? userDetails[userId].credits : 'Loading...'}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <ul>
                        {activity.map((act, index) => (
                          <li key={index} className='my-2'>
                            <span className='bg-sky-200 p-1 rounded'>{act.type}</span> <span className='bg-slate-200 p-1 rounded'>{new Date(act.timeStamp).toLocaleString()}</span>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center align-top">
                    <button onClick={() => this.addCredit(100)} className='bg-sky-700 hover:bg-sky-800 w-20 text-white p-2 m-2 rounded shadow'>100</button>
                    <button onClick={() => this.addCredit(500)} className='bg-yellow-400 hover:bg-yellow-500 w-20 text-slate-800 p-2 m-2 rounded shadow'>500</button>
                    <button onClick={() => this.addCredit(1000)} className='bg-red-500 hover:bg-red-600 w-20 text-white p-2 m-2 rounded shadow'>1,000</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

export default Log;

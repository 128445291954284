import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Consultation from '../images/Consultation.png';
import Concept from '../images/concept.png';
import Tools from '../images/Tools.png';
import CDM from '../images/CDM.png';
import Journey from '../images/journey.png';
import Library from '../images/library.png';
import MHCP from '../images/MHCP1.jpg';
import Platform from '../images/platform.png';
import Chat from '../images/Chat.png';
import Demo from '../images/Demo.mp4'

//import sections, use  it below anytime
import {GridLeftImage, GridRightImage, FeatureListRow, FeatureListRight, FeatureListLeft, FeatureListTop, BentoFeatures } from './LandingComponents/Features';
import { HeroRightImage, HeroLeftImage, HeroCenterImage, HeroCenterText, HeroCenterVideo, CenterOwnVideo } from './LandingComponents/Hero';
import { SocialRow } from './LandingComponents/Social';

import { useAuth } from '../utilities/AuthContext'; 

const EndPoint = process.env.REACT_APP_API_ENDPOINT;
const IPToken = process.env.REACT_APP_IPINFO_TOKEN;

const socialComments = [
  {from:"Dr Xiaoting Ge", comment : `Increasing complexity and time consumption overwhelmed by competing tasks`},
  {from:"Dr Mohammed Vakil", comment: "Chronic disease is a substantial part of our management in general practice. A streamlined process to manage chronic conditions will help GPs manage the conditions in a comprehensive manner."},
  {from:"Dr Chris Hinton", comment: "Chronic disease managed by team of GP and nurses. Some case overlap. Some challenges in appointment scheduling and particularly delay in initiating first consult."},
  {from:"Dr Huseyin Ibrahim", comment: "Fear of further loss of income by Medicare. Making CDM efficient and having nurses agreeable to this"},
  {from:"Dr Krutik Patel", comment: "Difficult to differentiate from history and physical examination only"},
  {from:"Dr Faisal Khan", comment: "Like to have more comprehensive approach , minimal time consuming"},
  {from:"Dr Andrew Harris", comment: "Like to have more comprehensive approach , minimal time consuming"},
  {from:"Dr Ahmed Al-Obaidi", comment: "Inefficiencies with templates - repeatedly entering the same info - poor text formatting capabilities - lack of streamlined process (eg suggesting PSA check for every male > 50 yrs)"},
  {from:"Dr Evangeline Marzan", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Simon Luk", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Kapil Maharaj", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Timothy Vo", comment: `I've recently reviewed my care plans and care plans process. What Ive found is that the care plans were inadequate. They really did not achieve what I had in mind, which was to really make a positive difference in the patient's health and their chronic conditions. We have a nurse that does the care plans, and they were very generic. I managed to change that. They were still not satisfactory... So wordy, the process on Best practice was rubbish.... drawn out, not user friendly, not efficient... if you wanted to do a care plan that was indivualised. I've started doing them myself from start to finish and using a letter as my GPMP/TCA, rather than the "GPMP/TCA" button in Best Practice. This is so much better. Care plans are cleaner, easier to read, simpler. So now looking to improve efficiency in this area including the future follow ups.`},

]

const video = "https://www.youtube.com/embed/aj9KBA5k8hA?si=9qSsr6dmNN5AhxMa?=rel=0&amp;modestbranding=1&amp;showinfo=0&amp;controls=1&amp;";

function Home() {
  const navigate = useNavigate();
  const [auth] = useAuth(); 

  // Variation of title 
  const [title, setTitle] = useState(null); // Title as the primary focus
  const [subtitle, setSubtitle] = useState(''); // Dynamic subtitle
  const [image, setImage] = useState(''); // Dynamic image
  const [variation, setVariation] = useState(''); // Variation (A or B)

  // Variations
  const title1 = 'AI-powered Care Plans for GP';
  const title2 = 'Revolutionise Care with AI';
  const subtitle1 = 'Revolutionising Chronic Care with Intelligent, Automated, and Adaptive Care Plans';
  const subtitle2 = 'Revolutionising Chronic Care with Intelligent, Automated, and Adaptive Care Plans';
  const image1 = Demo;
  const image2 = Demo;

  // Log visit to the backend
  const logVisit = async (variation, variationType, title, subtitle, image) => {
    try {
      // const { data } = await axios.get('https://api64.ipify.org?format=json'); // Fetch client IP
      // const location = await getIPLocation(data.ip);// fetch client location
      await axios.post(`${EndPoint}/analytics/visit`, {
        variation,
        variationType,
        title,
        subtitle,
        image,
        ip: data.ip,
      });
    
      // console.log(location)
    } catch (err) {
      console.error('Error logging visit:', err);
    }
  };

  // get Ip Location
  const getIPLocation = async (ip) => {
    try {
      const { data } = await axios.get(`https://ipinfo.io/${ip}?token=${IPToken}`);
      return data;
    } catch (err) {
      console.error('Error fetching IPv6 location:', err);
      return null;
    }
  };

  

  // Assign title and log visit on first load
  useEffect(() => {
    const savedTitle = localStorage.getItem('title');
    if (!savedTitle) {
      // Assign title dynamically
      const randomTitle = Math.random() < 0.5 ? title1 : title2;
      const randomSubtitle = Math.random() < 0.5 ? subtitle1 : subtitle2;
      const randomImage = Math.random() < 0.5 ? image1 : image2;

      const variationType = 'title only'; // Specify the type of variation
      const assignedVariation = randomTitle === title1 ? 'A' : 'B';

      setTitle(randomTitle);
      setSubtitle(randomSubtitle);
      setImage(randomImage);
      setVariation(assignedVariation);

      // Save the selections to localStorage
      localStorage.setItem('title', randomTitle);
      localStorage.setItem('subtitle', randomSubtitle);
      localStorage.setItem('image', randomImage);
      localStorage.setItem('variation', assignedVariation);

      // Log visit to backend
      logVisit(assignedVariation, variationType, randomTitle, randomSubtitle, randomImage);
    } else {
      // Retrieve saved values from localStorage
      setTitle(savedTitle);
      setSubtitle(localStorage.getItem('subtitle'));
      setImage(localStorage.getItem('image'));
      setVariation(localStorage.getItem('variation'));
    }
  }, []);

  if (!title || !subtitle || !image) return <div>Loading...</div>;

  

  // const problemStatement = () => {

  //   return socialComments.map((item,index) => {
  //     return (
  //         <div className='p-2 m-2 rounded-xl bg-white shadow-lg w-[320px] h-auto border-l-2 border-slate-400'>
  //           <p className='text-left text-sm p-2'>"{item.comment}"</p>
  //           <p className='text-left text-xs p-2'>{item.from}</p>
  //         </div> 
  //     )
  //   });
  
  
  // }

  // const Features = [
  //   {title: "Create Care Plan Swiftly", description: "Streamline Care Plan generation from voice to plan to print in seconds", image: Concept},
  //   {title: "Project Manage Patient", description: "Creative and speed up processing time", image: Consultation},
  //   {title: "Patient Dashboard", description: "Never let patient out of track.", image: Tools},
  //   {title: "Automated Form Generation", description: "Form filled automatically just with one click", image: Concept}
  // ]

  // const Testimonials = [
  //   {title: "Increasing complexity and time consumption overwhelmed by competing tasks", author: 'Dr Linda'},
  //   {title: "Like to have more comprehensive approach , minimal time consuming", author : "Dr John King"},
  // ]


  const features = [{
      title : "AI-Driven Care Plan",
      notes : `Effortlessly create multiple care plans with our AI-driven platform.
  
      Automatically fill out forms, create list of problems, develop action plans, and review notes after each dictation or consultation.`,
      image: Platform
    }, {
      title : "Visualise Care",
      notes : `Leverage Kanban-style project management to visualise care plans and easily track patient progress.

      Create and update plans effortlessly, either manually or through voice commands. `,
      image : MHCP
    }, {
      title : "Patient Progress", 
      notes : `Review and update patient progress seamlessly with AI-powered voice assistance or manual entry.

      Get a clear, instant overview of patient progress.`,
      image : Journey
    }, {
      title : "Chat with Records",
      notes : `Simply use your voice to access relevant data within the patient care plan.

      Make faster, safer decisions, avoid duplication and repetition of test and treatment.`,
      image : Chat
    }, {
      title : "Care Library",
      notes : `Multiple goals and plans are available for selection when creating a new plan.

      Use Smart Paste to let AI automatically build the library from the provided text. `,
      image : Library
    }
  ]

  const benefits = [
    {
      title: "Enhanced Efficiency",
      notes: "Automate admin tasks with AI-powered Voice Input, Smart-Paste, Smart-Scan and AI-agents.",
      image: CDM
    },
    {
      title: "Improved Care Visualisation",
      notes: "Get a clear, visual overview of care plans."
    },
    {
      title: "Streamlined Patient Management",
      notes: "Coordinate and monitor patient care efficiently."
    },
    {
      title: "Comprehensive Care Library",
      notes: "Reuse a curated library for quick, personalized care."
    },
    {
      title: "Data-Driven Insights",
      notes: "Use AI to analyze data and optimize care strategies."
    },
    {
      title: "Enhanced Collaboration",
      notes: "Enable seamless collaboration with up-to-date info."
    }
  ];


  return (

      <div className="bg-gradient-to-tr from-sky-200">
        <div className='bg-sky-400 rounded h-[500px] w-[500px] rounded-2xl absolute rotate-12 -left-[200px] -top-[340px] opacity-25'></div>
        <div className='bg-sky-400 rounded h-[500px] w-[500px] rounded-2xl absolute rotate-12 -left-[240px] -top-[300px] opacity-25'></div>
        <div className='z-50'>
          <CenterOwnVideo title={title} subtitle={subtitle} video={Demo} />

          <GridRightImage title={features[0].title} subtitle={features[0].notes}  image={features[0].image} />
          <GridLeftImage title={features[1].title} subtitle={features[1].notes}  image={features[1].image}/>
          
          <BentoFeatures title="Benefits" content={benefits}/>
          <GridRightImage title={features[2].title} subtitle={features[2].notes}  image={features[2].image} />
          <GridLeftImage title={features[3].title} subtitle={features[3].notes}  image={features[3].image} />
          <GridRightImage title={features[4].title} subtitle={features[4].notes}  image={features[4].image} />
        </div>
      
      </div>

  );
}

export default Home;

import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../utilities/NavigationContext';

import Menu from './NavComponents/Menu';
import Sidebar from './NavComponents/Sidebar';

import Practitioner from './PatientComponents/Practitioner';
import Profile from './PatientComponents/Profile';
import Team from './PatientComponents/Team';

import MentalHealth from './MainComponents/MentalHealth';
import CDM from './MainComponents/CDM';
import HealthAssessment from './MainComponents/HealthAssessment';
import Consult from './MainComponents/Consult';
import General from './MainComponents/General';
import Settings from './MainComponents/Settings';
import ChatRecord from './MainComponents/ChatRecord';
import Tools from './SharedComponents/Tools';
import Comment from './CommunityComponents/Comment';

const secretStorage = process.env.REACT_APP_SECRET_STORAGE

function generateId() {
  return Math.random().toString(36).substr(2, 9);
}

const defaultReferrences = [
    {
      problem: "Diabetes Type 2",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Achieve target blood glucose levels" },
        { id: generateId(), entry: "Reduce A1C to below 7%" },
        { id: generateId(), entry: "Improve dietary habits" }
      ],
      plan: [
        { id: generateId(), entry: "Prescribe oral hypoglycemic agents" },
        { id: generateId(), entry: "Introduce regular blood sugar monitoring" },
        { id: generateId(), entry: "Provide dietary counseling" }
      ],
      education: [
        { id: generateId(), title: "Managing Type 2 Diabetes", link: "https://www.youtube.com/watch?v=example_link1" },
        { id: generateId(), title: "Diabetes Diet Tips", link: "https://www.youtube.com/watch?v=example_link2" }
      ]
    },
    {
      problem: "Hypertension",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Reduce blood pressure to target levels" },
        { id: generateId(), entry: "Encourage a low-sodium diet" },
        { id: generateId(), entry: "Increase physical activity" }
      ],
      plan: [
        { id: generateId(), entry: "Prescribe antihypertensive medication" },
        { id: generateId(), entry: "Schedule regular BP monitoring" },
        { id: generateId(), entry: "Provide lifestyle modification guidance" }
      ],
      education: [
        { id: generateId(), title: "Understanding and Managing High Blood Pressure", link: "https://www.youtube.com/watch?v=example_link3" },
        { id: generateId(), title: "Lifestyle Changes for Hypertension", link: "https://www.youtube.com/watch?v=example_link4" }
      ]
    },
    {
      problem: "Depression",
      category: "Mental Health",
      goal: [
        { id: generateId(), entry: "Reduce depressive symptoms" },
        { id: generateId(), entry: "Improve daily functioning" },
        { id: generateId(), entry: "Enhance coping strategies" }
      ],
      plan: [
        { id: generateId(), entry: "Provide cognitive behavioral therapy" },
        { id: generateId(), entry: "Consider SSRI medication" },
        { id: generateId(), entry: "Encourage regular physical activity" }
      ],
      education: [
        { id: generateId(), title: "Understanding Depression", link: "https://www.youtube.com/watch?v=example_link5" },
        { id: generateId(), title: "Coping Strategies for Depression", link: "https://www.youtube.com/watch?v=example_link6" }
      ]
    },
    {
      problem: "Obesity",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Achieve a healthy weight" },
        { id: generateId(), entry: "Improve dietary habits" },
        { id: generateId(), entry: "Increase physical activity" }
      ],
      plan: [
        { id: generateId(), entry: "Offer weight loss counseling" },
        { id: generateId(), entry: "Introduce an exercise routine" },
        { id: generateId(), entry: "Monitor weight and progress" }
      ],
      education: [
        { id: generateId(), title: "The Truth About Weight Loss", link: "https://www.youtube.com/watch?v=example_link7" },
        { id: generateId(), title: "Nutrition and Weight Management", link: "https://www.youtube.com/watch?v=example_link8" }
      ]
    },
    {
      problem: "Social Isolation",
      category: "Social",
      goal: [
        { id: generateId(), entry: "Increase social interactions" },
        { id: generateId(), entry: "Encourage participation in community events" },
        { id: generateId(), entry: "Reduce feelings of loneliness" }
      ],
      plan: [
        { id: generateId(), entry: "Connect with local support groups" },
        { id: generateId(), entry: "Encourage family visits" },
        { id: generateId(), entry: "Suggest volunteer opportunities" }
      ],
      education: [
        { id: generateId(), title: "Coping with Loneliness", link: "https://www.youtube.com/watch?v=example_link9" },
        { id: generateId(), title: "Building Social Connections", link: "https://www.youtube.com/watch?v=example_link10" }
      ]
    },
    {
      problem: "Smoking",
      category: "Lifestyle",
      goal: [
        { id: generateId(), entry: "Reduce cigarette consumption" },
        { id: generateId(), entry: "Work towards complete cessation" },
        { id: generateId(), entry: "Improve lung health" }
      ],
      plan: [
        { id: generateId(), entry: "Provide smoking cessation counseling" },
        { id: generateId(), entry: "Introduce nicotine replacement therapy" },
        { id: generateId(), entry: "Monitor progress and setbacks" }
      ],
      education: [
        { id: generateId(), title: "How to Quit Smoking", link: "https://www.youtube.com/watch?v=example_link11" },
        { id: generateId(), title: "The Effects of Smoking on Health", link: "https://www.youtube.com/watch?v=example_link12" }
      ]
    },

  {
    problem: "Asthma",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Control asthma symptoms" },
      { id: generateId(), entry: "Reduce asthma exacerbations" },
      { id: generateId(), entry: "Improve lung function" }
    ],
    plan: [
      { id: generateId(), entry: "Prescribe inhaled corticosteroids" },
      { id: generateId(), entry: "Provide asthma action plan" },
      { id: generateId(), entry: "Schedule regular follow-ups" }
    ],
    education: [
      { id: generateId(), title: "Managing Asthma", link: "https://www.youtube.com/watch?v=example_link13" },
      { id: generateId(), title: "Asthma Action Plan", link: "https://www.youtube.com/watch?v=example_link14" }
    ]
  },
  {
    problem: "COPD",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Improve breathing and lung function" },
      { id: generateId(), entry: "Reduce COPD exacerbations" },
      { id: generateId(), entry: "Enhance overall quality of life" }
    ],
    plan: [
      { id: generateId(), entry: "Prescribe bronchodilators" },
      { id: generateId(), entry: "Develop a COPD action plan" },
      { id: generateId(), entry: "Encourage smoking cessation" },
      { id: generateId(), entry: "Schedule regular pulmonary function tests" }
    ],
    education: [
      { id: generateId(), title: "Managing COPD", link: "https://www.youtube.com/watch?v=example_link15" },
      { id: generateId(), title: "COPD Action Plan", link: "https://www.youtube.com/watch?v=example_link16" }
    ]
  },  
  {
    problem: "Atrial Fibrillation",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Control heart rate" },
      { id: generateId(), entry: "Prevent stroke" },
      { id: generateId(), entry: "Manage symptoms" }
    ],
    plan: [
      { id: generateId(), entry: "Prescribe anticoagulants" },
      { id: generateId(), entry: "Consider beta-blockers or calcium channel blockers" },
      { id: generateId(), entry: "Monitor ECG regularly" }
    ],
    education: [
      { id: generateId(), title: "Understanding Atrial Fibrillation", link: "https://www.youtube.com/watch?v=example_link15" },
      { id: generateId(), title: "Atrial Fibrillation and Stroke Prevention", link: "https://www.youtube.com/watch?v=example_link16" }
    ]
  },
  {
    problem: "Coeliac Disease",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Achieve gluten-free diet adherence" },
      { id: generateId(), entry: "Prevent nutritional deficiencies" },
      { id: generateId(), entry: "Manage gastrointestinal symptoms" }
    ],
    plan: [
      { id: generateId(), entry: "Provide gluten-free diet counseling" },
      { id: generateId(), entry: "Schedule regular nutritional monitoring" },
      { id: generateId(), entry: "Refer to a dietitian" }
    ],
    education: [
      { id: generateId(), title: "Living with Coeliac Disease", link: "https://www.youtube.com/watch?v=example_link17" },
      { id: generateId(), title: "Gluten-Free Diet Tips", link: "https://www.youtube.com/watch?v=example_link18" }
    ]
  },
  {
    problem: "PTSD",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce PTSD symptoms" },
      { id: generateId(), entry: "Improve emotional regulation" },
      { id: generateId(), entry: "Enhance coping mechanisms" }
    ],
    plan: [
      { id: generateId(), entry: "Provide trauma-focused therapy" },
      { id: generateId(), entry: "Consider SSRIs" },
      { id: generateId(), entry: "Monitor symptoms regularly" }
    ],
    education: [
      { id: generateId(), title: "Understanding PTSD", link: "https://www.youtube.com/watch?v=example_link19" },
      { id: generateId(), title: "PTSD Coping Strategies", link: "https://www.youtube.com/watch?v=example_link20" }
    ]
  },
  {
    problem: "Alcohol Misuse",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Reduce alcohol intake" },
      { id: generateId(), entry: "Avoid binge drinking" },
      { id: generateId(), entry: "Achieve complete abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Provide counseling for alcohol misuse" },
      { id: generateId(), entry: "Consider support group referral" },
      { id: generateId(), entry: "Monitor progress regularly" }
    ],
    education: [
      { id: generateId(), title: "Effects of Alcohol on Health", link: "https://www.youtube.com/watch?v=example_link21" },
      { id: generateId(), title: "Strategies for Reducing Alcohol Intake", link: "https://www.youtube.com/watch?v=example_link22" }
    ]
  },
  {
    problem: "Benzodiazepine Dependency",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce benzodiazepine use" },
      { id: generateId(), entry: "Prevent withdrawal symptoms" },
      { id: generateId(), entry: "Achieve long-term abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Implement tapering schedule" },
      { id: generateId(), entry: "Provide behavioral therapy" },
      { id: generateId(), entry: "Refer to addiction specialist if necessary" }
    ],
    education: [
      { id: generateId(), title: "Understanding Benzodiazepine Dependence", link: "https://www.youtube.com/watch?v=example_link23" },
      { id: generateId(), title: "Coping with Withdrawal", link: "https://www.youtube.com/watch?v=example_link24" }
    ]
  },
  {
    problem: "Cannabis Use Disorder",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce cannabis consumption" },
      { id: generateId(), entry: "Improve mental clarity" },
      { id: generateId(), entry: "Achieve abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Provide counseling for substance use" },
      { id: generateId(), entry: "Monitor for withdrawal symptoms" },
      { id: generateId(), entry: "Consider support group referral" }
    ],
    education: [
      { id: generateId(), title: "Understanding Cannabis Use Disorder", link: "https://www.youtube.com/watch?v=example_link25" },
      { id: generateId(), title: "Cannabis and Mental Health", link: "https://www.youtube.com/watch?v=example_link26" }
    ]
  },
  {
    problem: "Caffeine Addiction",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Reduce caffeine intake" },
      { id: generateId(), entry: "Avoid dependency on caffeine" },
      { id: generateId(), entry: "Improve sleep quality" }
    ],
    plan: [
      { id: generateId(), entry: "Provide strategies for reducing caffeine intake" },
      { id: generateId(), entry: "Monitor for withdrawal symptoms" },
      { id: generateId(), entry: "Encourage regular sleep patterns" }
    ],
    education: [
      { id: generateId(), title: "Effects of Caffeine on the Body", link: "https://www.youtube.com/watch?v=example_link27" },
      { id: generateId(), title: "How to Reduce Caffeine Intake", link: "https://www.youtube.com/watch?v=example_link28" }
    ]
  },
  {
    problem: "Financial Constraint",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Improve financial management" },
      { id: generateId(), entry: "Reduce debt" },
      { id: generateId(), entry: "Achieve financial stability" }
    ],
    plan: [
      { id: generateId(), entry: "Provide financial counseling" },
      { id: generateId(), entry: "Offer budgeting resources" },
      { id: generateId(), entry: "Connect to financial aid programs" }
    ],
    education: [
      { id: generateId(), title: "Managing Finances", link: "https://www.youtube.com/watch?v=example_link29" },
      { id: generateId(), title: "Budgeting Basics", link: "https://www.youtube.com/watch?v=example_link30" }
    ]
  },
  {
    problem: "Unemployment",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Secure employment" },
      { id: generateId(), entry: "Develop job skills" },
      { id: generateId(), entry: "Improve resume and interview skills" }
    ],
    plan: [
      { id: generateId(), entry: "Provide job search assistance" },
      { id: generateId(), entry: "Connect with vocational training programs" },
      { id: generateId(), entry: "Offer resume and interview coaching" }
    ],
    education: [
      { id: generateId(), title: "Job Hunting Tips", link: "https://www.youtube.com/watch?v=example_link31" },
      { id: generateId(), title: "Building a Strong Resume", link: "https://www.youtube.com/watch?v=example_link32" }
    ]
  },
  {
    problem: "Homelessness",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Secure stable housing" },
      { id: generateId(), entry: "Improve access to resources" },
      { id: generateId(), entry: "Connect with support services" }
    ],
    plan: [
      { id: generateId(), entry: "Provide housing assistance" },
      { id: generateId(), entry: "Connect to local shelters" },
      { id: generateId(), entry: "Offer mental health support" }
    ],
    education: [
      { id: generateId(), title: "Understanding Homelessness", link: "https://www.youtube.com/watch?v=example_link33" },
      { id: generateId(), title: "Resources for the Homeless", link: "https://www.youtube.com/watch?v=example_link34" }
    ]
  },
  {
    problem: "Intimate Partner Violence",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Enhance personal safety" },
      { id: generateId(), entry: "Access support services" },
      { id: generateId(), entry: "Build a support network" }
    ],
    plan: [
      { id: generateId(), entry: "Provide crisis counseling" },
      { id: generateId(), entry: "Connect with local shelters" },
      { id: generateId(), entry: "Offer legal assistance" }
    ],
    education: [
      { id: generateId(), title: "Understanding Intimate Partner Violence", link: "https://www.youtube.com/watch?v=example_link35" },
      { id: generateId(), title: "Resources for Victims", link: "https://www.youtube.com/watch?v=example_link36" }
    ]
  },
  {
    problem: "Not Driving",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Identify alternative transportation" },
      { id: generateId(), entry: "Improve access to essential services" },
      { id: generateId(), entry: "Maintain social connections" }
    ],
    plan: [
      { id: generateId(), entry: "Provide transportation resources" },
      { id: generateId(), entry: "Connect with local mobility services" },
      { id: generateId(), entry: "Encourage ridesharing options" }
    ],
    education: [
      { id: generateId(), title: "Transportation Alternatives", link: "https://www.youtube.com/watch?v=example_link37" },
      { id: generateId(), title: "Accessing Services without a Car", link: "https://www.youtube.com/watch?v=example_link38" }
    ]
  },
  {
    problem: "Legal Issues",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Resolve ongoing legal matters" },
      { id: generateId(), entry: "Understand legal rights" },
      { id: generateId(), entry: "Access legal representation" }
    ],
    plan: [
      { id: generateId(), entry: "Provide legal counseling" },
      { id: generateId(), entry: "Refer to local legal aid services" },
      { id: generateId(), entry: "Monitor case progress" }
    ],
    education: [
      { id: generateId(), title: "Understanding Legal Rights", link: "https://www.youtube.com/watch?v=example_link39" },
      { id: generateId(), title: "Finding Legal Assistance", link: "https://www.youtube.com/watch?v=example_link40" }
    ]
  },
  {
    problem: "Lack of Activities",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Increase engagement in activities" },
      { id: generateId(), entry: "Encourage physical activity" },
      { id: generateId(), entry: "Develop hobbies and interests" }
    ],
    plan: [
      { id: generateId(), entry: "Provide list of local activities" },
      { id: generateId(), entry: "Encourage participation in community events" },
      { id: generateId(), entry: "Suggest physical exercise routines" }
    ],
    education: [
      { id: generateId(), title: "Benefits of Staying Active", link: "https://www.youtube.com/watch?v=example_link41" },
      { id: generateId(), title: "Finding New Hobbies", link: "https://www.youtube.com/watch?v=example_link42" }
    ]
  }
  ];

  const contacts = [
    {
        id: '1',
        title: 'Dr',
        firstName: 'John',
        lastName: 'Smith',
        profession: 'Cardiologist',
        providerNumber: '1234567',
        address1: '123 Heart Ave',
        address2: 'Suite 5',
        suburb: 'Melbourne',
        postcode: '3000',
        state: 'VIC',
        phone: '(03) 9123 4567',
        fax: '(03) 9123 4568',
        email: 'john.smith@heartcare.com.au'
    },
    {
        id: '2',
        title: 'Ms',
        firstName: 'Emily',
        lastName: 'Brown',
        profession: 'Physiotherapist',
        providerNumber: '2345678',
        address1: '789 Movement St',
        address2: 'Room 2',
        suburb: 'Sydney',
        postcode: '2000',
        state: 'NSW',
        phone: '(02) 8123 4567',
        fax: '(02) 8123 4568',
        email: 'emily.brown@movewell.com.au'
    },
    {
        id: '3',
        title: 'Mr',
        firstName: 'Michael',
        lastName: 'Green',
        profession: 'Occupational Therapist',
        providerNumber: '3456789',
        address1: '56 Therapy Rd',
        address2: 'Suite 12B',
        suburb: 'Brisbane',
        postcode: '4000',
        state: 'QLD',
        phone: '(07) 6234 5678',
        fax: '(07) 6234 5679',
        email: 'michael.green@otservices.com.au'
    },
    {
        id: '4',
        title: 'Mrs',
        firstName: 'Sarah',
        lastName: 'White',
        profession: 'Dietitian',
        providerNumber: '4567890',
        address1: '44 Wellness Blvd',
        address2: '',
        suburb: 'Adelaide',
        postcode: '5000',
        state: 'SA',
        phone: '(08) 8123 4569',
        fax: '(08) 8123 4570',
        email: 'sarah.white@wellnutrition.com.au'
    },
    {
        id: '5',
        title: 'Dr',
        firstName: 'Angela',
        lastName: 'Black',
        profession: 'Clinical Psychologist',
        providerNumber: '5678901',
        address1: '33 Mind St',
        address2: '',
        suburb: 'Perth',
        postcode: '6000',
        state: 'WA',
        phone: '(08) 9123 4567',
        fax: '(08) 9123 4568',
        email: 'angela.black@mentalhealthcare.com'
    },
    {
        id: '6',
        title: 'Mr',
        firstName: 'David',
        lastName: 'Thompson',
        profession: 'General Practitioner (GP)',
        providerNumber: '6789012',
        address1: '90 Health Ave',
        address2: '',
        suburb: 'Darwin',
        postcode: '0800',
        state: 'NT',
        phone: '(08) 8932 1234',
        fax: '(08) 8932 1235',
        email: 'david.thompson@localgp.com.au'
    },
    {
        id: '7',
        title: 'Mrs',
        firstName: 'Lucy',
        lastName: 'Lee',
        profession: 'Speech Pathologist',
        providerNumber: '7890123',
        address1: '22 Speech Lane',
        address2: 'Level 3, Unit 15',
        suburb: 'Hobart',
        postcode: '7000',
        state: 'TAS',
        phone: '(03) 6234 7890',
        fax: '(03) 6234 7891',
        email: 'lucy.lee@talktherapy.com'
    }
];


const patientDataInit = {
    profile: {},
    practitioner: {},
    teams: [],
    history : [],
    mhcp: {},
    mhformulation: {},
    mhsafety: {},
    mhrelapse: {},
    healthassessment: {},
    planning : [{id:'2o3in235', category: 'Chronic Disease', collapsed: false, priority: "Low", selected: "false", problem: "Double click to edit problem/issue", goal: [], journey: [], review:[], plan : []}],
    consultations: []
};

const adminDataInit = {
    patientList: [],
    dashBoard: [],
    referrences : defaultReferrences, 
    contacts : contacts,
    practices : []
}

class Master extends React.Component  {
    constructor(props) {
        super(props);
        // const patientDataStorage = localStorage.getItem('patientData');
        // const encryptedAdmin = localStorage.getItem('admin');
        // const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
        // const adminDataStorage = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


        this.state = {
            adminData: adminDataInit,
            patientData: patientDataInit,
            type: "Consultation", 
            settingComponent: "",
 
        };
        this.saveAdminRef = React.createRef();
    }

    componentDidMount() {
        const patientDataStorage = localStorage.getItem('patientData');
        if (patientDataStorage) {
            this.setState({ patientData: JSON.parse(patientDataStorage) });
        } else {
            this.setState({patientData: patientDataInit})
        }
        const encryptedAdmin = localStorage.getItem('admin');
        if (encryptedAdmin) {
            const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
            const adminDataStorage = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.setState({adminData: adminDataStorage})
        } else {
            this.setState({adminData:adminDataInit})
        }
    }

    componentDidUpdate(prevProps, prevState) {
      // Check if patientData in state has changed
      if (prevState.patientData !== this.state.patientData) {
          // Save the updated patientData to localStorage
          localStorage.setItem('patientData', JSON.stringify(this.state.patientData));
      }
  }
    // Update patient data and save to localStorage
    loadPatientData = (patientData) => {
        this.setState({ patientData }, () => {
            localStorage.setItem('patientData', JSON.stringify(this.state.patientData));
        });
    }

    // Save patient data method, similar to updatePatientData
    savePatientData = (patientData) => {
        this.setState({ patientData }, () => {
            localStorage.setItem('patientData', JSON.stringify(this.state.patientData));
        });
    }

    changeComponent = (type) => {
        this.setState({type})
    }

    changeSettingComponent = (type) => {
        this.setState({settingComponent: type})
    }

    saveAdminData = (data) => {
        if (this.saveAdminRef.current) {
            this.saveAdminRef.current.saveAdminToLocalStorage(data); // Calling the child's function
          }
    }

    render() {
        const {type, patientData, adminData, settingComponent } = this.state;
  
        return (
        <div className='ml-10 grid grid-cols-6' style={{gridTemplateRows: "minmax(50px, auto) minmax(320px, auto) minmax(20px, auto) minmax(300px, auto)"}}>
            <Menu 
                loadPatientData={this.loadPatientData}
                patientData={this.state.patientData}
                type={this.state.type}        
                changeComponent={this.changeSettingComponent}   
                adminData={this.state.adminData}
                ref={this.saveAdminRef}
            />
            
            <Sidebar 
                component={this.changeComponent}
                patientData={patientData}
            />

            {type !== "Settings" && Object.keys(patientData).length !== 0 && (
            <>
             <Profile 
                patientData={patientData}
                updatePatientData={this.savePatientData}
            />

            <Practitioner 
                patientData={patientData}
                updatePatientData={this.savePatientData}
                adminData={adminData}
                
            />
            <Team
                patientData={patientData}
                updatePatientData={this.savePatientData}
                adminData={adminData}
            />
            </>
            )}
            {type !== "Chat" && Object.keys(patientData).length !== 0 && (
            <Tools patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}

            {type === "Consultation" && Object.keys(patientData).length !== 0 && (
                <Consult patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "General" && Object.keys(patientData).length !== 0 && (
                <General patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData} />
            )}
            {type === "CDM" && Object.keys(patientData).length !== 0 &&(
                <CDM patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Mental Health" && Object.keys(patientData).length !== 0 && (
                <MentalHealth patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Health Assessment" && Object.keys(patientData).length !== 0 && (
                <HealthAssessment patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Settings" && Object.keys(patientData).length !== 0 && (
                <Settings patientData={patientData}  updatePatientData={this.savePatientData} saveData={this.saveAdminData} adminData={adminData} settingComponent={settingComponent}/>
            )}
             {type === "Chat" && Object.keys(patientData).length !== 0 && (
                <ChatRecord patientData={patientData}  updatePatientData={this.savePatientData}/>
            )}
            <Comment />

        </div>
        )
    }


}
const MasterWithNavigation = (props) => {
    const navigate = useNavigation();
    return <Master {...props} navigate={navigate} />;
};

export default MasterWithNavigation;

import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//tools
import K10 from './MetricComponents/psychometric/k10';
import PSC from './MetricComponents/psychometric/PSC';
import ACE from './MetricComponents/psychometric/ACE';
import PCL5 from './MetricComponents/psychometric/PCL5';
import DASS21 from './MetricComponents/psychometric/DASS21';
import ASRS from './MetricComponents/psychometric/ASRS';
import VADRS from './MetricComponents/psychometric/VADRS';
import AQ from './MetricComponents/psychometric/AQ';
import GDS from './MetricComponents/psychometric/GDS';
import EPDS from './MetricComponents/psychometric/EPDS';
import EDEQ from './MetricComponents/psychometric/EDEQ';


const toolList = [
    { label: "K-10", tool: 'K10', subtitle: 'Kessler Distress Scale', note:"For initial check and monitoring of level of distress in adult."},
    { label: "DASS-21", tool: "DASS21", subtitle : "Depression Anxiety Stress Scale", note:"Initial and monitoring level of depression, anxiety and stress in adult."},
    { label: "GDS", tool: "GDS", subtitle : "Geriatric Depression Scale", note:"To be used for older adult above 65 years old to measure level of depression."},
    { label: "PSC", tool: "PSC", subtitle : "Paediatric Symptoms Checklist", note:"Identify children with behaviour and emotional difficulties."},
    { label: "PCL-5", tool: "PCL5", subtitle : "PTSD checklist", note:"For initial and monitoring adult with PTSD symptoms."},
    { label: "ASRS v1.1", tool: "ASRS", subtitle : "Adult ADHD Self Report Scale", note:"Screening tool for adult with possible ADHD symptoms"},
    { label: "VADRS", tool: "VADRS", subtitle : "Vanderbilt ADHD Diagnostic Rating Scale", note:"Screening tool for childe age 6 to 12 with possible ADHD and other related comorbid symptoms"},
    { label: "AQ score", tool: "AQ", subtitle : "Adult Autism Quotient Score", note:"To check possibility of adult with autism symptoms"},
    { label: "ACE", tool: "ACE", subtitle : "Adverse Childhood Event", note:"Measure burden of significant childhood event"},
    { label: "EDE-Q", tool: "EDEQ", subtitle : "Eating Disorder Examination Questionnaire ", note:"Diagnostic tool for Eating Disorder"},
    { label: "EPDS", tool: "EPDS", subtitle : "Edinburgh Postnatal Depression Scale", note:"Check symptoms of depression for pre and post partum"}
]


const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Tools extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tool : "", 
            metric : [],
            userData: ''
        };
        this.displayTools = this.displayTools.bind(this);
    };

    componentDidMount() {
        this.fetchUserData();
    }
    
    fetchUserData = async () => {
        try {
          const response = await axios.post(`${EndPoint}/user/reqData`,{}, {withCredentials: true});
          this.setState({ userData: response.data });
        } catch (error) {
          console.error('Error fetching data', error);
          // Handle errors here
        }
    };


    displayTools = (e) => {

        this.setState({tool: e.target.id})
   
    }

    checkTool =(tool) => {
        var metric = this.state.metric;
        if (metric.includes(tool)) {
            metric = metric.filter(tx => tx !== tool);
        } else {
            metric.push(tool)
        }
        this.setState({metric:metric})
    }



    renderTool() {

        return toolList.map((tx, index) => {
            return (
            <div className="grid grid-cols-2 w-96 h-40 relative bg-slate-100 border border-slate-200 shadow-lg rounded-lg" key={index}>
                <div className="p-4 text-left text-gray-800 text-3xl relative">
                    <p className='py-1 font-semibold'>{tx.label}</p>
                    <hr></hr>
                    <p className='text-sm mt-2 font-light white'>{tx.subtitle}</p>
                    <input type='checkbox' onChange={()=>this.checkTool(tx.tool)} className='absolute border border-slate-300 h-8 w-8 bottom-2 left-2'></input>
                    
                </div>
                <div className='text-sm p-4'>{tx.note}
                <div>

                    <button onClick={this.displayTools} id={tx.tool} className='bg-sky-700 bottom-3 right-3 absolute hover:bg-sky-800 w-36 text-white px-4 py-2 rounded-lg shadow-xl'>Open</button>
                </div>
                </div>
            </div>
            )
        })
    }

    copyLink() {
        var url = document.getElementById('toolLink').innerHTML;
        navigator.clipboard.writeText(url);
        toast.success(`Message copied to clipboard. Paste anywhere`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar : false
        });
    }
    render() {
        var userData = this.state.userData;
        var tool = this.state.tool;
        var metric = this.state.metric;
        var newMetric = metric.map(item => item.replace(/,/g, ', '));
        var linkURL = `https://tools.caredevo.com/?metric=${newMetric}&type=patient&sender=${userData}`;
        var command = `Please click this link to do the psychometric test requested by your medical practitioner :  ${linkURL}`;
        return (
            <>
            <div className='text-center text-gray-900 text-2xl xs:text-xl font-semibold px-10 py-4'>Psychometric tool.</div>
            <div className='text-center text-gray-900 text-md'>Click open to use the tool or check to create a list of tools to be sent to patient as a message.</div>
            <div className="flex flex-row flex-wrap justify-center mx-4 my-4 xs:mx-1 xs:my-2 mb-10 xs:mb-6 gap-4 xs:gap-4">
                {this.renderTool()}
              
            </div>
              {(() => { 
                switch (tool) {
                    case "PSC" :
                        return (
                            <PSC
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "K10":
                        return (
                            <K10 
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "ACE":
                        return (
                            <ACE
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "PCL5":
                        return (
                            <PCL5
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "DASS21":
                        return (
                            <DASS21
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "ASRS":
                        return (
                            <ASRS
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "VADRS":
                        return (
                            <VADRS
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "AQ":
                        return (
                            <AQ
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "GDS":
                        return (
                            <GDS
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "EPDS":
                        return (
                            <EPDS
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                    case "EDEQ":
                        return (
                            <EDEQ
                            tool={true}
                            ref={ref => (this.child = ref)}
                            setState={state => this.setState(state)}
                            />
                        )
                        
                    default:
                        return null    
                }

            })()} 
            <div>
            <div className='text-center p-2'>
                <div><textarea id='toolLink' className='border border-slate-100 rounded shadow-lg resize-none h-[140px] w-[330px] text-sm' value = {command} /></div>
                <div><button onClick={this.copyLink}  className='bg-sky-700 hover:bg-sky-800 w-36 text-white px-4 py-2 rounded-lg shadow-xl'>Copy</button></div>
            </div>
            <ToastContainer />
            </div>
            </>
        )
    }



}

export default Tools;
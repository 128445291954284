import React, { Component } from 'react';
import axios from 'axios';
import RecordLocal from '../../utilities/RecordLocal';

class LocalAI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null,
      userMessage: "Where is Mexico?",
    };

    // Dynamically set the base URL
    this.baseURL = this.isSafari() ? 'http://localhost:8010' : 'http://127.0.0.1:8010';
  }

  // Helper function to detect Safari
  isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  handleInputUpdate = async (newTranscription) => {
    this.setState({ userMessage: newTranscription });
    this.handleChatCompletion();
  };

  handleChatCompletion = () => {
    const requestData = {
      messages: [
        {
          content: "You are a helpful assistant.",
          role: "system",
        },
        {
          content: this.state.userMessage,
          role: "user",
        },
      ],
      model: "llama-3.2-3b-instruct",
    };

    axios
      .post(`${this.baseURL}/v1/chat/completions`, requestData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(response.data);
        this.setState({ response: response.data.choices[0].message.content });
      })
      .catch((error) => {
        console.error('There was an error making the request!', error);
      });
  };

  handleInputChange = (event) => {
    this.setState({ userMessage: event.target.value });
  };

  render() {
    const { response, userMessage } = this.state;

    return (
      <div className="flex flex-col min-h-screen bg-slate-100 p-4">
        <h1 className="text-center text-lg font-bold mb-4">Chat Completion</h1>
        <div>
          <RecordLocal onInputUpdate={this.handleInputUpdate} />
        </div>

        <textarea
          type="text"
          value={userMessage}
          onChange={this.handleInputChange}
          className="p-2 border-gray-200 rounded mb-4 mx-4 h-24 resize-none"
          placeholder="Enter your message here"
        />

        <button
          onClick={this.handleChatCompletion}
          className="p-2 bg-sky-800 text-white rounded shadow m-4"
        >
          Ask
        </button>

        {response && (
          <div>
            <textarea
              value={response}
              readOnly
              className="w-full h-[500px] border-gray-100 resize-none p-4"
            />
          </div>
        )}
      </div>
    );
  }
}

export default LocalAI;

import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import AWS from "aws-sdk";
import {
  Slate,
  Editable,
  withReact,
  useSlate,
} from "slate-react";
import {
  BoldIcon, 
  ItalicIcon, 
  CodeBracketIcon, 
  UnderlineIcon,
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  H1Icon,
  H2Icon,
  H3Icon,
  ListBulletIcon,
  NumberedListIcon,
  ChatBubbleLeftEllipsisIcon

} from "@heroicons/react/24/outline";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import axios from "axios";
import { Button, Icon, Toolbar } from "./icons";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const initialValue = [
    {
      type: 'paragraph',
      children: [
        { text: 'This is editable ' },
        { text: 'rich', bold: true },
        { text: ' text, ' },
        { text: 'much', italic: true },
        { text: ' better than a ' },
        { text: '<textarea>', code: true },
        { text: '!' },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: "Since it's rich text, you can do things like turn a selection of text ",
        },
        { text: 'bold', bold: true },
        {
          text: ', or add a semantically rendered block quote in the middle of the page, like this:',
        },
      ],
    },
    {
      type: 'block-quote',
      children: [{ text: 'A wise quote.' }],
    },
    {
      type: 'paragraph',
      align: 'center',
      children: [{ text: 'Try it out for yourself!' }],
    },
  ]



const EndPoint = process.env.REACT_APP_API_ENDPOINT;

// Configure AWS S3
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Add to .env
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Add to .env
  region: process.env.REACT_APP_AWS_REGION, // Example: "us-east-1"
});

const s3 = new AWS.S3();
const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;

const CreateBlog = () => {
  const navigate = useNavigate();
const [title, setTitle] = useState("");
const [summary, setSummary] = useState("");
const [editorValue, setEditorValue] = useState(initialValue);
const [image, setImage] = useState(null); // State to hold the file to upload
const [imageUrl, setImageUrl] = useState(null); // State to hold the URL of the uploaded image
const [imagePreview, setImagePreview] = useState(null); // State for the local preview

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const content = JSON.stringify(editorValue);
    if (!title || !summary || !content ) {
      console.error("All fields are required.");
      return;
    }

    try {

      // Upload image to S3 and get the URL
      let uploadedImageUrl = null;
      if (image) {
        uploadedImageUrl = await uploadToS3(image);
      }

      // Save blog data
      const blogData = {
        title,
        summary,
        content,
        imageUrl: uploadedImageUrl, // Use the S3 image URL here
      };
      
      const response = await axios.post(`${EndPoint}/blog`, blogData, {
        withCredentials: true,
      });
      console.log("Blog created:", response.data);
    } catch (error) {
      console.error("Error creating blog:", error);
    }

    navigate("/blog-list"); 
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const uploadToS3 = async (file) => {
    console.log("Uploading file:", file);
  
    const params = {
      Bucket: bucketName,
      Key: `uploads/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };
  
    try {
      const { Location } = await s3.upload(params).promise();
      console.log("File uploaded to S3:", Location);
      return Location;
    } catch (err) {
      console.error("S3 Upload Error:", err);
      throw err;
    }
  };
  

  return (
    <div className="w-full mx-auto p-10">
      <h1 className="text-3xl font-bold mb-6">Create New Blog</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 font-medium mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Summary</label>
          <textarea
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md resize-none focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Content</label>
          <Slate
            editor={editor}
            initialValue={editorValue}
            onChange={(value) => setEditorValue(value)}
          >
            <Toolbar>
              <MarkButton format="bold" IconComponent={BoldIcon} />
              <MarkButton format="italic" IconComponent={ItalicIcon} />
              <MarkButton format="underline" IconComponent={UnderlineIcon} />
              <MarkButton format="code" IconComponent={CodeBracketIcon} />
              <BlockButton format="heading-one" IconComponent={H1Icon} />
              <BlockButton format="heading-two" IconComponent={H2Icon} />
              <BlockButton format="heading-three" IconComponent={H3Icon} />
              <BlockButton format="block-quote" IconComponent={ChatBubbleLeftEllipsisIcon} />
              <BlockButton format="numbered-list" IconComponent={NumberedListIcon} />
              <BlockButton format="bulleted-list" IconComponent={ListBulletIcon} />
              <BlockButton format="left" IconComponent={Bars3BottomLeftIcon} />
              <BlockButton format="center" IconComponent={Bars3Icon} />
              <BlockButton format="right" IconComponent={Bars3BottomRightIcon} />
            </Toolbar>
            <Editable
             className="h-[1200px] overflow-y-auto border border-slate-200 p-4 rounded-md"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Write your blog content here..."
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
          </Slate>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Upload Image
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={handleImageChange}
            className="hidden"
            required
          />
           <label
            htmlFor="fileInput"
            className="inline-block m-2 p-2 bg-sky-700 hover:bg-sky-800 text-white rounded shadow cursor-pointer"
          >
            Choose File
          </label>
          {imagePreview && (
            <div className="w-32 h-32 border border-gray-300 rounded-md overflow-hidden">
              <img
                src={imagePreview}
                alt="Thumbnail Preview"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-sky-700 hover:bg-sky-800 text-white px-4 py-2 rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isAlign = TEXT_ALIGN_TYPES.includes(format);

  if (isAlign) {
    Transforms.setNodes(
      editor,
      { align: isActive ? undefined : format }, // Toggle alignment
      { match: (n) => SlateElement.isElement(n), split: true }
    );
    return;
  }

  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n.type === format || n.align === format),
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const alignment = element.align || "left";

  switch (element.type) {
    case "block-quote":
      return <blockquote className="border-l-4 border-sky-600 p-4 italic text-sky-700 text-lg font-semibold" {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul className="list-disc pl-8" {...attributes}>{children}</ul>;
    case "numbered-list":
      return <ol className="list-decimal pl-8" {...attributes}>{children}</ol>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "paragraph":
      return (
        <p
          className={`text-${alignment}`}
          {...attributes}
        >
          {children}
        </p>
      );
    case "heading-one":
      return <h1 className="text-3xl font-bold" {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 className="text-2xl font-bold" {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 className="text-xl font-bold" {...attributes}>{children}</h3>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.code) children = <code>{children}</code>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, IconComponent }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>
        <IconComponent className="w-5 h-5 mx-2" />
      </Icon>
    </Button>
  );
};

const MarkButton = ({ format, IconComponent }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>
        <IconComponent className="w-5 h-5" />
      </Icon>
    </Button>
  );
};



export default CreateBlog;

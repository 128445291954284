import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import HAPdf from '../../docs/HealthAssessment.pdf';

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Extract the day, month, and year
  const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits for the day
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for the month
  const year = dateObject.getFullYear();

  // Format the date as "21/03/2024"
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

function wrapText(text, maxLength = 60) {
    if (!text) return '';
    const words = text.split(' ');
    let wrappedText = '';
    let line = '';

    words.forEach((word) => {
        if (line.length + word.length + 1 > maxLength) {
            wrappedText += line.trim() + '\n';
            line = '';
        }
        line += word + ' ';
    });

    wrappedText += line.trim(); // add the last line
    return wrappedText;
}



export const printHA = async (event, formData, dateOfPlan, reviewDate, signature) => {
  event.preventDefault();
  
  // Fetch your existing PDF as an array buffer
  const existingPdfBytes = await fetch(HAPdf).then(res => res.arrayBuffer());

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
  const page = pdfDoc.getPage(0); // Get the first page of the PDF

  const width = 595.28; // A4 width
  const height = 841.89; // A4 height
  const margin = 50;
  const fontSize = 12;


  // Load a standard font
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

  // Get the form in the PDF
  const form = pdfDoc.getForm();

  // Fill up Profile
  const profile = formData.profile;
  const personText = `${profile.firstName} ${profile.lastName}\nDOB : ${formatDate(profile.dob)}\nGender : ${profile.gender}\nPronoun : ${profile.pronoun}\n${profile.address1} ${profile.address2} \n${profile.suburb}, ${profile.state} ${profile.postcode}`;
  const Profile = form.getTextField('Patient');
  Profile.setText(personText);

  // Fill up Practitioner
  const practitioner = formData.practitioner;
  const practitionerText = `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}\n${practitioner.practiceName} \n${practitioner.address1} ${practitioner.address2}\n ${practitioner.suburb}, ${practitioner.state} ${practitioner.postcode}\nPhone : ${practitioner.phone}\nEmail : ${practitioner.email}\n${practitioner.providerNumber}`;
  const Practitioner = form.getTextField('GP');
  Practitioner.setText(practitionerText);

  // Helper function to format history data safely
  const formatHistory = (data, eachItemCallback, defaultMessage = 'No data available.') => {
    return (data && data.length > 0) ? data.map(eachItemCallback).join('\n') : defaultMessage;
  };

  // Fill up medicalHistory
  const history = formData.history ?? {}; // Default to an empty object if history is null/undefined

  const medicalHistory = history.activeHistory ?? []; // Default to an empty array
  const eachHistory = (item) => {
    return `${item.date ? formatDate(item.date) : "                  "} - ${item.condition}`;
  };
  const medicalHistoryText = formatHistory(medicalHistory, eachHistory);
  const medicalHistoryField = form.getTextField("History");
  medicalHistoryField.setText(medicalHistoryText);

  // Fill up pastHistory (PMH)
  const pastHistory = history.pastHistory ?? []; // Default to an empty array
  const eachPMH = (item) => {
    return `${item.date ? formatDate(item.date) : "                  "} - ${item.condition}`;
  };
  const pastHistoryText = formatHistory(pastHistory, eachPMH);
  const pastHistoryField = form.getTextField("PMH");
  pastHistoryField.setText(pastHistoryText);

  // Fill up familyHistory (FH)
  const familyHistory = history.familyHistory ?? []; // Default to an empty array
  const eachFH = (item) => {
    return `${item.relation} - ${item.condition}, ${item.notes}`;
  };
  const familyHistoryText = formatHistory(familyHistory, eachFH);
  const familyHistoryField = form.getTextField("FH");
  familyHistoryField.setText(familyHistoryText);

  // Fill up Emergency contacts (Emergency)
  const emergency = history.emergency ?? []; // Default to an empty array
  const eachContact = (item) => {
    return `${item.name}, ${item.relationship} : ${item.contact}`;
  };
  const emergencyText = formatHistory(emergency, eachContact);
  const emergencyField = form.getTextField("Emergency");
  emergencyField.setText(emergencyText);

  // Fill up Allergies (Allergy)
  const allergies = history.allergies ?? []; // Default to an empty array
  const eachAllergy = (item) => {
    return `${item.substance}, ${item.severity}`;
  };
  const allergiesText = formatHistory(allergies, eachAllergy);
  const allergiesField = form.getTextField("Allergy");
  allergiesField.setText(allergiesText);

  // Fill up medications (Medication)
  const medications = history.medications ?? []; // Default to an empty array
  const eachMed = (item) => {
    return `${item.name} ${item.dose} ${item.unit} ${item.form} ${item.frequency}`;
  };
  const medicationsText = formatHistory(medications, eachMed);
  const medicationsField = form.getTextField("Medication");
  medicationsField.setText(medicationsText);

  // Fill up Alcohol (Alcohol)
  const alcohol = history.alcohol ?? {}; // Default to an empty object
  const alcoholText = `${alcohol.status || 'No status'}, ${alcohol.notes || 'No notes'}`;
  const alcoholField = form.getTextField('Alcohol');
  alcoholField.setText(alcoholText);

  // Fill up Smoking (Smoking)
  const smoking = history.smoking ?? {}; // Default to an empty object
  const smokingText = `${smoking.status || 'No status'}, ${smoking.notes || 'No notes'}`;
  const smokingField = form.getTextField('Smoking');
  smokingField.setText(smokingText);

  // Fill up Substance (Substance)
  const substance = history.substance ?? {}; // Default to an empty object
  const substanceText = `${substance.status || 'No status'}, ${substance.notes || 'No notes'}`;
  const substanceField = form.getTextField('Substance');
  substanceField.setText(substanceText);


    // Fill up Examination
    const examination = formData.healthassessment;

    const examinationContent1 = `
Vision:
${wrapText(examination.vision) || 'N/A'}

Hearing:
${wrapText(examination.hearing) || 'N/A'}

Fall and Home Safety:
${wrapText(examination.fallAndHomeSafety) || 'N/A'}

Skin:
${wrapText(examination.skin) || 'N/A'}

Feet:
${wrapText(examination.feet) || 'N/A'}

Nutrition:
${wrapText(examination.nutrition) || 'N/A'}

Oral Health:
${wrapText(examination.oralHealth) || 'N/A'}

Cognition:
${wrapText(examination.cognition) || 'N/A'}

Mental Health:
${wrapText(examination.mentalHealth) || 'N/A'}

Urine Continence:
${wrapText(examination.urineContinence) || 'N/A'}

Feacal Continence:
${wrapText(examination.feacalContinence) || 'N/A'}

Mobility:
${wrapText(examination.mobility) || 'N/A'}
`;

const examinationContent2 = `
Activity:
${wrapText(examination.activity) || 'N/A'}

Social:
${wrapText(examination.social) || 'N/A'}

Housing:
${wrapText(examination.housing) || 'N/A'}

Legal:
${wrapText(examination.legal) || 'N/A'}

Finance:
${wrapText(examination.finance) || 'N/A'}

Driving:
${wrapText(examination.driving) || 'N/A'}

Advanced Care Directive:
${wrapText(examination.advancedCareDirective) || 'N/A'}

Services:
${wrapText(examination.services) || 'N/A'}

Examination:
${wrapText(examination.examination) || 'N/A'}

Surveillance:
${wrapText(examination.surveillance) || 'N/A'}

Vaccination:
${wrapText(examination.vaccination) || 'N/A'}
`;


    // Set the text in the Examination field
    const Examination1 = form.getTextField('Examination1');
    Examination1.setText(examinationContent1);

    const Examination2 = form.getTextField('Examination2');
    Examination2.setText(examinationContent2);


    // Fill up Problems
    const problems = formData.planning;

    const eachProblem = (item, index) => {
        // Sort the plans with 'doing' first, 'plan' next, and 'done' last
        const sortedPlans = item.plan.sort((a, b) => {
            const order = { 'doing': 1, 'plan': 2, 'done': 3 };
            return order[a.status] - order[b.status];
        });
    
        return `
Problem #${index + 1}: \n${wrapText(item.problem)}
Notes : \n${wrapText(item.notes)} \n
Goal : 
${item.goal.map(entry => `- ${entry.description}`).join('\n')}
Plan : 
${sortedPlans.map(plan => {
    let statusText;
    switch (plan.status) {
    case 'doing':
        statusText = 'Ongoing';
        break;
    case 'plan':
        statusText = 'Planned';
        break;
    case 'done':
        statusText = 'Done';
        break;
    default:
        statusText = plan.status;
    }
    return `- ${plan.entry}\n  Status: ${statusText}`;
}).join('\n')}

        `;
    };
    
    
    const problemTexts = problems.map(eachProblem);
    const firstFiveProblems = problemTexts.slice(0, 5).join('\n');
    const remainingProblems = problemTexts.slice(5).join('\n');

    // Get the text fields
    const Problem1 = form.getTextField('Problem1');
    const Problem2 = form.getTextField('Problem2');

    // Set the text for each field
    Problem1.setText(firstFiveProblems);

    if (remainingProblems) {
        Problem2.setText(remainingProblems);
    } else {
        Problem2.setText('');  
    }

  // Fill up Date of Plan
  const PlanDate = form.getTextField('Date');
  PlanDate.setText(formatDate(dateOfPlan));

  // Fill up Review Date
  const ReviewDate = form.getTextField('ReviewDate');
  ReviewDate.setText(formatDate(reviewDate));

  // Add the signature to the bottom of the second page
  if (signature) {
      const pages = pdfDoc.getPages();
      const secondPage = pages[3]; // Get the second page

      if (secondPage) {
          // Convert the signature data URL to an image that can be embedded in the PDF
          const signatureImage = await pdfDoc.embedPng(signature);

          // Get the dimensions of the signature image
          const signatureDims = signatureImage.scale(0.5); // Scale the signature if necessary

          // Draw the signature on the second page at the bottom
          secondPage.drawImage(signatureImage, {
              x: 30, // Adjust X coordinate as needed
              y: 540, // Y coordinate near the bottom of the page
              width: signatureDims.width,
              height: signatureDims.height,
          });
      }
  }

  // Flatten the form to prevent further editing
  form.flatten();

  // Serialize the PDF to bytes (Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Create a Blob and a link element to allow the user to download the PDF
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Open the PDF in a new browser window
  const newWindow = window.open(blobUrl, '_blank');
  if (!newWindow) {
      // Handle cases where the browser blocked the new window
      alert('Please allow pop-ups to view the PDF.');
  }
};

export const printTCA = async (event, formDataArray, patient, practitioner, signature) => {
    event.preventDefault();
  
    // Fetch your existing PDF as an array buffer
    const existingPdfBytes = await fetch(TCAPdf).then(res => res.arrayBuffer());
  
    // Create a new PDFDocument to combine all individual PDFs into one
    const combinedPdfDoc = await PDFDocument.create();
  
    // Load a font for the sample letter
    const font = await combinedPdfDoc.embedFont(StandardFonts.Helvetica);
  
    // Iterate over each object in formDataArray to create a PDF for each
    for (const formData of formDataArray) {
      // Add a new page for the GP letter
      const letterPage = combinedPdfDoc.addPage([595.28, 841.89]); // A4 size in points
      const { width, height } = letterPage.getSize();
  
      // Sample letter text with line breaks
      const letterText = `
  To : ${formData.title} ${formData.firstName} ${formData.lastName},
       ${formData.address1}
       ${formData.address2}
       ${formData.suburb}, ${formData.state} ${formData.postcode}
  
  Dear ${formData.firstName},
  
  We are referring the ${patient.firstName} ${patient.lastName} for further evaluation and management.
  
  Please find the attached detailed plan.
  
  Sincerely,
  
  
  
  ${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}
      `;
  
      // Split the letterText by newlines to handle line breaks
      const lines = letterText.trim().split('\n');
  
      // Set initial Y position for text
      let yPosition = height - 100;
      const lineHeight = 14; // Adjust line height as needed
  
      // Draw each line of text
      lines.forEach((line) => {
        letterPage.drawText(line, {
          x: 50,
          y: yPosition,
          size: 12,
          font,
          color: rgb(0, 0, 0),
          maxWidth: width - 100,
        });
        yPosition -= lineHeight; // Move to the next line
      });
  
      // Embed the signature image if it exists
      if (signature) {
        const signatureImage = await combinedPdfDoc.embedPng(signature);
        letterPage.drawImage(signatureImage, {
          x: 50, // Adjust the x position
          y: yPosition - 60, // Adjust the y position (below the "Sincerely" text)
          width: 150, // Adjust the width of the signature
          height: 50, // Adjust the height of the signature
        });
      }
  
      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
  
      // Get the form in the PDF
      const form = pdfDoc.getForm();
  
      // Define the fields and values to update based on formData
      const fieldsToUpdate = [
        { field: "GPName", value: `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}` },
        { field: "GPAddress", value: `${practitioner.practiceName}, ${practitioner.address1} ${practitioner.address2}, ${practitioner.suburb} ${practitioner.state}` },
        { field: "ProviderNumber", value: `${practitioner.providerNumber}` },
        { field: "GPPostcode", value: `${practitioner.postcode}` },
        { field: "PatientName", value: `${patient.firstName} ${patient.lastName}` },
        { field: "MedicareNumber", value: `${patient.medicare}` },
        { field: "PatientAddress", value: `${patient.address1} ${patient.address2}, ${patient.suburb} ${patient.state}` },
        { field: "PatientPostcode", value: `${patient.postcode}` },
        { field: "AHPName", value: `${formData.firstName} ${formData.lastName}` },
        { field: "AHPAddress", value: `${formData.address1} ${formData.address2}, ${formData.suburb} ${formData.state}` },
        { field: "AHPPostcode", value: `${formData.postcode}` },
        { field: "ATSI", value: formData.profession === "ATSI Worker" ? formData.visits : `` },
        { field: "Audiologist", value: formData.profession === "Audiologist" ? formData.visits : `` },
        { field: "Chiropractor", value: formData.profession === "Chiropractor" ? formData.visits : `` },
        { field: "DiabetesEducator", value: formData.profession === "Diabetes Educator" ? formData.visits : `` },
        { field: "Dietitian", value: formData.profession === "Dietitian" ? formData.visits : `` },
        { field: "ExercisePhysiologist", value: formData.profession === "Exercise Physiologist" ? formData.visits : `` },
        { field: "MHWorker", value: formData.profession === "Mental Health Worker" ? formData.visits : `` },
        { field: "OT", value: formData.profession === "Occupational Therapist" ? formData.visits : `` },
        { field: "Osteopath", value: formData.profession === "Osteopath" ? formData.visits : `` },
        { field: "Physio", value: formData.profession === "Physiotherapist" ? formData.visits : `` },
        { field: "Podiatrist", value: formData.profession === "Podiatrist" ? formData.visits : `` },
        { field: "Psychologist", value: formData.profession === "Psychologist" ? formData.visits : `` },
        { field: "Speech", value: formData.profession === "Speech Therapist" ? formData.visits : `` },
      ];
  
      // Update the fields in the PDF
      fieldsToUpdate.forEach(({ field, value }) => {
        const fieldToUpdate = form.getTextField(field);
        fieldToUpdate.setText(value);
      });
  
      // Fill up the Date field with the current date
      const DateField = form.getTextField('Date');
      const dateNow = new Date().toLocaleDateString('en-GB');
      DateField.setText(dateNow);
  
      // Embed the signature image in the form PDF if it exists
      if (signature) {
        const signatureImage = await pdfDoc.embedPng(signature);
        const signaturePage = pdfDoc.getPage(0); // Assuming signature is on the first page
  
        // Positioning the signature image in the form (Adjust x, y, width, and height as needed)
        signaturePage.drawImage(signatureImage, {
          x: 50, // Adjust the x position
          y: 120, // Adjust the y position (bottom of the page)
          width: 150, // Adjust the width of the signature
          height: 50, // Adjust the height of the signature
        });
      }
  
      // Flatten the form fields to make them non-editable
      form.flatten();
  
      // Serialize the individual PDF to bytes (Uint8Array)
      const pdfBytes = await pdfDoc.save();
  
      // Load the individual PDF into a new PDFDocument to extract pages
      const individualPdfDoc = await PDFDocument.load(pdfBytes);
  
      // Copy all pages from the individual PDF to the combined PDF
      const [copiedPage] = await combinedPdfDoc.copyPages(individualPdfDoc, [0]);
      combinedPdfDoc.addPage(copiedPage);
    }
  
    // Serialize the combined PDF to bytes (Uint8Array)
    const combinedPdfBytes = await combinedPdfDoc.save();
  
    // Create a Blob for the combined PDF
    const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
  
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
  
    // Open the combined PDF in a new browser window or tab
    const newWindow = window.open(blobUrl, '_blank');
    if (!newWindow) {
      alert('Please allow pop-ups to view the PDF.');
    }
};
  
  
  
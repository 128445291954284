import React, { useState, useRef } from 'react';
import { transcribeAudioLocal } from './AI'; // Whisper integration
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { MicrophoneIcon } from '@heroicons/react/20/solid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const EndPoint = process.env.REACT_APP_API_ENDPOINT;


const RecordLocal = (props) => {
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const audioChunksRef = useRef([]);
    const audioContext = useRef(new AudioContext());
  
    const toggleRecording = () => {
      if (recording) {
        stopRecording();
      } else {
        startRecording();
      }
    };
  
    const startRecording = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          setMediaStream(stream);
  
          recorder.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };
  
          recorder.start();
          setRecording(true);
          toast.info('Recording started...', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
        })
        .catch((error) => {
          console.error('Error accessing media devices:', error);
          toast.error('Unable to access microphone.');
        });
    };
  
    const stopRecording = () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
        toast.info('Processing recording...', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
  
        mediaRecorder.onstop = async () => {
          const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          audioChunksRef.current = []; // Clear audio chunks after recording
          const wavBlob = await convertToWav(blob);
  
          if (wavBlob) {
            splitAndTranscribe(wavBlob);
          }
  
          // Stop all tracks of the media stream
          if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
          }
        };
  
        setRecording(false);
      }
    };
  
    const splitAndTranscribe = async (blob) => {
      const totalSize = blob.size;
      const chunkSizeLimit = 20 * 1024 * 1024; // 20 MB
      let start = 0;
  
      const toastId = toast.info('Transcription in progress...', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
  
      while (start < totalSize) {
        const end = Math.min(start + chunkSizeLimit, totalSize);
        const chunk = new Blob([blob.slice(start, end)], { type: 'audio/wav' });
  
        try {
          const transcription = await transcribeAudioLocal(chunk);
          if (props.onInputUpdate) {
            props.onInputUpdate(transcription, toastId);
          }
          toast.update(toastId, {
            render: 'Transcription success!',
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
        } catch (error) {
          console.error('Error during transcription:', error);
          toast.update(toastId, {
            render: 'Transcription failed!',
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
          return; // Stop further processing on error
        }
  
        start = end;
      }
    };
  
    const convertToWav = async (blob) => {
      try {
        const arrayBuffer = await blob.arrayBuffer();
        const audioBuffer = await audioContext.current.decodeAudioData(arrayBuffer);
  
        // OfflineAudioContext for re-encoding
        const offlineContext = new OfflineAudioContext(
          audioBuffer.numberOfChannels,
          audioBuffer.length,
          audioBuffer.sampleRate
        );
        const source = offlineContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(offlineContext.destination);
        source.start(0);
  
        const renderedBuffer = await offlineContext.startRendering();
        return audioBufferToWav(renderedBuffer);
      } catch (error) {
        console.error('Error converting to WAV:', error);
        toast.error('Failed to convert audio to WAV.');
        return null;
      }
    };
  
    const audioBufferToWav = (audioBuffer) => {
      const numChannels = audioBuffer.numberOfChannels;
      const length = audioBuffer.length * numChannels * 2 + 44;
      const buffer = new ArrayBuffer(length);
      const view = new DataView(buffer);
  
      writeString(view, 0, 'RIFF');
      view.setUint32(4, 36 + audioBuffer.length * numChannels * 2, true);
      writeString(view, 8, 'WAVE');
      writeString(view, 12, 'fmt ');
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true);
      view.setUint16(22, numChannels, true);
      view.setUint32(24, audioBuffer.sampleRate, true);
      view.setUint32(28, audioBuffer.sampleRate * numChannels * 2, true);
      view.setUint16(32, numChannels * 2, true);
      view.setUint16(34, 16, true);
      writeString(view, 36, 'data');
      view.setUint32(40, audioBuffer.length * numChannels * 2, true);
  
      let offset = 44;
      for (let i = 0; i < audioBuffer.length; i++) {
        for (let channel = 0; channel < numChannels; channel++) {
          const sample = Math.max(-1, Math.min(1, audioBuffer.getChannelData(channel)[i]));
          view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
          offset += 2;
        }
      }
  
      return new Blob([buffer], { type: 'audio/wav' });
    };
  
    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };
  
    return (
      <div className="flex flex-col items-center m-4 h-auto">
        <div className="flex items-center space-x-4 ml-80 sm:ml-[700px]">
          <button
            className={`${
              recording ? 'bg-red-600 hover:bg-red-700' : 'bg-sky-800 hover:bg-sky-900'
            } flex items-center text-white px-2 py-2 rounded-3xl hover:shadow-xl`}
            onClick={toggleRecording}
          >
              <MicrophoneIcon className="w-8 h-8" />
              {recording && <p className='px-2'>Recording</p>}
          </button>
          <div style={{ width: 300, height: 50 }}>
            {recording && mediaRecorder ? (
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                width={300}
                height={50}
                barWidth={3}
                gap={2}
                barColor={'#205680'}
              />
            ) : null}
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  };
  
  export default RecordLocal;

import React, { Component } from "react";
import axios from "axios";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Helmet } from "react-helmet";
import { withHistory } from "slate-history";
import { useNavigation } from "../../utilities/NavigationContext";
import { useAuth } from '../../utilities/AuthContext';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;
const AWS = require("aws-sdk");
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const bucketName = process.env.REACT_APP_S3_BUCKET_NAME

class BlogPost extends Component {
  state = {
    blog: null,
    editor: withHistory(withReact(createEditor())),
    content: [],
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    axios
      .get(`${EndPoint}/blog/${id}`)
      .then((response) => {
        const blog = response.data;

        let parsedContent;
        try {
          parsedContent = JSON.parse(blog.content);
        } catch (error) {
          console.error("Error parsing blog content, using default value:", error);
          parsedContent = [
            {
              type: "paragraph",
              children: [{ text: "Error loading content" }],
            },
          ];
        }

        this.setState({
          blog,
          content: parsedContent,
        });
      })
      .catch((error) => {
        console.error("Error fetching blog:", error);
      });
  }

  handleDelete = async () => {
    const { blog } = this.state;
    const { navigate } = this.props;
    if (!blog) return;
  
    try {
      // Delete the associated image from S3 if it exists
      if (blog.imageUrl) {
        const imageKey = blog.imageUrl.split("/").pop(); // Extract the file key
        await this.deleteFromS3(imageKey);
      }
  
      // Delete the blog from the backend
      await axios.delete(`${EndPoint}/blog/${blog._id}`);
  
      // Redirect to the blog list
      navigate("/blog-list");
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };
  
  // Helper function to delete an object from S3
  deleteFromS3 = async (key) => {
    const params = {
      Bucket: bucketName, // Your bucket name
      Key: `uploads/${key}`, // The key of the file to delete
    };
  
    try {
      await s3.deleteObject(params).promise();
      console.log("Image deleted successfully from S3:", key);
    } catch (err) {
      console.error("Error deleting image from S3:", err);
    }
  };
  
  handleEdit = () => {
    const { navigate } = this.props;
    const { blog } = this.state;
    if (!blog) return;


    navigate(`/edit-post?id=${blog._id}`); // Redirect to the edit blog page
  };

  handleBack = () => {
    const { navigate } = this.props;
    navigate("/blog-list"); 
  };

  render() {
    const { blog, editor, content } = this.state;
    const { navigate, auth } = this.props; 
  
    console.log(auth)
    if (!blog) {
      return (
        <div className="text-center">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500 mx-auto"></div>
          <p>Loading your blog...</p>
        </div>
      );
    }

    return (
      <div className="w-full mx-auto p-20 relative">
        {/* Add Meta Tags with Helmet */}
        <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.summary} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.summary} />
        <meta property="og:image" content={blog.imageUrl} />
        <meta property="og:url" content={`${window.location.origin}/blog?id=${blog._id}`} />
        <meta property="og:type" content="article" />

          {/* Add Canonical Link */}
          <link rel="canonical" href={`${window.location.origin}/blog?id=${blog._id}`} />
        </Helmet>
        
        {/* Buttons */}
        {auth && auth[0].role && auth[0].role.includes('admin') && (
        <div className="absolute top-4 right-4 flex space-x-4">
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow"
            onClick={this.handleDelete}
          >
            Delete
          </button>
          <button
            className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded shadow"
            onClick={this.handleEdit}
          >
            Edit
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded shadow"
            onClick={this.handleBack}
          >
            Back
          </button>
        </div>
        )}

        {/* Blog Content */}
        <h1 className="text-6xl text-sky-900 font-bold mb-6">{blog.title}</h1>
        <p className="text-gray-600 mb-4">By {blog.author}</p>
        <p className="text-gray-700 text-lg font-semibold mb-6">{blog.summary}</p>
        {blog.imageUrl && (
          <img
            src={`${blog.imageUrl}`}
            alt="Blog"
            className="mt-6"
          />
        )}
        <div className="rounded-md p-4">
          <Slate editor={editor} initialValue={content} onChange={() => {}}>
            <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
          </Slate>
        </div>
        <div className="flex space-x-4 mt-6">
        {/* Facebook Share */}
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `${window.location.origin}/blog?id=${blog._id}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded shadow"
        >
          Share on Facebook
        </a>

        {/* LinkedIn Share */}
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            `${window.location.origin}/blog?id=${blog._id}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-800 hover:bg-blue-900 text-white px-4 py-2 rounded shadow"
        >
          Share on LinkedIn
        </a>
      </div>

     
      </div>
    );
  }
}

const renderElement = ({ attributes, children, element }) => {
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote
          className="border-l-4 border-sky-600 p-4 italic text-sky-700 text-lg font-semibold"
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return <ul className="list-disc pl-8" {...attributes}>{children}</ul>;
    case "numbered-list":
      return <ol className="list-decimal pl-8" {...attributes}>{children}</ol>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "heading-one":
      return <h1 className="text-3xl font-bold" {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 className="text-2xl font-bold" {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 className="text-xl font-bold" {...attributes}>{children}</h3>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const renderLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.code) children = <code>{children}</code>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};


const BlogPostWithNavigation = (props) => {
  const navigate = useNavigation(); 
  const auth = useAuth(); 
  return <BlogPost {...props} navigate={navigate} auth={auth}  />;
};

export default BlogPostWithNavigation;

import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { printMHCP } from './PrintMHCP';

class MHOption extends Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().slice(0, 10); // Format the date as YYYY-MM-DD
    this.state = {
      dateOfPlan: today,
      reviewDate: "",
      trimmedDataURL: null, // This will store the trimmed signature data
    };
  }

  calculateReviewDate = (months) => {
    const { dateOfPlan } = this.state;
    const planDate = new Date(dateOfPlan);
    planDate.setMonth(planDate.getMonth() + months);
    const reviewDate = planDate.toISOString().slice(0, 10); // Format the date as YYYY-MM-DD
    this.setState({ reviewDate });
  };

  handleDateChange = (event) => {
    this.setState({ dateOfPlan: event.target.value }, () => {
      this.calculateReviewDate(0); // Recalculate review date based on the new dateOfPlan
    });
  };

  sigPad = {}; // Reference for SignatureCanvas

  clearSignature = () => {
    this.sigPad.clear();
    this.setState({ trimmedDataURL: null }); // Clear the saved signature
  };

  saveSignature = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') });
  };

  handleSignatureEnd = () => {
    this.saveSignature(); // Automatically save the signature when the user stops drawing
  };

  sendEmail = (event) => {
    const formData = this.props.formData;
    const {dateOfPlan, reviewDate} = this.state;
    // You can now include the signature image data if needed
    const signatureImage = this.state.trimmedDataURL;

    // Use the signatureImage with the formData if necessary
    printMHCP(event, formData, dateOfPlan, reviewDate, signatureImage);
  };


  render() {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-auto">
          <h2 className="text-xl font-semibold m-10 text-center text-black">Mental Health Care Plan</h2>
          
          {/* Date of Plan */}
          <div className="flex flex-row items-center m-4">
            <label className="text-black mr-4">Date of Plan:</label>
            <input
              type="date"
              value={this.state.dateOfPlan}
              onChange={this.handleDateChange}
              className="p-2 border border-gray-300 rounded-md text-black"
            />
          </div>

          {/* Review Date */}
          <div className="flex items-center justify-between m-4">
            <label className="text-black mr-4">Review Date:</label>
            <input
              type="date"
              value={this.state.reviewDate}
              readOnly
              className="p-2 border border-gray-300 rounded-md text-black"
            />
            <div className="flex gap-2 ml-4">
              <div 
                onClick={() => this.calculateReviewDate(3)}
                className="cursor-pointer p-2 bg-slate-500 text-sm hover:bg-sky-600 text-white rounded-md transition duration-200"
              >
                3 months
              </div>
              <div 
                onClick={() => this.calculateReviewDate(6)}
                className="cursor-pointer p-2 bg-slate-500 text-sm hover:bg-sky-600 text-white rounded-md transition duration-200"
              >
                6 months
              </div>
              <div 
                onClick={() => this.calculateReviewDate(12)}
                className="cursor-pointer p-2 bg-slate-500 text-sm hover:bg-sky-600 text-white rounded-md transition duration-200"
              >
                12 months
              </div>
            </div>
          </div>

          {/* Signature Canvas */}
          <div className="mt-6">
            <p className='mt-4 ml-4 -mb-4 text-black'>Sign here</p>
            <div className='flex flex-row'>
              <SignatureCanvas
                ref={(ref) => { this.sigPad = ref; }}
                penColor="black"
                onEnd={this.handleSignatureEnd} // Automatically save on end
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas border border-gray-300 m-4' }}
              />     
              <button
                onClick={this.clearSignature}
                className="px-4 py-2 bg-slate-500 my-4 hover:bg-slate-600 text-white rounded-md transition duration-200"
              >
                Clear
              </button>
            </div>
    
          </div>

          <div className="flex justify-center mt-4 gap-4">
            <button
              onClick={this.props.onClose}
              className="px-4 py-2 bg-slate-500 hover:bg-slate-600 text-white rounded-md transition duration-200"
            >
              Close
            </button>
            <button
              onClick={this.sendEmail}
              className="px-4 py-2 bg-sky-600 hover:bg-sky-700 text-white rounded-md transition duration-200"
            >
              Print
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MHOption;

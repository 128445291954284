import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Planning from '../SharedComponents/Planning';
import axios from 'axios'

import Joyride from 'react-joyride';
const EndPoint = process.env.REACT_APP_API_ENDPOINT;

const walkThrough = [
    { target : '#main',
        content: 'This is a walkthrough how to create/update mental health care plan in less than 3 minutes.',
        placement: 'center',
        title: '3 Minutes Mental Health Care Plan',
        disableBeacon: 'true'
    },
    { target : '#microphone',
      content: 'Click here to start recording. Your browser will ask for permission to use microphone for the first time. Wait 3 seconds before start dictation/recording. Click stop when you have finished recording. Wait for 1-2 minutes for the AI to transcribe, fill up form, and create plan.',
      placement: 'bottom',
      title: 'Start recording/dictation',
      disableBeacon: 'true'
    },
    { target : '#tab',
      content : 'Click this tab to view between background history and action plan',
      placement: 'bottom',
      title: 'Viewing history and plan'
    },
    { target : '#checklist',
        content : 'This tab show which part of the form is still empty. Click this note to close it.',
        placement: 'top',
        title: 'Mental Health Care Plan Checklist'
    },
    { target : '#print',
      content : 'Click here, then select MHCP to print Mental Health Care Plan',
      placement : 'bottom',
      title: 'Printing Plan'
    },
    { target : '#microphone',
        content: 'To update the history and plan, just click record again.',
        placement: 'bottom',
        title: 'Update History and Plan',
        disableBeacon: 'true'
    },
]

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}


class MentalHealth extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');

        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.initializeFormData(props.patientData),
            adminData: this.props.adminData,
            isEditingMHCP: false,
            isEditingFormulation: false,
            isEditingSafety: false,
            isEditingRelapse: false,
            //active tab
            activeTab: 'background',
            // helper
            isHelper : true,
            // walktthrough
            run : false
        };

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    // componentDidMount() {
    //     const encryptedAdmin = localStorage.getItem('admin');
    //     if (encryptedAdmin) {
    //         const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
    //         const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //         const referrences = adminData.contacts;
    //         const dashboard = adminData.dashboard;
    //         this.setState({ referrences: referrences, dashboard: dashboard });
    //     }
    // }

    componentDidMount() {
        const fetchOnboardData = async () => {
          try {
            const onBoard = await axios.get(`${EndPoint}/user/onboard`, { withCredentials: true });
            if (onBoard.data.mhcp === true) {
                this.setState({run: false})
            } else {
                this.setState({run: true})
            }
          } catch (error) {
            console.error("Error fetching onboard data:", error.response?.data || error.message);
          }
        };
      
        fetchOnboardData();
    }

    finishOnboard = async (data) => {
        try {
          const { status } = data;
          if (status === 'ready') {
            this.setState({ run: false }); // Stop the tour when finished or skipped
          
          // Make the POST request to the onboarding endpoint
          await axios.post(
            `${EndPoint}/user/onboard`,
            { path: 'mhcp' },
            { withCredentials: true }
          );
      
          // Show a success toast notification
          toast.success("Mental Health Care Plan walkthrough complete!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
      
        
        } catch (error) {
          console.error("Error updating onboarding:", error.response?.data || error.message);
          toast.error("Failed to update onboarding. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
    };
      
    

    initializeFormData = (patientData) => {

        const mhcp = {
            presentingIssue: "",
            history: "",
            pastHistory: "",
            childhoodHistory: "",
            familyHistory: "",
            parentChildNeed : "",
    
            occupation: "",
            social : "",
            substance: "",
            currentMedication: "",
            allergies: "",
    
    
            treatmentHistory: "",
            medicationHistory: "",
    
            relevantExamination : "",
            relevantInvestigation: "",
            devTesting: "",
    
            mentalStateExamination: {},
            risk: "",
            tools: "",
            diagnosis: "",
            date: new Date()
        }

        const mhformulation = {
            predisposing: "",
            precipitating: "",
            perpetuating:"",
            protective: "",
            date: new Date()  
        }
        const mhsafety= {
            reason: "",
            cando: "",
            help: "",
            call: "",
            talk: "",
            professional: "",
            date: new Date()
        }
    
        const relapsePrevention = {
            triggers: "",
            warningSigns: "",
            copingStrategy :"",
            support: "",
            emergency: "",
            date: new Date()
        }

        // Ensure healthassessment has all the necessary fields
        return {
            ...patientData,
            mhcp: {
                ...mhcp,
                ...patientData.mhcp,
            },
            mhformulation : {
                ...mhformulation,
                ...patientData.mhformulation
            },
            mhsafety : {
                ...mhsafety,
                ...patientData.mhsafety
            },
            relapsePrevention:{
                ...relapsePrevention,
                ...patientData.relapsePrevention
            },
            planning: patientData.planning || [], 
            reviews: patientData.reviews || []
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({
                formData: this.initializeFormData(this.props.patientData),
            });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }
    }

    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    toggleEdit = (section) => {
        this.setState((prevState) => {
            // If we're switching from editing to saving, update the parent data
            if (prevState[section]) {
                this.updatePatientData(); // Call updatePatientData to send the updated formData to the parent
            }
    
            return {
                [section]: !prevState[section] // Toggle editing state
            };
        });
    };
    
    handleInputChange = (section, key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [section]: {
                    ...prevState.formData[section],
                    [key]: value,
                },
            },
        }));
    };
    
    ////// UPDATE FROM CHILD COMPONENT 

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: updatedProblems,
            },
        }), this.updatePatientData);
    };

    // Render Form
    renderBackground() {
        const { isEditingMHCP, isEditingFormulation, isEditingSafety, isEditingRelapse, formData } = this.state;

        // // Add checks for formData and its sub-properties
        // if (!formData || !formData.mhcp || !formData.mhformulation || !formData.mhsafety || !formData.relapsePrevention) {
        //     return <div>Select File, then open, to open patient file.</div>;  
        // }
    
        const { mhcp, mhformulation, mhsafety, relapsePrevention } = formData;


        // setting up Fields and labels for Forms
        const profileFields = [
            { label: 'Presenting Issue', key: 'presentingIssue' },
            { label: 'History', key: 'history' },
            { label: 'Past History', key: 'pastHistory' },
            { label: 'Childhood History', key: 'childhoodHistory' },
            { label: 'Family History', key: 'familyHistory' },
            { label: "Parent and Children Needs", key: "parentChildNeed"},

            { label: "Employment History", key: "occupation"},
            { label: "Social History", key: "social"},
            { label: "Substance Use History", key: "substance"},
            { label: "Current Medication", key: "currentMedication"},
            { label: "Allergies", key: "allergies"},

            { label: "Relevant Physical Examination", key: "relevantExamination" },
            { label: "Relevant Investigations", key: "relevantInvestigation"},
            { label: "Previous psychological and Development Testing", key: "devTesting"},

            { label: 'Treatment History', key: 'treatmentHistory' },
            { label: 'Medication History', key: 'medicationHistory' },
       
            { label: 'Mental State Examination', key: 'mentalStateExamination' },
            { label: 'Risk and Comorbidities', key: 'risk' },
            { label: 'Diagnosis', key: 'diagnosis' },
            { label: 'Date', key: 'date' }
        ];

        const formulationFields = [
            { label: 'Predisposing factor', key: 'predisposing' },
            { label: 'Precipitating factor', key: 'precipitating' },
            { label: 'Perpetuating factor', key: 'perpetuating' },
            { label: 'Protective factor', key: 'protective' },
            { label: 'Date', key: 'date' }
        ];

        const safetyFields = [
            { label: 'Reason to Live', key: 'reason' },
            { label: 'What I can do to keep me safe', key: 'cando' },
            { label: 'Where can I seek help', key: 'help' },
            { label: 'Who can I call', key: 'call' },
            { label: 'Who can I talk to', key: 'talk' },
            { label: 'What professional help can I reach', key: 'professional' },
            { label: 'Date', key: 'date' }
        ];

        const preventionFields = [
            { label: "Possible Triggers", key: "triggers"},
            { label: "Warning Signs", key: "warningSigns"},
            { label: "Coping Strategies", key: "copingStrategy"},
            { label: "Support System", key: "support"},
            { label: "Emergency Plan", key: "emergency"},
            { label: 'Date', key: 'date' },
         
        ]

        return (
   
                <div className='grid grid-cols-6 xs:grid-cols-2 sm:grid-cols-4 relative'>


                {/* Helper */}
                {this.state.isHelper && (
                <div id='checklist' className='fixed -mt-60 right-4 w-[400px] h-auto bg-yellow-100 shadow rounded-md p-4' onClick={()=> this.setState({isHelper:false})}>
                    <p className='text-lg font-semibold text-red-700 text-center'>No Entry yet</p>
                    {profileFields.map((field) => {
                        if (mhcp[field.key] === "") {
                            return <div key={field.key} className='p-1'>- {field.label}</div>;
                        }
                    })}
                    {formulationFields.map((field) => {
                            if (mhformulation[field.key] === "") {
                                return <div key={field.key} className='p-1'>- {field.label}</div>;
                        }
                    })}
                    {safetyFields.map((field) => {
                            if (mhsafety[field.key] === "") {
                                return <div key={field.key} className='p-1'>- {field.label}</div>;
                        }
                    })}
                    {preventionFields.map((field) => {
                            if (relapsePrevention[field.key] === "") {
                                return <div key={field.key} className='p-1'>- {field.label}</div>;
                        }
                    })}
                </div>
                )}
                
                
                {/* Mental Health Care Plan */}
                <div className='col-span-4 row-span-3 p-4 bg-slate-100 rounded shadow-md m-2 xs:col-span-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-xl font-bold text-center'>History</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingMHCP')}
                            className={`px-4 py-2 rounded text-white ${isEditingMHCP ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingMHCP ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {profileFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhcp[field.key]}
                                        readOnly={!isEditingMHCP}
                                        onChange={(e) => this.handleInputChange('mhcp', field.key, e.target.value)}
                                        disabled={!isEditingMHCP}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            typeof mhcp[field.key] === 'object' && mhcp[field.key] !== null
                                                ? Array.isArray(mhcp[field.key])
                                                    ? mhcp[field.key].join('\n')
                                                    : Object.entries(mhcp[field.key]).map(([key, value]) => `${key}: ${value}`).join('\n')
                                                : mhcp[field.key]
                                        }
                                        readOnly={!isEditingMHCP}
                                        onChange={(e) => this.handleInputChange('mhcp', field.key, e.target.value)}
                                        disabled={!isEditingMHCP}
                                    />
                                
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Formulation */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Formulation</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingFormulation')}
                            className={`px-4 py-2 rounded text-white ${isEditingFormulation ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingFormulation ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {formulationFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhformulation[field.key]}
                                        readOnly={!isEditingFormulation}
                                        onChange={(e) => this.handleInputChange('mhformulation', field.key, e.target.value)}
                                        disabled={!isEditingFormulation}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(mhformulation[field.key])
                                                ? mhformulation[field.key].join('\n')
                                                : mhformulation[field.key]
                                        }
                                        readOnly={!isEditingFormulation}
                                        onChange={(e) => this.handleInputChange('mhformulation', field.key, e.target.value)}
                                        disabled={!isEditingFormulation}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Safety Plan */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Safety Plan</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingSafety')}
                            className={`px-4 py-2 rounded text-white ${isEditingSafety ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingSafety ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4  xs:grid-cols-1'>
                        {safetyFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhsafety[field.key]}
                                        readOnly={!isEditingSafety}
                                        onChange={(e) => this.handleInputChange('mhsafety', field.key, e.target.value)}
                                        disabled={!isEditingSafety}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(mhsafety[field.key])
                                                ? mhsafety[field.key].join('\n')
                                                : mhsafety[field.key]
                                        }
                                        readOnly={!isEditingSafety}
                                        onChange={(e) => this.handleInputChange('mhsafety', field.key, e.target.value)}
                                        disabled={!isEditingSafety}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Relapse Prevention Plan */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Relapse Prevention Plan</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingRelapse')}
                            className={`px-4 py-2 rounded text-white ${isEditingRelapse ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingRelapse ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {preventionFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={relapsePrevention[field.key]}
                                        readOnly={!isEditingRelapse}
                                        onChange={(e) => this.handleInputChange('relapsePrevention', field.key, e.target.value)}
                                        disabled={!isEditingRelapse}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(relapsePrevention[field.key])
                                                ? relapsePrevention[field.key].join('\n')
                                                : relapsePrevention[field.key]
                                        }
                                        readOnly={!isEditingRelapse}
                                        onChange={(e) => this.handleInputChange('relapsePrevention', field.key, e.target.value)}
                                        disabled={!isEditingRelapse}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                </div>
        );

    }

    render() {
        const { formData, activeTab } = this.state; 
        const { run } = this.state;
        var adminData = this.state.adminData;
        var referrences = adminData.referrences
       
        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
             
                <div id='main' className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Mental Health Care Plan</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                    <div id='tab' className='bg-slate-100 rounded w-92 text-center'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'background' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('background')}
                    >
                        Background
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
          
                    </div>
                </div>
                <div className='tabContent col-span-6'>
                    {activeTab === 'background' &&  this.renderBackground()}
                    {activeTab === 'planning' && formData.planning && (
                    <Planning id='tab' problems={formData.planning} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription} category="Mental Health" />
                    )}
       
                
                </div>
                <ToastContainer />
          
                <Joyride 
                    steps={walkThrough} 
                    continuous
                    disableScrolling
                    showProgress
                    run={run}
                    callback={this.finishOnboard}
                    styles={{
                    options: {
                        arrowColor: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(255, 255, 255)',
                        overlayColor: 'rgba(18, 32, 61, 0.4)',
                        primaryColor: 'rgba(167, 65, 65, 0.86)',
                        textColor: 'rgba(0, 0, 0, 0.86)',
                        hideCloseButton: 'false',
                        width: 500,
                        zIndex: 1000,
                    },
                    }}
                />
                
             
            </div>
        );     
        
    }
    
}

export default MentalHealth;

import React, { useState, useMemo, useCallback, useEffect } from "react";
import AWS from "aws-sdk";
import { useNavigate, useParams } from "react-router-dom";
import {
  Slate,
  Editable,
  withReact,
  useSlate,
} from "slate-react";
import {
  BoldIcon, 
  ItalicIcon, 
  CodeBracketIcon, 
  UnderlineIcon,
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  H1Icon,
  H2Icon,
  H3Icon,
  ListBulletIcon,
  NumberedListIcon,
  ChatBubbleLeftEllipsisIcon
} from "@heroicons/react/24/outline";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import axios from "axios";
import { Button, Icon, Toolbar } from "./icons";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];


const EndPoint = process.env.REACT_APP_API_ENDPOINT;

// Configure AWS S3
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Add to .env
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Add to .env
  region: process.env.REACT_APP_AWS_REGION, // Example: "us-east-1"
});

const s3 = new AWS.S3();
const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;


const EditPost = () => {
  const navigate = useNavigate();

  const defaultContent = [
    {
      type: "paragraph",
      children: [{ text: "Start editing your content here..." }],
    },
  ];

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [editorValue, setEditorValue] = useState([]);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    axios
      .get(`${EndPoint}/blog/${id}`)
      .then((response) => {
        const { title, summary, content, imageUrl } = response.data;
        setTitle(title);
        setSummary(summary);
        const parsedContent = JSON.parse(content);
        setEditorValue(parsedContent.length > 0 ? parsedContent : defaultContent);
        setImagePreview(`${EndPoint}${imageUrl}`);
      })
      .catch(() => {
        setEditorValue(defaultContent);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const content = JSON.stringify(editorValue);
    if (!title || !summary || !content) {
      console.error("All fields are required.");
      return;
    }
  
    let uploadedImageUrl = imagePreview; // Use the existing preview if no new image
    try {
      // Fetch the current blog data to get the previous image URL
      const response = await axios.get(`${EndPoint}/blog/${id}`);
      const previousImageUrl = response.data.imageUrl;
  
      // If a new image is selected, upload it and delete the old image
      if (image) {
        // Upload the new image to S3
        uploadedImageUrl = await uploadToS3(image);
  
        // Delete the previous image from S3 if it exists
        if (previousImageUrl) {
          const previousKey = previousImageUrl.split("/").pop(); // Extract the file key
          await deleteFromS3(previousKey);
        }
      }
    } catch (error) {
      console.error("Error updating image:", error);
      return;
    }
  
    const blogData = {
      title,
      summary,
      content,
      imageUrl: uploadedImageUrl, // Use the new image URL
    };
  
    try {
      await axios.put(`${EndPoint}/blog/${id}`, blogData, {
        withCredentials: true,
      });
      navigate("/blog-list");
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  const deleteFromS3 = async (key) => {
    const params = {
      Bucket: bucketName,
      Key: `uploads/${key}`, // Key of the object to delete
    };
  
    try {
      await s3.deleteObject(params).promise();
      console.log("Image deleted successfully:", key);
    } catch (err) {
      console.error("S3 Delete Error:", err);
    }
  };
  
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Save file to state
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: bucketName,
      Key: `uploads/${Date.now()}_${file.name}`, // Unique key for the file
      Body: file,
      ContentType: file.type,
    };
  
    try {
      const { Location } = await s3.upload(params).promise();
      return Location; // S3 URL of the uploaded file
    } catch (err) {
      console.error("S3 Upload Error:", err);
      throw err;
    }
  };

  const renderSlate = () => {
    return (
      <Slate
      editor={editor}
      initialValue={editorValue}
      onChange={(value) => setEditorValue(value)}
    >
      <Toolbar>
        <MarkButton format="bold" IconComponent={BoldIcon} />
        <MarkButton format="italic" IconComponent={ItalicIcon} />
        <MarkButton format="underline" IconComponent={UnderlineIcon} />
        <MarkButton format="code" IconComponent={CodeBracketIcon} />
        <BlockButton format="heading-one" IconComponent={H1Icon} />
        <BlockButton format="heading-two" IconComponent={H2Icon} />
        <BlockButton format="heading-three" IconComponent={H3Icon} />
        <BlockButton format="block-quote" IconComponent={ChatBubbleLeftEllipsisIcon} />
        <BlockButton format="numbered-list" IconComponent={NumberedListIcon} />
        <BlockButton format="bulleted-list" IconComponent={ListBulletIcon} />
        <BlockButton format="left" IconComponent={Bars3BottomLeftIcon} />
        <BlockButton format="center" IconComponent={Bars3Icon} />
        <BlockButton format="right" IconComponent={Bars3BottomRightIcon} />
      </Toolbar>
      <Editable
        className="h-[1200px] overflow-y-auto border border-slate-200 p-4 rounded-md"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Edit your blog content here..."
        spellCheck

        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
    )
  }


  return (
    <div className="w-full mx-auto p-10">
      <h1 className="text-3xl font-bold mb-6">Edit Blog</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 font-medium mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Summary</label>
          <textarea
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md resize-none focus:border-sky-500"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Content</label>
          {editorValue.length > 0 ? (
          <>{renderSlate()}</>
          ):(
            <div className="text-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-sky-500  mx-auto"></div>
              <p>Loading content...</p>
            </div>
        )}
        </div>

        <div>

          <label className="block text-gray-700 font-medium mb-2">
            Upload Image
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={handleImageChange}
            className="hidden"
          />
          <label
            htmlFor="fileInput"
            className="inline-block m-2 p-2 bg-sky-700 hover:bg-sky-800 text-white rounded shadow cursor-pointer"
          >
            Choose File
          </label>
          {imagePreview && (
            <div className="w-32 h-32 border border-gray-300 rounded-md overflow-hidden">
              <img
                src={imagePreview}
                alt="Thumbnail Preview"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-sky-700 hover:bg-sky-800 text-white px-4 py-2 rounded-md"
        >
          Update Blog
        </button>
      </form>
    </div>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isAlign = TEXT_ALIGN_TYPES.includes(format);

  if (isAlign) {
    Transforms.setNodes(
      editor,
      { align: isActive ? undefined : format }, // Toggle alignment
      { match: (n) => SlateElement.isElement(n), split: true }
    );
    return;
  }

  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n.type === format || n.align === format),
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const alignment = element.align || "left";

  switch (element.type) {
    case "block-quote":
      return <blockquote className="border-l-4 border-sky-600 p-4 italic text-sky-700 text-lg font-semibold" {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul className="list-disc pl-8" {...attributes}>{children}</ul>;
    case "numbered-list":
      return <ol className="list-decimal pl-8" {...attributes}>{children}</ol>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "paragraph":
      return (
        <p
          className={`text-${alignment}`}
          {...attributes}
        >
          {children}
        </p>
      );
    case "heading-one":
      return <h1 className="text-3xl font-bold" {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 className="text-2xl font-bold" {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 className="text-xl font-bold" {...attributes}>{children}</h3>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.code) children = <code>{children}</code>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, IconComponent }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>
        <IconComponent className="w-5 h-5 mx-2" />
      </Icon>
    </Button>
  );
};

const MarkButton = ({ format, IconComponent }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>
        <IconComponent className="w-5 h-5" />
      </Icon>
    </Button>
  );
};

export default EditPost;

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import History from '../PlanComponents/History';

import Joyride from 'react-joyride';
import axios from 'axios';
const EndPoint = process.env.REACT_APP_API_ENDPOINT;


// helper functions
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const walkThrough = [
    { target : '#main',
        content: 'This is a walkthrough how to create/update summary.',
        placement: 'center',
        title: 'Health Summary',
        disableBeacon: 'true'
    },
    { target : '#smartPaste',
        content : 'Click here to paste any text data such as discharge summary, letters, result, etc. AI will read, process, then put it in the patient data.',
        placement : 'bottom',
        title: 'Smart Paste'
    }
]


// sample data
const history = {
    allergies: [{ id: generateId(), substance: "Bee sting", severity: "Moderate" }],
    medications: [
        { id: generateId(), name: "Atorvastatin", dose: 40, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Darolutamide", dose: 300, unit: "mg", form: "Tablet", frequency: "1 Tablet Twice a day" },
        { id: generateId(), name: "Metformin", dose: 1, unit: "g", form: "Tablet", frequency: "1 Tablet Twice a day" },
        { id: generateId(), name: "Olanzapine", dose: 10, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Olanzapine", dose: 5, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Sitagliptin", dose: 100, unit: "mg", form: "Tablet", frequency: "1 Tablet In the morning" },
        { id: generateId(), name: "Valproate Sodium", dose: 500, unit: "mg", form: "Enteric coated tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Xarelto", dose: 20, unit: "mg", form: "Tablet", frequency: "1 Tablet In the morning" }
    ],
    activeHistory: [
        { id: generateId(), date: "2014-12-26", condition: "Anxiety", details: "" },
        { id: generateId(), date: "2014-12-26", condition: "Bipolar affective disorder", details: "" },
        { id: generateId(), date: "2017-08-21", condition: "Type 2 Diabetes Mellitus", details: "" },
        { id: generateId(), date: "2018-02-01", condition: "Hepatic steatosis", details: "" },
        { id: generateId(), date: "2020-03-01", condition: "Prostate carcinoma", details: "Adenocarcinoma of the prostate, T2c Gleason 3+4, post radical prostatectomy/PLND April 2020, post salvage prostate bed RT, completed Jul 2021, another redo salvage pelvic RT completed Jul 2022" },
        { id: generateId(), date: "2020-07-31", condition: "Bilateral Pulmonary embolism", details: "" },
        { id: generateId(), date: "2020-10-02", condition: "Dyslipidaemia", details: "" }
    ],
    pastHistory: [
        { id: generateId(), date: "", condition: "Tonsillitis", details: "" },
        { id: generateId(), date: "1975-01-01", condition: "MBA", details: "" },
        { id: generateId(), date: "1975-01-01", condition: "Fractured tibia & fibula", details: "Post MBA" },
        { id: generateId(), date: "2016-02-24", condition: "Depression", details: "" },
        { id: generateId(), date: "2017-08-21", condition: "Tympanic perforation", details: "" },
        { id: generateId(), date: "2020-04-29", condition: "Prostatectomy, Open", details: "" },
        { id: generateId(), date: "2020-07-31", condition: "Deep venous thrombosis (Right)", details: "Extensive above & below DVT" }
    ],
    immunisations: [
        { id: generateId(), date: "1966-01-01", vaccine: "Hepatitis B (Hepatitis B)" },
        { id: generateId(), date: "2019-05-01", vaccine: "FluQuadri (Influenza)" },
        { id: generateId(), date: "2021-06-28", vaccine: "AstraZeneca VAXZEVRIA (COVID-19)" },
        { id: generateId(), date: "2021-09-20", vaccine: "AstraZeneca VAXZEVRIA (COVID-19)" },
        { id: generateId(), date: "2022-03-03", vaccine: "Pfizer Comirnaty (COVID-19)" },
        { id: generateId(), date: "2022-04-19", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2022-07-13", vaccine: "Pfizer Comirnaty (COVID-19)" },
        { id: generateId(), date: "2023-10-11", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2024-04-26", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2024-08-07", vaccine: "Shingrix (Herpes Zoster)" }
    ],
    smoking: { id: generateId(), status: "Former", notes: "Quit in 2010 after 20 years of smoking." },
    alcohol: { id: generateId(), status: "Occasional", notes: "Drinks socially on weekends." },
    substance: { id: generateId(), status: "None", notes: "No history of substance abuse." },
    familyHistory: [
        { id: generateId(), relation: "Father", condition: "Hypertension", notes: "Diagnosed at age 50." },
        { id: generateId(), relation: "Mother", condition: "Type 2 Diabetes Mellitus", notes: "Diagnosed at age 60." }
    ],
    emergency: [
        { id: generateId(), name: "John Doe", relationship: "Spouse", phone: "+123456789" },
        { id: generateId(), name: "Jane Smith", relationship: "Daughter", phone: "+987654321" }
    ],
    carer: [
        { id: generateId(), name: "John Doe", relationship: "Spouse", phone: "+123456789" },
        { id: generateId(), name: "Jane Smith", relationship: "Daughter", phone: "+987654321" }
    ],
    cultural : { id: generateId(), language: "English", religion: "Christian", interpreter: "No", birthCountry: "Australia", otherCommunication: "None", otherCultural: "" },
    eduEmploy : { id: generateId(), educationLevel: "Primary degree", employment : "Not working"}
};
  


class General extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            isEditing: false,
            // onboard
            run: false
        };

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    componentDidMount() {
        const fetchOnboardData = async () => {
          try {
            const onBoard = await axios.get(`${EndPoint}/user/onboard`, { withCredentials: true });
            if (onBoard.data.summary === true) {
                this.setState({run: false})
            } else {
                this.setState({run: true})
            }
          } catch (error) {
            console.error("Error fetching onboard data:", error.response?.data || error.message);
          }
        };
      
        fetchOnboardData();
    }
    

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        // Check if formData has changed and if it's not empty, then save to localStorage
        if (prevState.formData !== this.state.formData && Object.keys(this.state.formData).length !== 0) {
            this.updatePatientData();
        }
    }
    
    // finish onboarding function
    finishOnboard = async (data) => {
        try {
            const { status } = data;
            if (status === 'ready') {
            this.setState({ run: false }); // Stop the tour when finished or skipped
            
            // Make the POST request to the onboarding endpoint
            await axios.post(
            `${EndPoint}/user/onboard`,
            { path: 'summary' },
            { withCredentials: true }
            );
        
            // Show a success toast notification
            toast.success("Summary walkthrough complete!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            });
        }
        
        
        } catch (error) {
            console.error("Error updating onboarding:", error.response?.data || error.message);
            toast.error("Failed to update onboarding. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            });
        }
    };


    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };


    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => {
            const isEditing = !prevState.isEditing;
            
            // Save to local storage when toggling from edit mode to view mode
            if (!isEditing) {
                this.updatePatientData();
            }
            
            return { isEditing };
        });
    };
    
    updateFormData = (key, updatedData) => {
      
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [key]: updatedData,
          },
        }), this.saveToLocalStorage);
    };
    

    render() {
    
        const { formData } = this.state;
        const { run } = this.state;

        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(320px, auto)' }}>
                <div  id='main' className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Summary</div>
                <History
                    history={formData.history}
                    updateHistory={(updatedHistory) => this.updateFormData('history', updatedHistory)}
                />
                <ToastContainer />
                <Joyride 
                    steps={walkThrough} 
                    continuous
                    disableScrolling
                    showProgress
                    run={run}
                    callback={this.finishOnboard}
                    styles={{
                    options: {
                        arrowColor: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(255, 255, 255)',
                        overlayColor: 'rgba(18, 32, 61, 0.4)',
                        primaryColor: 'rgba(167, 65, 65, 0.86)',
                        textColor: 'rgba(0, 0, 0, 0.86)',
                        hideCloseButton: 'false',
                        width: 500,
                        zIndex: 1000,
                    },
                    }}
                />
            </div>
        );
    }

}


export default General;
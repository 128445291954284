import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utilities/AuthContext';
import { useSharedFunction} from '../utilities/SharedFunctionContext';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;
const Member_Endpoint = process.env.REACT_APP_CLIENT_ENDPOINT;


const Navigation = () => {
    const navigate = useNavigate();
    const [auth, setAuth] = useAuth();
    const hasCheckedSession = useRef(false); // Ref to track if the session has been checked
    const [isOpen, setIsOpen] = useState(false);
    const { data } = useSharedFunction(); // Access data from SharedFunctionContext

    // Function to check the session and update the state
    const checkSession = async () => {
        if (hasCheckedSession.current) return;

        try {
            const response = await axios.get(`${EndPoint}/session`, { withCredentials: true });
            setAuth({ ...auth, isLoggedIn: response.data.isLoggedIn });
        } catch (error) {
            console.error('Error checking session:', error);
            setAuth({ ...auth, isLoggedIn: false });
            navigate('/');
        }

        hasCheckedSession.current = true;
    };

    // Function to handle logout
    const handleLogout = async () => {
        try {
            await axios.post(`${EndPoint}/user/logout`, {}, { withCredentials: true });
            toast.success("You are logged out!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            setAuth({ ...auth, isLoggedIn: false });
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    // Effect to reset the session check flag when auth.isLoggedIn changes
    useEffect(() => {
        hasCheckedSession.current = false;
    }, [auth.isLoggedIn]);

    // Check session on component mount
    useEffect(() => {
        checkSession();
    }, []); // Empty dependency array to run only once on mount

    useEffect(() => {
        if (data && auth.isLoggedIn) {
            setAuth((prevAuth) => ({
                ...prevAuth,
                credits: data, // Update credits from SharedFunctionContext's data
            }));
        }
    }, [data, auth.isLoggedIn, setAuth]);
    


    return (
        <nav className="sticky top-0 bg-slate-800 flex justify-between items-center z-50">
            <h1 className="text-white text-[30px] ml-4 font-semibold xs:cdhidden">Caredevo</h1>
            <ToastContainer />  

            <div className="hidden lg:flex xl:flex 2xl:flex"> {/* Render the original version for large screens */}
                {auth.isLoggedIn ? (
                    <div>
                        <button
                            // onClick={() => navigate('/pricing')}
                            className={`text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline rounded ${
                                auth.credits < 10
                                    ? "bg-red-500 hover:bg-red-600"
                                    : auth.credits < 30
                                    ? "bg-yellow-600 hover:bg-yellow-600"
                                    : "bg-sky-600 hover:bg-slate-700"
                            }`}
                        >
                            <span id='creditLeft' className='font-semibold text-lg'>{auth.credits}</span> tokens
                        </button>
                        <button
                            onClick={() => navigate('/')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Home
                        </button>
                        <button
                            onClick={() => navigate('/master')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Care Plan
                        </button>
                        <button
                            onClick={() => navigate('/usage')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Account
                        </button>
                        {auth && auth.role && auth.role.includes('admin') && (
                            <button
                               onClick={() => navigate('/log')}
                               className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                            >
                               Logs 
                           </button>
                        )}
                          {auth && auth.role && auth.role.includes('admin') && (
                            <button
                               onClick={() => navigate('/project')}
                               className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                            >
                               Project
                           </button>
                        )}
                        {auth && auth.role && auth.role.includes('admin') && (
                            <button
                               onClick={() => navigate('/localai')}
                               className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                            >
                               LocalAI
                           </button>
                        )}
                        {auth && auth.role && auth.role.includes('admin') && (
                            <button
                               onClick={() => navigate('/analytics')}
                               className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                            >
                               A/B Testing
                           </button>
                        )}
                        <button
                            onClick={() => navigate('/blog-list')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Blog
                        </button>
                        <button
                            onClick={() => navigate('/education')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Education
                        </button>
                        <button
                            onClick={() => navigate('/community')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Discussion
                        </button>
                        <button
                            onClick={() => navigate('/tools')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Tools
                        </button>
                        <button
                            onClick={handleLogout}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                        >
                            Log out 
                        </button>
                    </div>
                ) : (
                    <>
                        <button
                            onClick={() => navigate('/')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Home
                        </button>
                        <button
                             onClick={() => navigate('/blog-list')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Blog
                        </button>
                        <button
                             onClick={() => navigate('/education')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                        >
                            Education
                        </button>
                        <button
                            onClick={() => navigate('/login')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                        >
                            Login
                        </button>
                        <button
                            onClick={() => navigate('/register')}
                            className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                        >
                            Register
                        </button>
                    </>
                )}
            </div>

            {/* Dropdown version for small and extra-small screens */}
            <div className="lg:hidden xl:hidden 2xl:hidden">
                {/* Implement the dropdown version here */}
                <button 
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                >
                    Menu
                </button>

                {isOpen && (
                    <div className="absolute top-14 right-0 bg-gray-900 p-4 z-10 shadow-lg w-[200px]">
                        {auth.isLoggedIn ? (
                            <>
                                <button
                                    // onClick={() => navigate('/pricing')}
                                    className={`block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline rounded ${
                                        auth.credits < 10
                                            ? "bg-red-500 hover:bg-red-600"
                                            : auth.credits < 30
                                            ? "bg-yellow-600 hover:bg-yellow-600"
                                            : "bg-sky-600 hover:bg-slate-700"
                                    }`}
                                >
                                    <span id="creditLeft" className="font-semibold text-lg">{auth.credits}</span> tokens
                                </button>
                                <button
                                    onClick={() => navigate('/')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Home
                                </button>
                                <button
                                    onClick={() => navigate('/blog-list')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Blog
                                </button>
                                <button
                                    onClick={() => navigate('/education')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Education
                                </button>
                                <button
                                    onClick={() => navigate('/tools')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Tools
                                </button>
                
                                <button
                                    onClick={() => navigate('/master')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Care Plan
                                </button>

                                <button
                                    onClick={() => navigate('/usage')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Account
                                </button>
                                {auth.role.includes('admin') && (
                                    <button
                                    onClick={() => navigate('/log')}
                                       className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                    >
                                    Logs 
                                </button>
                                )}
                   
                                <button
                                    onClick={handleLogout}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Log out
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() => navigate('/')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Home
                                </button>
                                <button
                                    onClick={() => navigate('/blog-list')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Blog
                                </button>
                                <button
                                    onClick={() => navigate('/education')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Education
                                </button>
                                <button
                                    onClick={() => navigate('/login')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Login
                                </button>
                                <button
                                    onClick={() => navigate('/register')}
                                    className="block text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded"
                                >
                                    Register
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navigation;

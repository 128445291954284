import React, { Component } from 'react';
import {AITextToObjectwithDataAndRef  } from '../../utilities/AI';

const systemMessage = `You are a medical practitioner.

You will receive three types of data:
1. 'input': A text containing medical notes.
2. 'initData': A JSON object containing a list of existing medical problems, each with fields for "problem" (title of the problem), "goal" (goal for the problem), "todo" (list of tasks for the problem), "review" (notes of the problem) and "journey" (journey of the patient for this problem).
3. 'referrences : A JSON object containing a list of referrences for medical problems, each with fields for "problem" (title of the problem), "goal" (goal for the problem), "todo" (list of tasks for the problem).
Your task is to:
1. Read through the notes provided in 'input'.
2. Identify and create a list of medical problems based on the content in 'input'.
3. Compare each identified problem in 'input' with the problems listed in 'initData'.
4. If an identified problem does not already exist in 'initData', find if the problem is exist in the 'referrences'
   Add a new problem entry with the following fields:
   - "problem": the title of the problem same as in the referrences. If not found, write a new title. 
   - "goal": list of goal as per list of goal in referrences, and add another goal if mentioned with this format: [{id: null, description: "goal for the problem"}].
   - "plan": list of todo as per referrences, also add another todo only if mentioned with this format : [{id: null, entry: "what need to be done", status: "plan"]].
   - "review" : [{id: null, date : today's date, notes: "note about the problem"}], only if mentioned
   - "journey" : [{id: null, date: date as mentioned or today's date with this format: yyyy-mm-dd, , journey: 'note about the journey', type: 'either diagnosis, pathology, imaging, medication, procedure, expertise'}]
   - "priority" : "Low"
   - "collapsed": false 
5. If an identified problem already exists in 'initData', update as follows:
   - Update the "goal" field if there is additional information about the problem in 'input'.
   - Add any new tasks to the "plan" list if additional tasks are mentioned in 'input'.
   - If there's macthing plan already, check if the task mentioned is ongoing or done. If ongoing set the "status" to "doing". If already done, change "status" to "done"
   - Add any new review notes to the "review" list if additional notes are mentioned in 'input'.
   - Add any new journey to the "journey" list if additional journey are mentioned in 'input'
Return the updated 'initData' as a JSON array of objects.
Do not add any comments or explanations; only output the JSON array of objects.

`

const initInput = `Patient fasting blood sugar is 8.0. So he has Diabetes type-2 now. He needs to start on metformin 500mg/tab once daily. 
Monitor HbA1C in 3 months time.
Also advised to check his feet with podiatrist every 6 months, eye check every year. 
Advised referral to Dietitian as well.`

const initOutput = `{
  "problem": "Type 2 diabetes mellitus",
  "goals": ["HbA1C less than 7%"],
  "todo": [
  {title : "Start Metformin 500mg/tab OD", status: "doing"},
  {title : "Annual eye examination", status: "plan"}
  ]
  "journey" : [{
  "date" : "2023-11-30",
  "type" : "pathology",
  "entry" : "HbA1C 8%"
  }]
}`

const referrences = `const medicalProblems = [
  {
    problem: "Diabetes Type-2",
    goals: [
      "Achieve and maintain optimal blood glucose levels",
      "Prevent complications related to diabetes",
      "Promote a healthy lifestyle through diet and exercise"
    ],
    todo: [
      "Monitor blood glucose levels daily",
      "Schedule regular HbA1c testing every 3 months",
      "Start or adjust medication as needed (e.g., metformin)",
      "Educate patient on diet and exercise modifications",
      "Arrange follow-up consultations every 6 months"
    ]
  },
  {
    problem: "Heart Failure",
    goals: [
      "Improve cardiac function and reduce symptoms",
      "Maintain optimal fluid balance",
      "Prevent hospital readmissions"
    ],
    todo: [
      "Initiate or adjust medication (e.g., beta-blockers, ACE inhibitors)",
      "Advise on daily weight monitoring",
      "Restrict sodium intake to manage fluid retention",
      "Schedule a follow-up echocardiogram in 6-12 months",
      "Educate patient on recognizing symptoms of worsening heart failure"
    ]
  },
  {
    problem: "Coeliac Disease",
    goals: [
      "Achieve and maintain a gluten-free diet",
      "Promote healing of the intestinal lining",
      "Prevent nutritional deficiencies"
    ],
    todo: [
      "Provide education on gluten-free foods and cross-contamination",
      "Refer to a dietitian for nutritional counseling",
      "Monitor for common deficiencies (e.g., iron, calcium, vitamin D)",
      "Conduct follow-up blood tests for antibodies every 6-12 months",
      "Encourage regular screenings for related autoimmune conditions"
    ]
  },
  {
    problem: "Hyperthyroidism",
    goals: [
      "Normalize thyroid hormone levels",
      "Relieve symptoms associated with hyperthyroidism",
      "Prevent complications such as heart issues and osteoporosis"
    ],
    todo: [
      "Initiate or adjust antithyroid medication as needed",
      "Monitor thyroid hormone levels every 3-6 months",
      "Educate patient on symptoms of thyroid storm and when to seek help",
      "Consider options for definitive treatment (e.g., radioiodine therapy or surgery)",
      "Schedule bone density scans if at risk for osteoporosis"
    ]
  },
  {
    problem: "Eczema",
    goals: [
      "Reduce the frequency and severity of flare-ups",
      "Maintain skin hydration and integrity",
      "Minimize itching and discomfort"
    ],
    todo: [
      "Advise on regular use of emollients and moisturizers",
      "Prescribe topical corticosteroids for flare-ups",
      "Educate patient on triggers to avoid (e.g., certain soaps, allergens)",
      "Suggest antihistamines if itching is severe",
      "Schedule follow-up to assess treatment response and skin condition"
    ]
  }
];

`

class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruction: systemMessage,
      inputMessage: initInput,
      output: [],
      referrences : referrences
    };
  }

  handleChangeText1 = (e) => {
    this.setState({ instruction: e.target.value });
  };

  handleChangeText2 = (e) => {
    this.setState({ inputMessage: e.target.value });
  };

  handleSubmit = async () => {
    const { instruction, inputMessage, output } = this.state;

    try {
        const respond = await AITextToObjectwithDataAndRef(instruction, inputMessage, output, referrences);
        // Format JSON output with 2-space indentation for readability
        this.setState({ output: JSON.stringify(respond, null, 2) });
      } catch (error) {
        this.setState({ output: 'Error processing input' });
      }


  };

  render() {
    return (
      <div className="flex flex-row w-auto m-4 gap-4">
        <div className='flex-1'>
            <textarea
                className="border-slate-200 rounded h-[800px] w-full p-2 resize-none"
                placeholder="Instruction"
                value={this.state.instruction}
                onChange={this.handleChangeText1}
            />
        </div>


        <div className="flex-1 flex flex-col">
            <textarea
                className="border-slate-200 rounded h-[800px] w-full p-2  resize-none"
                placeholder="Input"
                value={this.state.inputMessage}
                onChange={this.handleChangeText2}
            />
            <button
                className="bg-sky-700 text-white px-4 py-2 m-2 shadow rounded"
                onClick={this.handleSubmit}
                >
                Submit
            </button>
        </div>

        <div className='flex-1'>
            <textarea
                className="border-slate-200 rounded h-[800px] w-full p-2 m resize-none"
                placeholder="Result"
                value={this.state.output}

            />
        </div>
      </div>
    );
  }
}

export default Explore;

import React, { Component } from "react";

const educationData = [
  { title: "PTSD", description: "General education about PTSD, Childhood trauma and treatments", meta: "PTSD" }
//   { title: "Education 2", description: "Description 2", meta: "Meta 2" },
//   { title: "Education 3", description: "Description 3", meta: "Meta 3" },
];

const panel = [
  {
    title: "PTSD",
    subtitle : "General education about PTSD, Childhood trauma and treatments",
    content: [
        {
            subtitle: "PTSD",
            description:
              "What is PTSD, what are they symptoms and how it's affect people's life.",
            url: "https://www.youtube.com/embed/5iocOm_ixLU?si=CUy4DS7CkbvDVwEt&rel=0",
            type: "video",
        },
        {
            subtitle: "EMDR",
            description:
            "EMDR (Eye Movement Desensitization and Reprocessing) is a psychotherapy approach that helps individuals process and reduce distress from traumatic memories by using guided eye movements or other forms of bilateral stimulation.",
            url: "https://www.youtube.com/embed/hKrfH43srg8?si=aId2SQuWxu5mGszJ&rel=0",
            type: "video",
        },
        {
            subtitle: "Childhood Trauma and The Brain",
            description:
            "Explanation about how childhood trauma can significantly alter brain development, impacting emotional regulation, memory, and stress responses, often leading to long-term psychological and physical health challenges.",
            url: "https://www.youtube.com/embed/xYBUY1kZpf8?si=SPxXas2TSA0w_cEc&rel=0",
            type: "video",
        },
        {
            subtitle: "Trauma Focused CBT",
            description:
              "What is Trauma Focused CBT",
            url: "https://www.youtube.com/embed/B0xUwMcMwwc?si=wsKVR92zLqA1K99B&rel=0",
            type: "video",
        },
        {
            subtitle: "Treatment of trauma memories",
            description:
              "Role play scenario about a counsellor talking to a young person with multiple trauma memories.",
            url: "https://www.youtube.com/embed/mn-Dm36WBs0?si=5M5h6ul8OM9su-al&rel=0",
            type: "video",
        },

    ],
  },
];

class Education extends Component {
  state = {
    active: null, // State to track the currently active panel
  };

  handleClick = (meta) => {
    const activeData = panel.find((item) => item.title === meta); // Find the matching panel by title
    this.setState({ active: activeData });
  };

  handleBack = () => {
    this.setState({ active: null }); // Reset to the initial list view
  };

  renderSection() {
    const { active } = this.state;

    if (!active) return null;

    return (
      <div className="">
        <h1 className="font-bold text-5xl text-center text-sky-800">{active.title}</h1>
        <h1 className="text-lg text-center text-slate-800 m-4">{active.subtitle}</h1>
        <div className="flex flex-wrap justify-center gap-8 m-10  xs:m-2 sm:m-2">
          {active.content.map((item, index) => (
            <div
              key={index}
              className="bg-white h-[520px] w-[560px] rounded shadow-lg p-4 relative"
            >
              <p className="text-2xl font-bold text-sky-800 text-center m-4">
                {item.subtitle}
              </p>
              <p className="m-4">{item.description}</p>
              <iframe
                width="560"
                height="315"
                className='-m-4 bottom-4 absolute rounded-b'
                src={item.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { active } = this.state;

    if (active) {
      // If an active panel is set, render it
      return (
        <div className="h-screen bg-gray-100">
          <button
            className="px-4 py-2 bg-sky-700 hover:bg-sky-800 text-white rounded shadow m-4"
            onClick={this.handleBack}
          >
            Back
          </button>
          {this.renderSection()}
        </div>
      );
    }

    // Otherwise, render the education list
    return (    
        <div className=''>
            <h1 className='text-center font-bold text-5xl text-sky-800 m-10'>Patient Education</h1>
            <div className="flex flex-wrap justify-center">
        
                {educationData.map((data, index) => (
                <div
                    key={index}
                    className="h-[200px] w-[300px] bg-gradient-to-r from-sky-600 to-slate-700 hover:bg-sky-700 m-4 flex flex-col justify-center items-center text-white cursor-pointer rounded shadow"
                    onClick={() => this.handleClick(data.meta)}
                >
                    <h3 className="text-3xl font-bold text-center">{data.title}</h3>
                    <p className="text text-center m-4">{data.description}</p>
                </div>
                ))}
            </div>
        </div>
    );
  }
}

export default Education;

import React from "react";
import ReactDOM from "react-dom";


export const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={`cursor-pointer ${
        reversed
          ? active
            ? "text-white"
            : "text-gray-400"
          : active
          ? "text-sky-700"
          : "text-gray-400"
      } ${className || ""}`}
    />
  )
);

export const EditorValue = React.forwardRef(
  ({ className, value, ...props }, ref) => {
    const textLines = value.document.nodes
      .map((node) => node.text)
      .toArray()
      .join("\n");

    return (
      <div
        ref={ref}
        {...props}
        className={`mt-8 mx-[-5rem] ${className || ""}`}
      >
        <div className="text-sm p-2 px-5 text-gray-800 border-t-2 border-gray-200 bg-gray-100">
          Slate's value as text
        </div>
        <div className="text-gray-800 font-mono text-xs whitespace-pre-wrap p-5">
          {textLines}
        </div>
      </div>
    );
  }
);

// export const Icon = React.forwardRef(({ className, ...props }, ref) => (
//   <span
//     {...props}
//     ref={ref}
//     className={`material-icons text-sm align-text-bottom ${className || ""}`}
//   />
// ));

export const Icon = ({ children }) => (
  <span className="inline-flex items-center">
    {children}
  </span>
);

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={`whitespace-pre-wrap mx-[-5rem] my-2 px-5 py-2 text-sm bg-[#f8f8e8] ${className || ""}`}
  />
));

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    data-test-id="menu"
    ref={ref}
    className={`inline-block space-x-4 ${className || ""}`}
  />
));

export const Portal = ({ children }) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={`relative p-1 px-4 pb-4 mb-5 border-b-2 border-gray-400 m-2 ${className || ""}`}
  />
));

import React from 'react';
import axios from 'axios';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class AnalyticsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weeklyReport: [],
      summary: null,
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const [weeklyReportResponse, summaryResponse] = await Promise.all([
        axios.get(`${EndPoint}/analytics/weekly-report`),
        axios.get(`${EndPoint}/analytics`),
      ]);

      this.setState({
        weeklyReport: weeklyReportResponse.data.metrics,
        summary: summaryResponse.data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: 'Failed to fetch analytics data. Please try again later.',
        loading: false,
      });
    }
  }

  renderWeeklyReport() {
    const { weeklyReport } = this.state;

    if (!Object.keys(weeklyReport).length) {
      return <p className="text-gray-500 text-center">No data available for this week.</p>;
    }

    return Object.entries(weeklyReport).map(([key, data], index) => (
      <div key={index} className="bg-white shadow-md rounded-lg p-4 mb-4">
        <h3 className="text-lg font-semibold text-gray-800">{key}</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-600">
            <span className="font-medium">Total Visits:</span> {data.totalVisits}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Unique Visits:</span> {data.uniqueVisits}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Conversions:</span> {data.conversions}
          </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Conversion Rate:</span> {data.conversionRate}%
          </p>
        </div>
      </div>
    ));
  }

  renderSummary() {
    const { summary } = this.state;

    if (!summary || !Object.keys(summary).length) {
      return <p className="text-gray-500 text-center">No summary data available.</p>;
    }

    return (
      <div className="bg-white shadow-md rounded-lg p-4">
        <h3 className="text-lg font-semibold text-gray-800">Analytic Summary</h3>
        <div className="mt-2 space-y-4">
          {Object.entries(summary).map(([key, data], index) => (
            <div key={index}>
              <h4 className="font-medium text-gray-700">{key}</h4>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Total Visits:</span> {data.totalVisits}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Unique Visits:</span> {data.uniqueVisits}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Conversions:</span> {data.conversions}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Conversion Rate:</span> {data.conversionRate}%
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { loading, error } = this.state;

    return (
      <div className="bg-gray-100 min-h-screen p-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl text-center font-bold text-gray-800 mb-4">Analytics Dashboard</h1>

          {loading && (
            <p className="text-gray-500 text-center">Loading analytics data...</p>
          )}

          {error && (
            <p className="text-red-500 text-center">{error}</p>
          )}

          {!loading && !error && (
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-800 m-4">
                  Summary by Variant
                </h2>
                {this.renderSummary()}
              </div>

              <div>
                <h2 className="text-xl font-semibold text-gray-800 m-4">
                  Weekly Report
                </h2>
                {this.renderWeeklyReport()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AnalyticsDashboard;


import axios from 'axios';

// using : whisper locally :  .\whisper-medium.en.llamafile --port 8010    or   .\whisper-tiny.en.llamafile --port 8010  .\whisper-tiny.en.llamafile -f raven_poe_64kb.mp3 -pc

let modelLoaded = null; // Cache the promise for loading the model


//#region - This section is using AWS Bedrock
import { BedrockRuntimeClient, ListAsyncInvokesCommand, ConverseCommand } from "@aws-sdk/client-bedrock-runtime";
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_RECORD_ACCESS_KEY;
const SECRET_KEY = process.env.REACT_APP_AWS_RECORD_SECRET_KEY;
const BUCKET_NAME = process.env.REACT_APP_S3_RECORD_BUCKET_NAME;
const client = new BedrockRuntimeClient({ 
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
  },
 });

// Define the model ID and input
// const modelId = "huggingface-asr-whisper-large-v3-turbo"; // Replace with your model ID
const modelId = "mistral.mistral-large-2402-v1:0";
const modelWhisper = 'huggingface-asr-whisper-large-v3-turbo'


// Trial
export const textGeneration = async() => {
  // Start a conversation with the user message.
  const userMessage =
  "Write me a blog about Agentic RAG and use it health care.";
  const conversation = [
    {
      role: "user",
      content: [{ text: userMessage }],
    },
  ];

  // Create a command with the model ID, the message, and a basic configuration.
  const command = new ConverseCommand({
    modelId,
    messages: conversation,
    inferenceConfig: { maxTokens: 512, temperature: 0.5, topP: 0.9 },
  });

  try {
    // Send the command to the model and wait for the response
    const response = await client.send(command);

    // Extract and print the response text.
    const responseText = response.output.message.content[0].text;
    console.log(responseText);
  } catch (err) {
    console.log(`ERROR: Can't invoke '${modelId}'. Reason: ${err}`);
  }

}

export const transcribeAudioBedrock = async (audioUrl) => {

}

// Main function to transcribe
export const transcribeBedrock = async (audioBlob) => {
  if (!audioBlob) {
    console.error('No audio file provided.');
    return null;
  }

  try {
    const audioUrl = await uploadToS3(audioBlob);
    const transcriptionText = await transcribeAudioBedrock(audioUrl);
    return transcriptionText;
  } catch (error) {
    console.error('Error in transcription:', error.message);
    return null;
  }
};

export const  invokeModel = async (audioBlob) => {
 

  try {

    const params = {
      EndpointName: "endpoint-quick-start-ztn8f", 
      Body: audioBlob, 
      ContentType: "audio/wav",
    };

    const command = new InvokeModelCommand(params);
    console.log(command)
    const response = await client.send(command);
    console.log(response);

    console.log("Model Response:", response);
  } catch (error) {
    console.error("Error invoking Bedrock model:", error);
  }
}

//#endregion


//#region - This section is using AWS Transcribe
import { S3 } from 'aws-sdk';
import {
  TranscribeClient,
  StartMedicalTranscriptionJobCommand,
  GetMedicalTranscriptionJobCommand,
  DeleteMedicalTranscriptionJobCommand,
} from '@aws-sdk/client-transcribe';

// AWS Configuration
// const REGION = process.env.REACT_APP_AWS_REGION;
// const ACCESS_KEY = process.env.REACT_APP_AWS_RECORD_ACCESS_KEY;
// const SECRET_KEY = process.env.REACT_APP_AWS_RECORD_SECRET_KEY;
// const BUCKET_NAME = process.env.REACT_APP_S3_RECORD_BUCKET_NAME;

const transcribeService = new TranscribeClient({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
  },
});

const s3 = new S3({
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
});

// Main function to transcribe
export const transcribeAWS = async (audioBlob) => {
  if (!audioBlob) {
    console.error('No audio file provided.');
    return null;
  }

  try {
    const audioUrl = await uploadToS3(audioBlob);
    const transcriptionText = await transcribeAudioAWS(audioUrl);
    return transcriptionText;
  } catch (error) {
    console.error('Error in transcription:', error.message);
    return null;
  }
};

// Upload audio to S3
const uploadToS3 = async (audioBlob) => {
  const file = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
  const key = `${Date.now()}_${file.name}`;

  try {
    const params = {
      Bucket: BUCKET_NAME,
      Key: key,
      Body: file,
      ContentType: file.type,
    };
    const { Location } = await s3.upload(params).promise();
    console.log('File uploaded to S3:', Location);
    return Location;
  } catch (error) {
    console.error('Error uploading to S3:', error.message);
    throw error;
  }
};

// Transcribe audio from S3 URL
const transcribeAudioAWS = async (audioUrl) => {
  const jobName = `transcription_${Date.now()}`;
  const params = {
    MedicalTranscriptionJobName: jobName,
    LanguageCode: 'en-US',
    Specialty: 'PRIMARYCARE',
    Type: 'DICTATION',
    Media: { MediaFileUri: audioUrl },
    OutputBucketName: BUCKET_NAME,
  };

  try {
    console.log('Starting transcription job...');
    await transcribeService.send(new StartMedicalTranscriptionJobCommand(params));

    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // Poll every 5 seconds
      const { MedicalTranscriptionJob } = await transcribeService.send(
        new GetMedicalTranscriptionJobCommand({ MedicalTranscriptionJobName: jobName })
      );

      if (MedicalTranscriptionJob.TranscriptionJobStatus === 'COMPLETED') {
        const transcriptUri = MedicalTranscriptionJob.Transcript.TranscriptFileUri;
        console.log('Transcription completed:', transcriptUri);

        const response = await fetch(transcriptUri);
        const transcriptData = await response.json();
        const text = transcriptData.results.transcripts[0].transcript;

        console.log('Transcription Text:', text);

        // Delete transcription job
        await transcribeService.send(
          new DeleteMedicalTranscriptionJobCommand({ MedicalTranscriptionJobName: jobName })
        );
        console.log(`Transcription job "${jobName}" deleted successfully.`);

        return text;
      } else if (MedicalTranscriptionJob.TranscriptionJobStatus === 'FAILED') {
        throw new Error('Transcription job failed.');
      }
    }
  } catch (error) {
    console.error('Error during transcription:', error.message);
    throw error;
  }
};

//#endregion


//#region - This Section is using local AI after running Working.bat

export const loadModel = async () => {
  if (!modelLoaded) {
    modelLoaded = (async () => {
      try {
        const formData = new FormData();
        formData.append('model', '/zip/ggml-medium.en.bin'); // Path to the model on the server

        const response = await axios.post('http://127.0.0.1:8000/load', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Model Load Response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error loading model:', error);
        throw new Error('Error loading model: ' + (error.message || error));
      }
    })();
  }
  return modelLoaded;
};

// Transcribe audio
export const transcribe = async (audioBlob) => {
  if (!audioBlob) {
    console.error('No audio file provided.');
    return;
  }

  try {
    // Validate audioBlob
    const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });

    const formData = new FormData();
    formData.append('file', audioFile); // Attach the audio file
    formData.append('temperature', '0.1'); // Optional parameter
    formData.append('response_format', 'json'); // Specify response format

    const response = await axios.post('http://127.0.0.1:8000/inference', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Transcription Response:', response.data);
    return response.data.text;
  } catch (error) {
    console.error('Error during transcription:', error);
    return { error: 'Error during transcription', details: error.message || error };
  }
};

export const transcribeAudioLocal = async (audioBlob) => {
  try {
    // Step 1: Load the model
    const loadResponse = await loadModel();
    console.log('Model loaded:', loadResponse);

    // Step 2: Transcribe the audio
    console.log(audioBlob)
    const transcription = await transcribe(audioBlob);
    console.log('Transcription:', transcription);

    return transcription;
  } catch (error) {
    console.error('Error in processAudio workflow:', error);
    return { error: 'Workflow failed', details: error.message || error };
  }
};

//#endregion


//#region - This section is using AWS Transcribe
const OPENAI_CHAT = 'https://api.openai.com/v1/chat/completions'

export const transcribeAudio = async (blob) => {
  if (!blob) {
    console.error('No audio to transcribe.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('model', 'whisper-1');
    formData.append('language', 'en');
    formData.append('temperature', '0.1');  

    const transcribeRes = await axios.post(
      'https://api.openai.com/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );
    return transcribeRes.data.text;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    return 'Error transcribing audio.';
  }
};

// export const transcribeAudioLocal = async (blob) => {

//   const result = await loadModel(blob);
//   console.log(result);

//   const transcription = await transcribe(blob);
//   console.log('Transcription:', transcription);

//   // if (!blob) {
//   //   console.error('No audio to transcribe.');
//   //   return;
//   // }

//   // try {
//   //   const modelFile = new File([blob], 'ggml-tiny.en.bin', { type: 'application/octet-stream' });
//   //   const formData = new FormData();
//   //   // formData.append('file', blob);
//   //   // formData.append('model', '/zip/ggml-tiny.en.bin');
//   //   formData.append('model', modelFile);
//   //   // formData.append('language', 'en');
//   //   // formData.append('temperature', '0.1');  

//   //   const transcribeRes = await axios.post(
//   //     localMic,
//   //     formData,
//   //     {
//   //       headers: {
//   //         'Content-Type': 'multipart/form-data'
//   //       },
//   //     }
//   //   );
//   //   console.log(transcribeRes)
//   //   return transcribeRes.data.text;
//   // } catch (error) {
//   //   console.error('Error transcribing audio:', error);
//   //   return 'Error transcribing audio.';
//   // }
// };

export const LLMTextToJSON = async (processedInput) => {
  try {
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }


    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }

    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};


export const LLMTextToText = async (processedInput) => {
  try {
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};

export const writeNotes = async (instruction, input) => {
  var respond = await LLMTextToText(instruction);
  return respond;
}

export const sortingText = async (input) => {
    var respond = await LLMTextToJSON(input);
    return respond;
}

export const AITextToObject = async (instruction, input) => {

  var processedInput = `${instruction}\nUser: ${input}}`;

  try {
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }
    console.log(messageContent)

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};

export const AITextToText = async (instruction, input, initData) => {

  const messages = [
    { role: 'system', content: instruction }
  ];

  if (input !== "") {
    messages.push({ role: 'user', content: input });
  } else {
    return;
  }

  if (initData) {
    messages.push({ role: 'user', content: JSON.stringify(initData) });
  }



  try {
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-4o-mini',
        messages: messages,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};

export const AITextToObjectwithData = async (instruction, input, initData) => {

  // var processedInput = `${instruction}\nUser: ${input}}`;

  try {
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-3.5-turbo',
        messages: [
          { role : 'system', content: instruction},
          { role: 'user', content: input },
          { role: 'user', content: JSON.stringify(initData) }
        ],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};

export const AITextToObjectwithDataAndRef = async (instruction, input, initData, referrences) => {
  try {
    // Create message array conditionally based on the existence of `referrences`
    const messages = [
      { role: 'system', content: instruction }
    ];

    if (input !== "") {
      messages.push({ role: 'user', content: input });
    }

    if (initData) {
      messages.push({ role: 'user', content: JSON.stringify(initData) });
    }

    // Add `referrences` only if it exists
    if (referrences) {
      messages.push({ role: 'user', content: JSON.stringify(referrences) });
    }
    const res = await axios.post(
      OPENAI_CHAT,
      {
        model: 'gpt-4o-mini',
        messages: messages,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;
    
    const jsonPrefix = 'Here is the report in JSON format:';

    // Comprehensive cleanup of JSON artifacts
    messageContent = messageContent
      .replace(/```json/g, '')
      .replace(/```/g, '')
      .replace(/\.\.\./g, '')
      .replace(jsonPrefix, '') // Removes the specific prefix text if it appears
      .trim();

    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as appropriate
  }
};

//#endregion
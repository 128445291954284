import React, { Component } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class SmartPaste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paste: false,
      showModal: false,
      smartPaste: '', // Initialize smartPaste
      isLoadingPaste: false, // Initialize isLoadingPaste
    };
    this.modalRef = React.createRef();
    this.handleSmartPaste = this.handleSmartPaste.bind(this); // Bind the method
  }

  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.setState({ showModal: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleSmartPaste(event) {
    event.preventDefault(); 

    const pasteData = this.state.smartPaste;

    // Create a single toast for the entire transcription process
    toast.info('Reading data...', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
      hideProgressBar: false
  });
    this.props.onPasteData(pasteData);

    this.setState({smartPaste:""})
    this.toggleModal();
  }

  // render() {
  //   return (
  //     <div></div>
  //   )
  // }

  render() {
    const { paste, showModal, smartPaste } = this.state;

    return (
      <div className='flex justify-center col-span-6 my-4'>
        {/* Toggle Button */}
        <button
          id='smartPaste'
          className={`${
            paste ? 'bg-red-600 hover:bg-red-700' : 'bg-sky-800 hover:bg-sky-900'
          } flex items-center text-white px-2 py-2 rounded-3xl`}
          onClick={this.toggleModal}
        >
          <ClipboardDocumentIcon className="w-8 h-8" />
        </button>

        {/* Modal */}
        {showModal && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-start"
            onClick={this.toggleModal}
          >
            <div
              className="bg-white shadow-lg p-6 mt-10 w-[80%] rounded transform transition-transform duration-300 ease-in-out"
              onClick={(event) => event.stopPropagation()}
              ref={this.modalRef}
            >
              <div className="m-10 z-10">
                <div className="m-4">
                  <p className="text-md font-semibold mx-4 text-center">Smart Paste</p>
                  <form onSubmit={this.handleSmartPaste}>
                    <textarea
                      value={smartPaste}
                      onChange={(e) => this.setState({ smartPaste: e.target.value })}
                      className="w-full h-[500px] border-gray-200 resize-none"
                      placeholder="Paste Data here"
                    />
                    <button
                      className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800"
                      type="submit"
                    >
                      Process Data
                    </button>
               
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      <ToastContainer />
      </div>
    );
  }
}

export default SmartPaste;

import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header1 = "On how many of the past 28 days ...";
const header2 = "Please fill in the appropriate number in the boxes on the right. Remember that the questions only refer to the past four weeks (28 days)";
const header3 = "Please circle the appropriate number. Please note that for these questions the term “binge eating” means eating what others would regard as an unusually large amount of food for the circumstances, accompanied by a sense of having lost control over eating";
   
const questions = [
    {type: "Restraint", number:1, question: "Have you been deliberately trying to limit the amount of food you eat to influence your shape or weight (whether or not you have succeeded)?" , value: [0,1,2,3,4,5,6] },
    {type: "Restraint", number:2, question: "Have you gone for long periods of time (8 wakinghours or more) without eating anything at all in order to influence your shape or weight?" , value: [0,1,2,3,4,5,6] },
    {type: "Restraint", number:3, question: "Have you tried to exclude from your diet anyfoods that you like in order to influence your shape or weight (whether or not you havesucceeded)?" , value: [0,1,2,3,4,5,6] },
    {type: "Restraint", number:4, question: "Have you tried to follow definite rules regardingyour eating (for example, a calorie limit) in order to influence your shape or weight (whether or not you have succeeded)?" , value: [0,1,2,3,4,5,6] },
    {type: "Restraint", number:5, question: "Have you had a definite desire to have an empty stomach with the aim of influencing your shape or weight?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:6, question: "Have you had a definite desire to have a totally flat stomach?" , value: [0,1,2,3,4,5,6] },
    {type: "Eating", number:7, question: "Has thinking about food, eating or calories made it very difficult to concentrate on things you are interested in (for example, working, following a conversation, or reading)?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape, Eating", number:8, question: "Has thinking about shape or weight made it very difficult to concentrate on things you are interested in (for example, working, following a conversation, or reading)?" , value: [0,1,2,3,4,5,6] },
    {type: "Eating", number:9, question: "Have you had a definite fear of losing control over eating?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:10, question: "Have you had a definite fear that you might gain weight?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:11, question: "Have you felt fat?" , value: [0,1,2,3,4,5,6] },
    {type: "Weight", number:12, question: "Have you had a strong desire to lose weight?" , value: [0,1,2,3,4,5,6] },
    {type: "", number:13, question: "Over the past 28 days, how many times have you eaten what other people would regards as an unusually large amount of food (given the circumstances)?" , value: [0,1,2,3,4,5,6] },
    {type: "", number:14, question: "On how many of these times did you have a sense of having lost control over your eating (at the time you were eating)?", value: [0,1,2,3,4,5,6] },
    {type: "", number:15, question: "Over the past 28 days, on how many DAYS have such episodes of overeating occurred (i.e. you have eaten an unusually large amount of food and have had a sense of loss of control at the time)?" , value: [0,1,2,3,4,5,6] },
    {type: "", number:16, question: "Over the past 28 days, how many times have you made yourself sick (vomit) as a means of controlling your shape or weight?" , value: [0,1,2,3,4,5,6] },
    {type: "", number:17, question: "Over the past 28 days, how many times have you taken laxatives as a means of controlling your shape or weight?" , value: [0,1,2,3,4,5,6] },
    {type: "", number:18, question: "Over the past 28 days, how many times have you exercised in a “driven” or “compulsive” way as a means of controlling your weight, shape or amount of fat, or to burn off calories" , value: [0,1,2,3,4,5,6] },
    {type: "Eating", number:19, question: "Over the past 28 days, on how many days have you eaten in secret (ie, furtively)?......Do not count episodes of binge eating" , value: [0,1,2,3,4,5,6] },
    {type: "Eating", number:20, question: "On what proportion of the times that you have eaten have you felt guilty (felt that you’ve done wrong) because of its effect on your shape or weight? ... Do not count episodes of binge eating." , value: [0,1,2,3,4,5,6] },
    {type: "Eating", number:21, question: "How concerned have you been about other people seeing you eat? (Do not count episodes of binge eating" , value: [0,1,2,3,4,5,6] },
    {type: "Weight", number:22, question: "Has your weight influenced how you think about (judge) yourself as a person?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:23, question: "Has your shape influenced how you think about (judge) yourself as a person?" , value: [0,1,2,3,4,5,6] },
    {type: "Weight", number:24, question: "How much would it have upset you if you had been asked to weigh yourself once a week (no more, or less, often) for the next four weeks?" , value: [0,1,2,3,4,5,6] },
    {type: "Weight", number:25, question: "How dissatisfied have you been with your weight?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:26, question: "6 How dissatisfied have you been with your shape?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:27, question: "How uncomfortable have you felt seeing your body (for example, seeing your shape in the mirror, in a shop window reflection, while undressing or taking a bath or shower)?" , value: [0,1,2,3,4,5,6] },
    {type: "Shape", number:28, question: "How uncomfortable have you felt about others seeing your shape or figure (for example, in communal changing rooms, when swimming, or wearing tight clothes)?" , value: [0,1,2,3,4,5,6] }
]

const title = "Eating Disorder Examination Questionnaire";
const instrument = "EDEQ";
const explanation = `The EDE-Q can be used with anyone over the age of 14. The Eating Disorder Examination Questionnaire (EDE-Q) is a widely used self-report measure for assessing eating disorder symptoms. It consists of 36 items that assess the frequency and severity of various eating disorder behaviors, including dietary restraint, eating concern, weight concern, and shape concern.

The items are rated on a seven-point Likert scale, ranging from 0 (no days) to 6 (every day), with higher scores indicating greater severity of the symptoms. The EDE-Q also includes subscales for each of the four domains of eating disorder symptoms, as well as a global score that represents the overall severity of the eating disorder symptoms.

The EDE-Q is frequently used in clinical and research settings to assess eating disorder symptoms, monitor changes in symptoms over time, and evaluate treatment outcomes. It is important to note, however, that self-report measures like the EDE-Q are only one part of a comprehensive evaluation for eating disorders, and a diagnosis should be made by a qualified healthcare professional based on a thorough assessment.`

const citation = `Fairburn, C., Cooper, Z., & O’Connor, M. (2014). Eating Disorder Examination (Edition 17.0D; April 2014)

Fairburn, C, Cooper, Z. & O’Connor, M. ‘Eating Disorder Examination’ in Fairburn, C.G. Cognitive Behaviour Therapy and Eating Disorders. Guildford Press, New York, 2008.

It is freely available for non-commercial clinical or research use and no permission need be sought. This includes using the EDE-Q digitally as long as it is for non-commercial clinical or research use.`


class EDEQ extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latest:"", 
                subset : [],
                tool: props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;

        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 70px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>EDEQ</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (

        <div>
            <div className='header p-4 bg-gray-200 sticky top-0'>{header1}</div>

            {questions.slice(0,12).map((item) => (
                      <div className='section flex flex-col border-b-2'>
                      <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                      <div className='flex flex-row xs:flex-col options w-11/12 xs:w-auto ml-6'>
                        <div className='optionblock flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> No days</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> 1-5 days</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> 6-12 days</div>
                        </div>
                        <div  className='optionblock flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> 13-15 days</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> 16-22 days</div>
                        </div>
                        <div className='optionblock flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",5]" } value={item.value[5]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> 23-27 days</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",6]" } value={item.value[6]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Everyday</div>
                        </div>
                    </div> 
                </div>
                
            ))}

            <div className='header p-4 bg-gray-200 sticky top-0'>{header2}</div>

            {questions.slice(12,18).map((item) => (
                      <div className='section flex flex-col border-b-2'>
                      <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                      <div className='options flex flex-col w-5/12 xs:w-auto ml-6'>
                        <div className='p-2'><input id={"[" + questions.indexOf(item) + ",0]" } className='w-20 bg-sky-100 focus:bg-gray-100 px-2 py-2 border-gray-300 rounded'  /></div>
                    </div> 
                </div>
                
            ))}

            <div className='header p-4 bg-gray-200 sticky top-0'>{header3}</div>

            {questions.slice(18,28).map((item) => (
                    <div className='section flex flex-col border-b-2'>
                    <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='flex flex-row xs:flex-col options w-11/12 xs:w-auto ml-6'>
                        <div className='flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Not at all</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Not much</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> A little bit</div>
                        </div>
                        <div className='flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Moderately</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Quite a bit</div>
                        </div>
                        <div className='flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",5]" } value={item.value[5]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Very much</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",6]" } value={item.value[6]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Markedly</div>
                        </div>
                    </div> 
                </div>
                
            ))}

        </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");

    }

    calculate() {
      
        var finalscore = 0;
        var restraint = 0;
        var eating = 0;
        var shape = 0;
        var weight = 0;

        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;

        //checking question 1-12
        questions.slice(0,12).map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;
               
                    if (item.type.includes('Restraint')) {
                        restraint= restraint + value;
                    } else if (item.type.includes("Eating")) {
                        eating = eating + value;
                    } else if (item.type.includes("Shape")) {
                        shape = shape + value;
                    } else if (item.type.includes("Weight")) {
                        weight = weight + value;
                    }
                    noBlank = noBlank + 1;
                }
            }     
        })

        

        //checking question 20-28
        questions.slice(18,28).map(item => {
            var questionNumber = questions.indexOf(item);
            
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;
                
                    if (item.type.includes('Restraint')) {
                        restraint= restraint + value;
                    } else if (item.type.includes("Eating")) {
                        eating = eating + value;
                    } else if (item.type.includes("Shape")) {
                        shape = shape + value;
                    } else if (item.type.includes("Weight")) {
                        weight = weight + value;
                    }
                    noBlank = noBlank +1;
                 
                }
            }     
        })

        if (noBlank !== questions.length-6) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length-6) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            var numGS = (restraint + eating + shape + weight);
            var globalScore = (numGS/22).toFixed(2);

            var score = `Global Score: ${globalScore}. Restraint : ${restraint/5}. Eating concern: ${eating/5}. Shape concern: ${shape/8}. Weight concern: ${weight/5}`
            this.setState({score: score})
            this.setState({subset:[globalScore/22, restraint/5, eating/5, shape/8, weight/5 ] })
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        //building interpretation
        var globalScore, restraint, eating, shape, weight;
        var subset = this.state.subset;
        if (subset[0] > 1.404) {
            globalScore = "Global score above normative value of 1.404 is clinically significant."
        } else {
            globalScore = "Global Score is not clinically significant.";
        }

        if (subset[1] > 1.251) {
            restraint = "Restraint subset above normative value of 1.251 is clinically significant."
        } else {
            restraint = "Restraint subset is not clinically significant."
        }
        
        if (subset[2] > 0.624) {
            eating = "Eating concern subset above normative value of 0.624 is clinically significant."
        } else {
            eating = "Eating concern subset is not clinically significant."
        }

        if (subset[3] > 2.149) {
            shape = "Shape concern subset above normative value of 2.149 is clinically significant."
        } else {
            shape = "Shape concern subset is not clinically significant."
        }

        if (subset[4] > 1.587) {
            weight = "Weight concern subset above normative value of 1.587 is clinically significant."
        } else {
            weight = "Weight concern subset is not clinically significant.";
        }
        
        var notes = `Result indicates ${globalScore} ${restraint} ${eating} ${shape} ${weight}`


        newData['notes'] = notes
        

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', EDEQ : [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-md -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-3/4 xs:h-3/4 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-sm'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default EDEQ;


import React from 'react';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AudioRecorder from '../../utilities/AudioRecorder'; 
import RecordLocal from '../../utilities/RecordLocal';
import SmartPaste from '../../utilities/SmartPaste';
import { workflowAI } from '../../utilities/WorkflowAI';
import Tooltip from './ToolTip';
import axios from 'axios';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;




const formatTimeStamp = (dateString) => {
    const date = new Date(dateString);
    
    // Format date as DD/MM/YYYY
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    
    // Format time as hh:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    return `${formattedDate} on ${formattedTime}`;
};


class Tools extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');
        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            //AI section
            isLoading: false,
            AInote: "",
            isProcessing: false, 
            // tooltip
            hoveredItem: null, 
            // credits
            credits: 0

        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    componentDidMount() {
        var credits = document.getElementById("creditLeft").innerText
        this.setState({credits: credits})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }

    }
    
    // update parent
    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    // Processing paste data
    handlePaste = async (pasteData) => {

        this.handleInputUpdate(pasteData);
        // log event
        await axios.post(`${EndPoint}/log/event`, { type:'Smart Paste', credit: 5 }, { withCredentials: true });


    }

    handleInputUpdate = async (newTranscription) => {
        this.setState({ isProcessing: true });
    
        const initData = this.state.formData;
        const adminData = this.props.adminData;
        const referrences = adminData.referrences;
    
        const toastId1 = toast.info('Working on it...', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: false,
        });
    
        try {
            var respond = await workflowAI(newTranscription, initData, referrences);
            this.setState({ formData: respond });
            this.updatePatientData();
    
            toast.update(toastId1, {
                render: 'All task done!',
                type: toast.TYPE.SUCCESS,
                autoClose: 1000,
                hideProgressBar: false,
            });
        } catch (error) {
            toast.update(toastId1, {
                render: 'Error while processing!',
                type: toast.TYPE.ERROR,
                autoClose: 3000,
                hideProgressBar: false,
            });
        } finally {
            this.setState({ isProcessing: false });
        }
    };
    

    handleMouseEnter = (item) => {
    this.setState({ hoveredItem: item });
    };

    handleMouseLeave = () => {
    this.setState({ hoveredItem: null });
    };
    


    render() {
        const { hoveredItem, credits } = this.state;
        const isDisabled = credits === "0";

        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(320px, auto)' }}>
              
                <div className={`col-span-6 grid grid-cols-2 bg-slate-200 m-2 rounded ${isDisabled ? 'opacity-25 pointer-events-none' : ''}`}>
                    <div  
                        className={`place-self-end z-20 relative ${this.state.isProcessing ? 'opacity-50 pointer-events-none' : ''}`}      
                        onMouseEnter={() => this.handleMouseEnter('SmartPaste')}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <SmartPaste onPasteData={this.handlePaste} />            
                        {hoveredItem === 'SmartPaste' && <Tooltip text="Smart Paste" />}
                    </div>
        


                    <div    
                        className={`place-self-start -ml-80 sm:-ml-[700px] relative ${this.state.isProcessing ? 'opacity-50 pointer-events-none' : ''}`}
                        onMouseEnter={() => this.handleMouseEnter('Recorder')}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <AudioRecorder onInputUpdate={this.handleInputUpdate} />
                        {hoveredItem === 'Recorder' && <Tooltip text="Start Recording"/>}
                    </div>

                </div>
                
                <ToastContainer />
            </div>
        );
    }
    
}

export default Tools;

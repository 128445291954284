import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import MHCPPdf from '../../docs/MHCP.pdf';

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Extract the day, month, and year
  const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits for the day
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for the month
  const year = dateObject.getFullYear();

  // Format the date as "21/03/2024"
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

function wrapText(text, maxLength = 60) {
    if (!text) return '';
    const words = text.split(' ');
    let wrappedText = '';
    let line = '';

    words.forEach((word) => {
        if (line.length + word.length + 1 > maxLength) {
            wrappedText += line.trim() + '\n';
            line = '';
        }
        line += word + ' ';
    });

    wrappedText += line.trim(); // add the last line
    return wrappedText;
}

// Function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Function to format an object into a "Key: Value" string with capitalized keys and values
// Function to format an object into a "Key: Value" string with capitalized keys and values, excluding 'id' and 'date'
function formatObjectToString(obj) {
  if (!obj) return ''; // Return an empty string if obj is undefined or null
  return Object.entries(obj)
      .filter(([key]) => key !== 'id' && key !== 'date') // Exclude 'id' and 'date'
      .map(([key, value]) => `${formatKey(key)}: ${capitalizeFirstLetter(String(value))}`)
      .join('\n');
}

// Function to format a camelCase key into a human-readable string
function formatKey(key) {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert a space before capital letters
    .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
}

// Function to format a list of objects into a string, handling nested arrays, excluding 'id' and 'date'
function formatListOfObjectsToString(list) {
  if (!list || !Array.isArray(list)) return ''; // Return an empty string if list is undefined, null, or not an array

  return list.map(obj => 
      Object.entries(obj)
          .filter(([key]) => key !== 'id' && key !== 'collapsed' && key !== 'selected') // Exclude 'id' and 'collapsed'
          .map(([key, value]) => {
              if (Array.isArray(value)) {
                  // If value is an array, format each object in the array with specific keys
                  if (key === 'goal') {
                      return `${capitalizeFirstLetter(key)}:\n${value.map(item => `- ${capitalizeFirstLetter(item.description)}`).join('\n')}`;
                  } else if (key === 'plan') {
                      return `${capitalizeFirstLetter(key)}:\n${value.map(item => `- ${capitalizeFirstLetter(item.entry)} - ${capitalizeFirstLetter(item.status)}`).join('\n')}`;
                  } 
              } else {
                  // Otherwise, format the value as a string
                  return `${capitalizeFirstLetter(key)}: ${capitalizeFirstLetter(String(value))}`;
              }
          })
          .join('\n')
  ).join('\n_______________________________________________________________________________________________________________________\n\n');
}



export const printMHCP = async (event, formData, dateOfPlan, reviewDate, signature) => {
  event.preventDefault();
  
  // Fetch your existing PDF as an array buffer
  const existingPdfBytes = await fetch(MHCPPdf).then(res => res.arrayBuffer());

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
  const page = pdfDoc.getPage(0); // Get the first page of the PDF

  const width = 595.28; // A4 width
  const height = 841.89; // A4 height
  const margin = 50;
  const fontSize = 12;


  // Load a standard font
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

  // Get the form in the PDF
  const form = pdfDoc.getForm();

  const profile = formData.profile;
  var history = formData.history;
  if (!history) {
    history = []
  }
  const practitioner = formData.practitioner;
  var mhcp = formData.mhcp;

  const formulation = formData.mhformulation;
  const safety = formData.mhsafety;
  const problems = formData.planning;
  const relapse = formData.relapsePrevention;
  const team = formData.teams;

  const team1Content = team.slice(0, 3).map(practitioner => 
      `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}\n` +
      `${practitioner.practiceName} \n` +
      `${practitioner.address1} ${practitioner.address2}\n` +
      `${practitioner.suburb}, ${practitioner.state} ${practitioner.postcode}\n` +
      `Phone: ${practitioner.phone}\n` +
      `Email: ${practitioner.email}\n`
  ).join('\n\n');

  const team2Content = team.length > 3 ? team.slice(3).map(practitioner => 
    `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}\n` +
    `${practitioner.practiceName} \n` +
    `${practitioner.address1} ${practitioner.address2}\n` +
    `${practitioner.suburb}, ${practitioner.state} ${practitioner.postcode}\n` +
    `Phone: ${practitioner.phone}\n` +
    `Email: ${practitioner.email}\n`
  ).join('\n\n'): "";


  const formFields = [ 
    { field: "Patient", content: `${profile.firstName} ${profile.lastName}\nDOB : ${formatDate(profile.dob)}\nGender : ${profile.gender}\nPronoun : ${profile.pronoun}\n${profile.address1} ${profile.address2} \n${profile.suburb}, ${profile.state} ${profile.postcode}` },
    { field: "GP", content: `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}\n${practitioner.practiceName} \n${practitioner.address1} ${practitioner.address2}\n${practitioner.suburb}, ${practitioner.state} ${practitioner.postcode}\nPhone : ${practitioner.phone}\nEmail : ${practitioner.email}\n${practitioner.providerNumber}` },
    { field: "Carer", content: formatListOfObjectsToString(history.carer) || "" },
    { field: "Emergency", content: formatListOfObjectsToString(history.emergency) || ""},
    { field: "Cultural", content: formatObjectToString(history.cultural) || ""  },
    { field: "Education", content: formatObjectToString(history.eduEmploy) || "" },
    { field: "presentingIssue", content: mhcp.presentingIssue || "" },
    { field: "history", content: mhcp.history || "" },
    { field: "pasthistory", content: mhcp.pastHistory || "" },
    { field: "parentChildNeed", content: mhcp.parentChildNeed || "" },
    { field: "social", content: mhcp.social || "" },
    { field: "substance", content: mhcp.substance || "" },
    { field: "currentMedication", content: mhcp.currentMedication || "" },
    { field: "treatmentHistory", content: mhcp.treatmentHistory || "" },
    { field: "allergies", content: mhcp.allergies || "" },
    { field: "relevantExamination", content: `${mhcp.relevantExamination || ""}\n${mhcp.relevantInvestigation || ""}` },
    { field: "devTesting", content: mhcp.devTesting || "" },
    { field: "mentalStateExamination", content: formatObjectToString(mhcp.mentalStateExamination)  || ""},
    { field: "risk", content: mhcp.risk || "" },
    { field: "tools", content: mhcp.tools || "" },
    { field: "diagnosis", content: mhcp.diagnosis || "" },
    { field: "formulation", content: formatObjectToString(formulation) || "" },
    { field: "plan", content: formatListOfObjectsToString(problems) || ""},
    { field: "relapse", content: formatObjectToString(relapse) || "" },
    { field: "team1", content: team1Content || "" },
    { field: "team2", content: team2Content || "" },
    { field: "date", content: formatDate(dateOfPlan) || "" }
  ]


  // go through each field
  formFields.forEach(item => {
    const field = form.getTextField(item.field);
    if (field) {
        field.setText(item.content);
    } else {
        console.warn(`Field ${item.field} not found in the PDF form.`);
    }
});



  if (signature) {
      const pages = pdfDoc.getPages();
      const lastPage = pages[4]; 

      if (lastPage) {
          // Convert the signature data URL to an image that can be embedded in the PDF
          const signatureImage = await pdfDoc.embedPng(signature);

          // Get the dimensions of the signature image
          const signatureDims = signatureImage.scale(0.5); // Scale the signature if necessary

          // Draw the signature on the second page at the bottom
          lastPage.drawImage(signatureImage, {
              x: 30, // Adjust X coordinate as needed
              y: 470, // Y coordinate near the bottom of the page
              width: signatureDims.width,
              height: signatureDims.height,
          });
      }
  }

  // Flatten the form to prevent further editing
  // form.flatten();

  // Serialize the PDF to bytes (Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Create a Blob and a link element to allow the user to download the PDF
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Open the PDF in a new browser window
  const newWindow = window.open(blobUrl, '_blank');
  if (!newWindow) {
      // Handle cases where the browser blocked the new window
      alert('Please allow pop-ups to view the PDF.');
  }
};

// export const printTCA = async (event, formDataArray, patient, practitioner, signature) => {
//     event.preventDefault();
  
//     // Fetch your existing PDF as an array buffer
//     const existingPdfBytes = await fetch(TCAPdf).then(res => res.arrayBuffer());
  
//     // Create a new PDFDocument to combine all individual PDFs into one
//     const combinedPdfDoc = await PDFDocument.create();
  
//     // Load a font for the sample letter
//     const font = await combinedPdfDoc.embedFont(StandardFonts.Helvetica);
  
//     // Iterate over each object in formDataArray to create a PDF for each
//     for (const formData of formDataArray) {
//       // Add a new page for the GP letter
//       const letterPage = combinedPdfDoc.addPage([595.28, 841.89]); // A4 size in points
//       const { width, height } = letterPage.getSize();
  
//       // Sample letter text with line breaks
//       const letterText = `
//   To : ${formData.title} ${formData.firstName} ${formData.lastName},
//        ${formData.address1}
//        ${formData.address2}
//        ${formData.suburb}, ${formData.state} ${formData.postcode}
  
//   Dear ${formData.firstName},
  
//   We are referring the ${patient.firstName} ${patient.lastName} for further evaluation and management.
  
//   Please find the attached detailed plan.
  
//   Sincerely,
  
  
  
//   ${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}
//       `;
  
//       // Split the letterText by newlines to handle line breaks
//       const lines = letterText.trim().split('\n');
  
//       // Set initial Y position for text
//       let yPosition = height - 100;
//       const lineHeight = 14; // Adjust line height as needed
  
//       // Draw each line of text
//       lines.forEach((line) => {
//         letterPage.drawText(line, {
//           x: 50,
//           y: yPosition,
//           size: 12,
//           font,
//           color: rgb(0, 0, 0),
//           maxWidth: width - 100,
//         });
//         yPosition -= lineHeight; // Move to the next line
//       });
  
//       // Embed the signature image if it exists
//       if (signature) {
//         const signatureImage = await combinedPdfDoc.embedPng(signature);
//         letterPage.drawImage(signatureImage, {
//           x: 50, // Adjust the x position
//           y: yPosition - 60, // Adjust the y position (below the "Sincerely" text)
//           width: 150, // Adjust the width of the signature
//           height: 50, // Adjust the height of the signature
//         });
//       }
  
//       // Load a PDFDocument from the existing PDF bytes
//       const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
  
//       // Get the form in the PDF
//       const form = pdfDoc.getForm();
  
//       // Define the fields and values to update based on formData
//       const fieldsToUpdate = [
//         { field: "GPName", value: `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}` },
//         { field: "GPAddress", value: `${practitioner.practiceName}, ${practitioner.address1} ${practitioner.address2}, ${practitioner.suburb} ${practitioner.state}` },
//         { field: "ProviderNumber", value: `${practitioner.providerNumber}` },
//         { field: "GPPostcode", value: `${practitioner.postcode}` },
//         { field: "PatientName", value: `${patient.firstName} ${patient.lastName}` },
//         { field: "MedicareNumber", value: `${patient.medicare}` },
//         { field: "PatientAddress", value: `${patient.address1} ${patient.address2}, ${patient.suburb} ${patient.state}` },
//         { field: "PatientPostcode", value: `${patient.postcode}` },
//         { field: "AHPName", value: `${formData.firstName} ${formData.lastName}` },
//         { field: "AHPAddress", value: `${formData.address1} ${formData.address2}, ${formData.suburb} ${formData.state}` },
//         { field: "AHPPostcode", value: `${formData.postcode}` },
//         { field: "ATSI", value: formData.profession === "ATSI Worker" ? formData.visits : `` },
//         { field: "Audiologist", value: formData.profession === "Audiologist" ? formData.visits : `` },
//         { field: "Chiropractor", value: formData.profession === "Chiropractor" ? formData.visits : `` },
//         { field: "DiabetesEducator", value: formData.profession === "Diabetes Educator" ? formData.visits : `` },
//         { field: "Dietitian", value: formData.profession === "Dietitian" ? formData.visits : `` },
//         { field: "ExercisePhysiologist", value: formData.profession === "Exercise Physiologist" ? formData.visits : `` },
//         { field: "MHWorker", value: formData.profession === "Mental Health Worker" ? formData.visits : `` },
//         { field: "OT", value: formData.profession === "Occupational Therapist" ? formData.visits : `` },
//         { field: "Osteopath", value: formData.profession === "Osteopath" ? formData.visits : `` },
//         { field: "Physio", value: formData.profession === "Physiotherapist" ? formData.visits : `` },
//         { field: "Podiatrist", value: formData.profession === "Podiatrist" ? formData.visits : `` },
//         { field: "Psychologist", value: formData.profession === "Psychologist" ? formData.visits : `` },
//         { field: "Speech", value: formData.profession === "Speech Therapist" ? formData.visits : `` },
//       ];
  
//       // Update the fields in the PDF
//       fieldsToUpdate.forEach(({ field, value }) => {
//         const fieldToUpdate = form.getTextField(field);
//         fieldToUpdate.setText(value);
//       });
  
//       // Fill up the Date field with the current date
//       const DateField = form.getTextField('Date');
//       const dateNow = new Date().toLocaleDateString('en-GB');
//       DateField.setText(dateNow);
  
//       // Embed the signature image in the form PDF if it exists
//       if (signature) {
//         const signatureImage = await pdfDoc.embedPng(signature);
//         const signaturePage = pdfDoc.getPage(0); // Assuming signature is on the first page
  
//         // Positioning the signature image in the form (Adjust x, y, width, and height as needed)
//         signaturePage.drawImage(signatureImage, {
//           x: 50, // Adjust the x position
//           y: 120, // Adjust the y position (bottom of the page)
//           width: 150, // Adjust the width of the signature
//           height: 50, // Adjust the height of the signature
//         });
//       }
  
//       // Flatten the form fields to make them non-editable
//       form.flatten();
  
//       // Serialize the individual PDF to bytes (Uint8Array)
//       const pdfBytes = await pdfDoc.save();
  
//       // Load the individual PDF into a new PDFDocument to extract pages
//       const individualPdfDoc = await PDFDocument.load(pdfBytes);
  
//       // Copy all pages from the individual PDF to the combined PDF
//       const [copiedPage] = await combinedPdfDoc.copyPages(individualPdfDoc, [0]);
//       combinedPdfDoc.addPage(copiedPage);
//     }
  
//     // Serialize the combined PDF to bytes (Uint8Array)
//     const combinedPdfBytes = await combinedPdfDoc.save();
  
//     // Create a Blob for the combined PDF
//     const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
  
//     // Create a URL for the Blob
//     const blobUrl = URL.createObjectURL(blob);
  
//     // Open the combined PDF in a new browser window or tab
//     const newWindow = window.open(blobUrl, '_blank');
//     if (!newWindow) {
//       alert('Please allow pop-ups to view the PDF.');
//     }
// };
  
  
  
import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import MHOption from '../PrintingComponents/MHOption';
import GPMPOption from '../PrintingComponents/GPMPOption';
import TCAOption from '../PrintingComponents/TCAOption';
import HAOption from '../PrintingComponents/HAOption';

const secretFile = process.env.REACT_APP_SECRET_FILE;
const secretKey = process.env.REACT_APP_SECRET_KEY;
const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

const patientDataInit = {
  profile: {},
  practitioner: {},
  teams: [],
  history : [],
  mhcp: {},
  mhformulation: {},
  mhsafety: {},
  mhrelapse: {},
  healthassessment: {},
  planning : [{id:'2o3in235', category: 'Chronic Disease', collapsed: false, priority: "Low", selected: "false", problem: "Double click to edit problem/issue", goal: [], journey: [], review:[], plan : []}],
  consultations: []
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileMenu: false,
      timeoutId: null,
      showPrintMenu: false,
      showPrintMHOption: false,
      showPrintHAOption: false,
      showPrintGPMPOption: false,
      showPrintTCAOption: false,
      isDropdownOpen: false,
      patientFileHandle: null,
      isAutoSaveActive: false, // State to track autosave toggle
      autoSaveIntervalId: null, // Store the interval ID for clearing later
      adminFileHandle: null,
      isFileLoaded: false,
      adminData: {}
    };
    this.menuRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adminData !== this.props.adminData) {
        this.setState({ adminData: this.props.adminData });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
    if (this.state.autoSaveIntervalId) {
      clearInterval(this.state.autoSaveIntervalId); // Clear the interval when the component unmounts
    }
  }

  handleClickOutside = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.closeAllMenus();
    }
  };

  closeAllMenus = () => {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
    this.setState({
      showFileMenu: false,
      showPrintMenu: false,
      timeoutId: null,
    });
  };

  // Patient File Menu handling
  saveDataAs = async () => {
    try {
      const patientData = this.props.patientData;
      const patient = patientData.profile || {};
      // Set default values for firstName and lastName if they are missing
      const firstName = patient.firstName || "FirstName";
      const lastName = patient.lastName || "LastName";

      // Use the default values in the suggested name
      const suggestedName = `${firstName}_${lastName}_${(patient.dob || '').split('T')[0]}.cdv`;
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(patientData), secretFile).toString();
  
      if (window.showSaveFilePicker) {
        const patientFileHandle = await window.showSaveFilePicker({
          suggestedName: suggestedName,
          types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
        });
        this.setState({ patientFileHandle });
  
        const writable = await patientFileHandle.createWritable();
        await writable.write(new Blob([encryptedData], { type: 'text/plain' }));
        await writable.close();
      } else {
        // Fallback for unsupported browsers
        const blob = new Blob([encryptedData], { type: 'text/plain' });
        const link = document.createElement('a');
  
        // Simulate "Save As" behavior
        link.href = URL.createObjectURL(blob);
        link.download = suggestedName.endsWith('.cdv') ? suggestedName : `${suggestedName}.cdv`;
        link.style.display = 'none';
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        alert(
          "Your file has been prepared for download. Look for the file in your downloads folder or specified location."
        );
      }
    } catch (error) {
      console.error('Error during save as:', error);
    }
  };
  
  
  fallbackSaveAs = (suggestedName) => {
    const { patientData } = this.props;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(patientData), secretFile).toString();
    const blob = new Blob([encryptedData], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = suggestedName.endsWith('.cdv') ? suggestedName : `${suggestedName}.cdv`; // Ensure .cdv extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  savePatientFile = async () => {
    try {
      const { patientData } = this.props;
      const { patientFileHandle } = this.state;

      if (!patientFileHandle) {
        throw new Error('No file handle available');
      }

      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(patientData), secretFile).toString();
      const writable = await patientFileHandle.createWritable(); // Get writable stream
      await writable.write(new Blob([encryptedData], { type: 'text/plain' })); // Write encrypted data
      await writable.close(); // Close the writable stream
      console.log('Patient file saved');
    } catch (error) {
      console.error('Error saving patient file:', error);
      this.fallbackSaveAs('patientData.cdv'); // Fallback if error occurs
    }
  };

  handleLoad = async () => {
    try {
      if (window.showOpenFilePicker) {
        const [fileHandle] = await window.showOpenFilePicker({
          types: [
            {
              description: 'CDV Files',
              accept: {
                'text/plain': ['.cdv'],
              },
            },
          ],
        });
        this.setState({ patientFileHandle: fileHandle });
  
        const file = await fileHandle.getFile();
        const fileContents = await file.text();
  
        const bytes = CryptoJS.AES.decrypt(fileContents, secretFile);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.props.loadPatientData(decryptedData, 'Loading');
      } else {
        // Fallback for unsupported browsers
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.cdv,text/plain';
        fileInput.onchange = async (event) => {
          const file = event.target.files[0];
          const fileContents = await file.text();
  
          const bytes = CryptoJS.AES.decrypt(fileContents, secretFile);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          this.props.loadPatientData(decryptedData, 'Loading');
        };
        fileInput.click();
      }
    } catch (error) {
      console.error('Error loading file:', error);
    }
  };
  

  handleNewFile = () => {
    this.props.loadPatientData(patientDataInit, 'New');
  };

  clearLocalStorage = () => {
    this.props.loadPatientData({}, 'Closing');
    this.setState({ showFileMenu: false, patientFileHandle: null });
  };

  toggleFileMenu = () => {
    this.setState((prevState) => ({
      showFileMenu: !prevState.showFileMenu,
    }));
  };

  togglePrintMenu = () => {
    this.setState((prevState) => ({
      showPrintMenu: !prevState.showPrintMenu,
      showFileMenu: false,
      showSettingsMenu: false,
    }), () => {
      if (this.state.showPrintMenu) {
        // Set a timeout to close the print menu after 3 seconds
        const timeoutId = setTimeout(() => {
          this.setState({ showPrintMenu: false });
        }, 3000);
  
        this.setState({ timeoutId });
      } else {
        // Clear timeout if the print menu is manually closed
        if (this.state.timeoutId) {
          clearTimeout(this.state.timeoutId);
        }
      }
    });
  };
  

  togglePrintMHOption = () => {
    this.setState((prevState) => ({
      showPrintMHOption: !prevState.showPrintMHOption,
    }));
  };
  togglePrintHAOption = () => {
    this.setState((prevState) => ({
      showPrintHAOption: !prevState.showPrintHAOption,
    }));
  };
  togglePrintGPMPOption = () => {
    this.setState((prevState) => ({
      showPrintGPMPOption: !prevState.showPrintGPMPOption,
    }));
  };
  togglePrintTCAOption = () => {
    this.setState((prevState) => ({
      showPrintTCAOption: !prevState.showPrintTCAOption,
    }));
  };

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

    // Close the dropdown after 3 seconds
    if (!this.state.isDropdownOpen) {
      setTimeout(() => {
        this.setState({ isDropdownOpen: false });
      }, 3000);
    }
  };

  ////  Admin Sections
  handleCreateAdminFile = async () => {
    try {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: 'admin.cdv',
        types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
      });

      const defaultAdmin = { contacts: [], practices: [], referrences: [], dashboard: [] };
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(defaultAdmin), secretFile).toString();
      await this.saveFile(fileHandle, encryptedData);
      this.saveToLocalStorage(defaultAdmin);

      this.setState({ adminFileHandle: fileHandle, isFileLoaded: true, adminData: defaultAdmin });
    } catch (error) {
      console.error('Error creating admin file:', error);
    }
  };

  handleLoadAdminFile = async () => {
    try {
      const fileHandle = await window.showOpenFilePicker({
        types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
      });
      const file = await fileHandle[0].getFile();
      const content = await file.text();
      const bytes = CryptoJS.AES.decrypt(content, secretFile);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
 
      this.setState({ adminFileHandle: fileHandle[0], isFileLoaded: true, adminData: decryptedData });
      this.saveAdminToLocalStorage(decryptedData);
      this.props.changeComponent("contacts")
    } catch (error) {
      console.error('Error loading admin file:', error);
    }
  };

  handleCloseAdminFile = () => {
    this.setState({ 
      adminFileHandle: null, 
      isFileLoaded: false, 
      adminData: null
    });
    localStorage.removeItem('admin');
    this.props.changeComponent("None")
  };

  saveAdminToLocalStorage = (updatedData) => {
    const { adminData } = this.state;
    const newData = { ...adminData, ...updatedData };

    try {
      const encryptedAdmin = CryptoJS.AES.encrypt(JSON.stringify(newData), secretStorage).toString();
      localStorage.setItem('admin', encryptedAdmin);
      this.setState({ adminData: newData });
      console.log('Data successfully saved to localStorage.');
      this.saveAdminToFileSystem(newData)
    } catch (error) {
      console.error('Error encrypting or saving to localStorage:', error);
    }
  };

  loadAdminFromLocalStorage = () => {
    const encryptedAdmin = localStorage.getItem('admin');
    if (encryptedAdmin) {
      const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
      const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.setState({ adminData });
      return adminData;
    }
    return { contacts: [], practices: [], dashboard: [], referrences: [] };
  };

  saveAdminToFileSystem = async (data) => {
    const { adminFileHandle } = this.state;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretFile).toString();
    const writable = await adminFileHandle.createWritable(); // Get writable stream
    await writable.write(new Blob([encryptedData], { type: 'text/plain' })); // Write encrypted data
    await writable.close();
    console.log('data save to file too')
  }

  ////// Toggle autosave feature with 2-minute interval
  toggleAutoSave = () => {
    const { isAutoSaveActive, autoSaveIntervalId } = this.state;

    if (isAutoSaveActive) {
      clearInterval(autoSaveIntervalId); // Clear the interval when toggling off
      this.setState({ isAutoSaveActive: false, autoSaveIntervalId: null });
      console.log('Auto-save disabled');
    } else {
      const intervalId = setInterval(this.savePatientFile, 2 * 60 * 1000); // Save every 2 minutes
      this.setState({ isAutoSaveActive: true, autoSaveIntervalId: intervalId });
      console.log('Auto-save enabled');
    }
  };

  render() {
    const { isAutoSaveActive } = this.state;
    const patientData = this.props.patientData;
    const adminData = this.state.adminData;

    return (
      <div className="bg-slate-600 text-white h-14 col-span-6 bg-slate-200 m-2 rounded mt-2 flex justify-start gap-10" ref={this.menuRef}>
        
        {this.props.type !== 'Settings' ? (
        <div>
          <div className="relative inline-block ml-2">
          <button
            id='file'
            onClick={this.toggleFileMenu}
            className="button hover:bg-sky-600 hover:text-white p-2 rounded my-2 h-10"
          >
            File
          </button>
          {this.state.showFileMenu && (
            <div className="absolute left-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-20">
              <ul className="py-1">
                {!patientData || Object.keys(patientData).length === 0 ? (
                  <>
                    <li>
                      <button
                        onClick={this.handleLoad}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        Open
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={this.handleNewFile}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        New
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button
                        onClick={this.saveDataAs}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        Save
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={this.clearLocalStorage}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        Close
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
  

        {!patientData || Object.keys(patientData).length === 0 ? (
            <></>
        ) : (
        
            <div className="relative inline-block">
            {!["Chat", "General", "Consultation"].includes(this.props.type) ? (
  <button
    id='print'
    onClick={this.togglePrintMenu}
    className="button hover:bg-sky-600 hover:text-white p-2 rounded my-2 h-10"
  >
    Print
  </button>
) : (<></>)}
            
            {this.state.showPrintMenu && (
                <div className="absolute left-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-20">
                  <ul className="py-1">
                    {this.props.type === "Mental Health" && (
                    <li>
                      <button
                        onClick={this.togglePrintMHOption}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        MHCP
                      </button>
                    </li>
                    )}
                    {this.props.type === "Health Assessment" && (
                    <li>
                      <button
                        onClick={this.togglePrintHAOption}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        Health Assessment
                      </button>
                    </li>
                    )}
                    {this.props.type === "CDM" && (
                      <>
                      <li>
                        <button
                          onClick={this.togglePrintGPMPOption}
                          className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                        >
                          GP Management Plan
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={this.togglePrintTCAOption}
                          className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                        >
                          Team Care Arrangement
                        </button>
                      </li>
                      </>
                    )}
                    {this.props.type === "Consultation" && (
                    <li>
                      <button
                        onClick={this.togglePrintOption}
                        className="block w-full text-left px-4 py-2 hover:bg-sky-600 hover:text-white"
                      >
                        Health Assessment
                      </button>
                    </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}

          {/* MHOption component */}
          {this.state.showPrintMHOption && (
            <MHOption onClose={this.togglePrintMHOption} formData={this.props.patientData} />
          )}
          {/* HAOption component */}
          {this.state.showPrintHAOption && (
            <HAOption onClose={this.togglePrintHAOption} formData={this.props.patientData} />
          )}
          {/* GPMPOption component */}
          {this.state.showPrintGPMPOption && (
            <GPMPOption onClose={this.togglePrintGPMPOption} formData={this.props.patientData} />
          )}
          {/* TCAOption component */}
          {this.state.showPrintTCAOption && (
            <TCAOption onClose={this.togglePrintTCAOption} formData={this.props.patientData} />
          )}
  

          {/* Auto-save toggle slider */}
          <div className="inline-block ml-8 items-center">
            <span className="mr-2 text-sm">Auto-Save</span>
            <label className="relative inline-block w-10 h-6">
              <input type="checkbox" className="hidden" onChange={this.toggleAutoSave} checked={isAutoSaveActive} />
              <span
                className={`mt-1 slider cursor-pointer transition-all duration-300 ease-in-out block w-full h-full rounded-full ${
                  isAutoSaveActive ? 'bg-green-600' : 'bg-gray-400'
                }`}
              />
              <span
                className={`mt-1 absolute top-0 left-0 w-6 h-6 rounded-full transform transition-transform duration-300 ease-in-out flex justify-center items-center ${
                  isAutoSaveActive ? 'translate-x-4 bg-green-700' : 'bg-gray-600'
                }`}
              >
                {isAutoSaveActive ? '✓' : '✕'}
              </span>
            </label>
          </div>
        </div>

        ) : (
          <div className="flex flex-row gap-10 ml-20 p-1 items-center">
          <div className="relative file-menu">
            <button
              className="text-white hover:bg-slate-300 hover:text-black rounded p-1"
              onClick={this.toggleDropdown}
            >
              File
            </button>
            {this.state.isDropdownOpen && (
              <div className="absolute bg-white text-black mt-2 rounded shadow">
                {!adminData ? (
                  <>
                    <button className="block px-4 py-2 hover:bg-gray-200 w-40" onClick={this.handleCreateAdminFile}>
                      Create Admin
                    </button>
                    <button className="block px-4 py-2 hover:bg-gray-200 w-40" onClick={this.handleLoadAdminFile}>
                      Load Admin
                    </button>
                  </>
                ) : (
                  <button className="block px-4 py-2 hover:bg-gray-200 w-40" onClick={this.handleCloseAdminFile}>
                    Close Admin
                  </button>
                )}
              </div>
            )}
          </div>
          {adminData && (
          <>
          {/* <button
          className="text-white hover:bg-slate-300 hover:text-black rounded p-1"
          onClick={() => this.props.changeComponent('dashboard')}
          >
            Dashboard
          </button> */}
          <button
          className="text-white hover:bg-slate-300 hover:text-black rounded p-1"
          onClick={() => this.props.changeComponent('contacts')}
          >
            Contacts
          </button>
          
          <button
            className="text-white hover:bg-slate-300 hover:text-black rounded p-1"
            onClick={() => this.props.changeComponent('practices')}
          >
            Practices
          </button>

          <button
          className="text-white hover:bg-slate-300 hover:text-black rounded p-1"
          onClick={() => this.props.changeComponent('referrences')}
          >
            Referrences
          </button>
          </>
          )}
        </div>
        )}
        
      </div>
    );
  }
}

export default Menu;
import React, { Component } from "react";
import axios from "axios";
import { useNavigation } from "../../utilities/NavigationContext";
import { useAuth } from '../../utilities/AuthContext';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Blog extends Component {
  state = {
    blogs: [],
  };

  componentDidMount() {
    axios
      .get(`${EndPoint}/blog`)
      .then((response) => {
        this.setState({ blogs: response.data });
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
      });

  }

  render() {
    const { blogs } = this.state;
    const { navigate, auth } = this.props; 
  
    return (
      <div className='bg-slate-200'>
      <div className="p-20 ">
        <h1 className="text-5xl text-sky-800 font-bold mb-6 text-center">Articles</h1>
        {auth && auth[0].role && auth[0].role.includes('admin') && (
        <button
          className="bg-sky-700 hover:bg-sky-800 p-2 m-2 text-white rounded shadow w-52 ml-32"
          onClick={() => navigate("/create-blog")}
        >
          Write Blog
        </button>
        )}
        <div className="flex flex-wrap justify-center gap-4">
          {blogs.map((blog, index) => (
            <div
              key={blog._id}
              className={`h-[490px]  bg-white rounded-md shadow-sm hover:shadow-md ${
                index === 0 ? "w-[820px] min-w-[300px] max-w-[820px]" : "w-[400px] min-w-[300px] max-w-[450px]"
              }`}
            >
              {blog.imageUrl && (
                <img
                  src={`${blog.imageUrl}`}
                  alt={blog.title}
                  className="w-full h-[300px] object-cover rounded-t-md"
                />
              )}
              <h2 className="text-2xl font-bold text-sky-800 mx-4 mt-4">{blog.title}</h2>
              <p className="text-sm italic text-gray-600 mx-4 line-clamp-3">by {blog.author}</p>
              <p className="text-md text-gray-600 mx-4 line-clamp-2 mt-2">{blog.summary}</p>
              <button
                className="text-red-600 text-sm hover:underline mt-2 m-4"
                onClick={() => navigate(`/blog?id=${blog._id}`)}
              >
                Read More
              </button>
            </div>
          ))}
        </div>
      </div>
      </div>
    );
  }
  
}

const BlogWithNavigation = (props) => {
  const navigate = useNavigation(); 
  const auth = useAuth(); 
  return <Blog {...props} navigate={navigate} auth={auth}  />;
};

export default BlogWithNavigation;

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { workflowAI } from '../../utilities/WorkflowAI';
import Joyride from 'react-joyride';
import axios from 'axios';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;


const formatTimeStamp = (dateString) => {
    const date = new Date(dateString);
    
    // Format date as DD/MM/YYYY
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    
    // Format time as hh:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    return `${formattedDate} on ${formattedTime}`;
};

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const walkThrough = [
    { target : '#main',
      content: 'This is a walkthrough to start with Caredevo AI scribe and plan.',
      placement: 'center',
      title: 'AI scribe',
      disableBeacon: 'true'
    },
    { target : '#microphone',
      content: 'Click here to start recording. Your browser will ask for permission to use microphone for the first time. Wait 3 seconds before start dictation/recording.',
      placement : 'bottom',
      title: 'Start recording'
    },
    { target : '#smartPaste',
      content : 'Click here to paste any text data such as discharge summary, letters, result, etc. AI will read, process, then put it in the patient data.',
      placement : 'bottom',
      title: 'Smart Paste'
    },
    { target : '#sidebar',
      content : 'Choose from different page for different function : Consultation, Summary, Mental Health Care Plan, Health Assessment, Chronic Diseaes Management, Chat with Records',
      placement : 'bottom',
      title: 'Sidebar'
    },
    { target : '#file',
      content : 'Click here to create new, open, save, and close patient file to your local folder.',
      placement : 'bottom',
      title: 'Patient File'
    },
    { target : '#patient',
      content : 'Click here edit patient profile. Click save once finished to save patient profile.',
      placement : 'bottom',
      title: 'Patient Profile'
    },
    { target : '#practice',
      content : "Click here to choose any practice you're working at.",
      placement : 'bottom',
      title: 'Practice Selection'
    },
    { target : '#team',
      content : 'Click here to add allied health, specialist, GP, or other health care practitioner who care for the patients.',
      placement : 'bottom',
      title: 'Care Team'
    },
  ]


class Consult extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');
        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            AInote: "",
            AIInstruction: "",
            // printing option
            showPrintOption: false,
            // walktthrough
            run : false

        };

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    componentDidMount() {
        const fetchOnboardData = async () => {
          try {
            const onBoard = await axios.get(`${EndPoint}/user/onboard`, { withCredentials: true });
            if (onBoard.data.intro === true) {
                this.setState({run: false})
            } else {
                this.setState({run: true})
            }
          } catch (error) {
            console.error("Error fetching onboard data:", error.response?.data || error.message);
          }
        };
      
        fetchOnboardData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }

    }

    // finish onboard
    finishOnboard = async (data) => {
        try {
          const { status } = data;
          if (status === 'ready') {
            this.setState({ run: false }); // Stop the tour when finished or skipped
          
          // Make the POST request to the onboarding endpoint
          await axios.post(
            `${EndPoint}/user/onboard`,
            { path: 'intro' },
            { withCredentials: true }
          );
      
          // Show a success toast notification
          toast.success("Introduction walkthrough completed!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
      
        
        } catch (error) {
          console.error("Error updating onboarding:", error.response?.data || error.message);
          toast.error("Failed to update onboarding. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
    };
    // update parent
    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };


    //Edit form functions
    toggleEdit = (section) => {
        this.setState((prevState) => ({
            [section]: !prevState[section]
        }), () => {
            if (!this.state[section]) {
                // If toggling from editing to not editing, save to localStorage
                this.updatePatientData();
            }
        });
    };
    

    handleInputChange = (section, key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [section]: {
                    ...prevState.formData[section],
                    [key]: value,
                },
            },
        }));
    };
    
    // AI text to form and notes 
    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    AITranscription = async (newTranscription) => {

        const initData = this.state.formData;
        const adminData = this.props.adminData;
        const referrences = adminData.referrences;

        // Agent to write notes, then add to consultation box
        toast.info('Working on it...', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: false
        });
    
        var respond = await workflowAI(newTranscription, initData, referrences);

        this.setState({formData: respond})
        this.setState({AInote: respond.consultation, AIInstruction: respond.instruction})
        this.updatePatientData();
        
        toast.success('All task done!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: false
        });

    };

    // Methods for consultation 
    handleDeleteConsultation = (id) => {
        this.setState((prevState) => {
            const updatedConsultations = prevState.formData.consultations.filter(item => item.id !== id);
            return {
                formData: {
                    ...prevState.formData,
                    consultations: updatedConsultations, // Correct field name
                },
            };
        }, this.updatePatientData);  // Save changes after deleting
    };
    
    
    handleSelectConsultation = (id) => {
        const selectedConsultation = this.state.formData.consultations.filter(item => item.id === id);
        this.setState({
            AInote: selectedConsultation[0].consultation,  // Populate the textarea for editing
            AIInstruction : selectedConsultation[0]. instruction,
            selectedConsultationIndex: id,  // Keep track of the selected consultation
        });
    };
    
    updateSelectedConsultation = () => {
        const { selectedConsultationIndex, AInote, formData } = this.state;
    
        if (selectedConsultationIndex !== undefined) {

            // Update the selected consultation with the new text
            const updatedConsultations = this.state.formData.consultations.map(item => 
                item.id === selectedConsultationIndex
                    ? { ...item, consultation: AInote }
                    : item
            );
            
    
            this.setState({
                formData: {
                    ...formData,
                    consultations: updatedConsultations,
                },
            }, this.updatePatientData);  // Save changes
        }
    };
    
    updateSelectedInstruction = () => {
        const { selectedConsultationIndex, AIInstruction, formData } = this.state;
    
        if (selectedConsultationIndex !== undefined) {

            // Update the selected consultation with the new text
            const updatedConsultations = this.state.formData.consultations.map(item => 
                item.id === selectedConsultationIndex
                    ? { ...item, instruction: AIInstruction }
                    : item
            );
            
    
            this.setState({
                formData: {
                    ...formData,
                    consultations: updatedConsultations,
                },
            }, this.updatePatientData);  // Save changes
        }
    };

    // render Notes
    renderNotes() {
        const formData = this.state.formData;
        if (Object.keys(formData).length === 0) {
            return;
        }
        const consultations = formData.consultations ?? []; // Handle empty case
        return consultations.map((item, index) => {
            return (
                <div key={index} className="bg-slate-100 rounded p-2 shadow m-2 flex justify-between items-start">
                    <div onClick={() => this.handleSelectConsultation(item.id)} className="flex-grow cursor-pointer overflow-hidden">
                    
                    <div className='grid grid-cols-2'>
                        <p className="text-xs italic my-2">{formatTimeStamp(item.date)}</p>
                        <button 
                            className="text-red-500 text-sm font-bold m-2 place-self-end" 
                            onClick={() => this.handleDeleteConsultation(item.id)}
                        >
                            X
                        </button>
                    </div>

                    <p className="text-sm my-1 overflow-hidden whitespace-nowrap text-ellipsis">{item.consultation}</p>
                    <div className='flex flex-row'>
                    {Array.isArray(item.tag) && item.tag.length > 0 ? (
                        item.tag.map((tag, index) => (
                        <div key={index} className='bg-sky-200 p-1 rounded m-1'>{tag}</div>
                        ))
                    ) : (
                        <></>
                    )}
                    </div>
                    </div>
                
                
                </div>
            );
            
        });
    }
    

    // rendering section
    renderOperational() {
        const { AInote, AIInstruction} = this.state;
        var messages;
        if (AInote) {
            messages = AInote;
        } else {
            messages = ""
        }
        return (
   
       
            <div className="col-span-6 bg-white p-4" >
            <div className='grid grid-cols-2 gap-2 mx-10 my-4 z-10 xs:grid-cols-1 xs:m-0' ref={this.drawerRef}>
                           
                <div className='m-4 row-span-2'>
                    <p className='text-md font-semibold mx-4 text-center'>Consultation Notes</p>
                    {this.renderNotes()}
                </div>
                <div className='m-4'>
                    <p className='text-md font-semibold mx-4 text-center'>Doctor Notes</p>
                    <textarea
                        value={this.state.AInote}
                        onChange={(e) => this.setState({ AInote: e.target.value })} 
                        onBlur={this.updateSelectedConsultation}  // Update on blur (when focus leaves the textarea)
                        className="w-[100%] h-[700px] border-gray-200 resize-none"
                        placeholder="Response appears here."
                    />

                    <button
                        className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800" 
                        type="button" 
                        onClick={() => this.copyToClipboard(this.state.AInote)}
                    >
                        Copy
                    </button>
                </div>
       
                <div className='m-4'>
                    <p className='text-md font-semibold mx-4 text-center'>Patient's Notes</p>
                    <textarea
                        value={this.state.AIInstruction}
                        onChange={(e) => this.setState({ AIinstruction: e.target.value })} 
                        onBlur={this.updateSelectedInstruction}  // Update on blur (when focus leaves the textarea)
                        className="w-[100%] h-[700px] border-gray-200 resize-none"
                        placeholder="Response appears here."
                    />
                            <button
                        className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800" 
                        type="button" 
                        onClick={() => this.copyToClipboard(this.state.AIInstruction)}
                    >
                        Copy
                    </button>
                </div>
            </div>
            </div>
      

        
        );
    }

    render() {
        const { run } = this.state;
        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(320px, auto)' }}>
              
              <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Consultation</div>
                {this.renderOperational()}
                <ToastContainer />
                <Joyride 
                    steps={walkThrough} 
                    continuous
                    disableScrolling
                    showProgress
                    run={run}
                    callback={this.finishOnboard}
                    styles={{
                    options: {
                        arrowColor: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(255, 255, 255)',
                        overlayColor: 'rgba(18, 32, 61, 0.4)',
                        primaryColor: 'rgba(167, 65, 65, 0.86)',
                        textColor: 'rgba(0, 0, 0, 0.86)',
                        hideCloseButton: 'false',
                        width: 500,
                        zIndex: 1000,
                    },
                    }}
                />
            </div>
        );
    }
    
}

export default Consult

import React from 'react';
import { withNavigation } from '../../utilities/NavigationContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      editableProfile: {}, 
    };
  }

  componentDidMount() {
    this.fetchPatientProfile();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientData !== this.props.patientData) {
      this.setState({ editableProfile: { ...this.props.patientData.profile } });
    }
  }

  fetchPatientProfile = async () => {
      const patientData = this.props.patientData;
      const profile = patientData.profile;
      if (profile && profile.DOB) {
        var formattedDOB = this.formatDateToISO(profile.dob);
      } else {
        formattedDOB = ""
      }
      
      this.setState({
        editableProfile: {
          ...profile,
          dob: formattedDOB, // Ensure the dob is in 'YYYY-MM-DD' format for the date input
        },
      });
  };

  formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD' format
  };

  toggleEdit = () => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing
    }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      editableProfile: { ...prevState.editableProfile, [name]: value }
    }));
  };

  handleSave = async () => {

      var patientData = { ...this.props.patientData }; 
      patientData.profile = this.state.editableProfile; 
      this.props.updatePatientData(patientData);

        toast.success(`Profile updated successfully`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar : false
        });
        this.toggleEdit(); // Turn off editing mode after saving

  };

  renderContent() {
      const { isEditing, editableProfile } = this.state;
      return (
        <>
          <div className='containerchild grid grid-cols-[1fr,3fr]'>
            <label className='label p-1 m-1 text-xs'>First Name</label>
            <input
              type="text"
              name="firstName" 
              placeholder="First Name"
              value={editableProfile.firstName || ''} 
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
            <label className='label p-1 m-1 text-xs'>Last Name</label>
            <input
              type="text"
              name="lastName" 
              placeholder="Last Name"
              value={editableProfile.lastName || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
            <label className='label p-1 m-1 text-xs'>Date of Birth</label>
            <input
              type="date"
              name="dob"
              placeholder="Date of Birth"
              value={editableProfile.dob || ''} 
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
            <label className='label p-1 m-1 text-xs'>Medicare</label>
            <input
              type="text"
              name="medicare"
              placeholder="Medicare"
              value={editableProfile.medicare || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
            <label className='label p-1 m-1 text-xs'>Address 1</label>
            <input
              type="text"
              name="address1"
              placeholder="Address Line 1"
              value={editableProfile.address1 || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
            <label className='label p-1 m-1 text-xs'>Address 2</label>
            <input
              type="text"
              name="address2"
              placeholder="Address Line 2"
              value={editableProfile.address2 || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
          </div>
          <div className='grid grid-cols-[1fr,3fr]'>
            <label className='label p-1 m-1 text-xs'>Gender</label>
            <select
              name="gender"
              value={editableProfile.gender || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='select text-xs p-1 m-1 rounded border border-slate-200'
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <label className='label p-1 m-1 text-xs'>Pronoun</label>
            <select
              name="pronoun"
              value={editableProfile.pronoun || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='select text-xs p-1 m-1 rounded border border-slate-200'
            >
              <option value="">Select Pronoun</option>
              <option value="he/him">He/Him</option>
              <option value="she/her">She/Her</option>
              <option value="they/them">They/Them</option>
            </select>
            <label className='label p-1 m-1 text-xs'>Ethnicity</label>
            <select
              name="ethnic"
              value={editableProfile.ethnic || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='text-xs p-1 m-1 rounded border border-slate-200'
            >
              <option value="">Select Ethnicity</option>
              <option value="Asian">Asian</option>
              <option value="Black/African">Black/African</option>
              <option value="Caucasian">Caucasian</option>
              <option value="Hispanic/Latin">Hispanic/Latin</option>
              <option value="Middle Eastern">Middle Eastern</option>
              <option value="Native American">Native American</option>
              <option value="Pacific Islander">Pacific Islander</option>
              <option value="Aboriginal">Aboriginal</option>
              <option value="Mixed">Mixed</option>
              <option value="Other">Other</option>
            </select>

            <label className='label p-1 m-1 text-xs'>Suburb</label>
            <input
              type="text"
              name="suburb"
              placeholder="Suburb"
              value={editableProfile.suburb || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />

            <label className='label p-1 m-1 text-xs'>State</label>
            <select
              name="state"
              value={editableProfile.state || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='select text-xs p-1 m-1 rounded border border-slate-200'
            >
              <option value="">Select State</option>
              <option value="NSW">New South Wales (NSW)</option>
              <option value="VIC">Victoria (VIC)</option>
              <option value="QLD">Queensland (QLD)</option>
              <option value="WA">Western Australia (WA)</option>
              <option value="SA">South Australia (SA)</option>
              <option value="TAS">Tasmania (TAS)</option>
              <option value="ACT">Australian Capital Territory (ACT)</option>
              <option value="NT">Northern Territory (NT)</option>
            </select>


            <label className='label p-1 m-1 text-xs'>Postcode</label>
            <input
              type="text"
              name="postcode"
              placeholder="Postcode"
              value={editableProfile.postcode || ''}
              onChange={this.handleChange}
              disabled={!isEditing}
              className='input text-xs p-1 m-1 rounded border border-slate-200'
            />
          </div>
        </>
    )
  }

  render() {
    const { isEditing } = this.state;

    return (
      <div className='col-span-3 m-2 rounded shadow-sm p-2 bg-slate-100 relative sm:col-span-6 xs:col-span-6'>
        <ToastContainer />
        <p className='text-lg font-semibold p-1'>Profile</p>
        <div className='profile containerparent grid grid-cols-2 gap-4 xs:grid-cols-1'>
            {this.renderContent()}
        </div>
      
        

        <button
          id='patient'
          className={`button absolute right-1 bottom-1 rounded shadow-sm w-32 text-xs p-2 m-1 ${isEditing ? 'bg-sky-600 hover:bg-sky-700 text-white' : 'bg-yellow-300 hover:bg-yellow-400 text-slate-900'}`}
          onClick={isEditing ? this.handleSave : this.toggleEdit}
        >
          {isEditing ? 'Save' : 'Edit'}
        </button>
        {/* <button className='rounded shadow-sm w-24 text-xs p-2 m-2 bg-red-700 hover:bg-red-800 text-white' onClick={() => this.props.navigate('/patientselect')}>Close</button> */}
   
      </div>
    );
  }
}

export default withNavigation(Profile);
